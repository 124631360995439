import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { Text as RebassText } from 'rebass/styled-components';

const Text = forwardRef(({ interactive, nospace, primary, uppercase, sx, ...props }, ref) => (
  <RebassText
    ref={ref}
    sx={{
      cursor: interactive ? 'pointer' : '',
      textTransform: uppercase ? 'uppercase' : '',
      color: primary ? '#e2001a' : '',
      ...sx,
    }}
    my={nospace ? ['0', '0', '0'] : ''}
    {...props}
  />
));

Text.propTypes = {
  nospace: PropTypes.bool,
  interactive: PropTypes.bool,
  primary: PropTypes.bool,
  uppercase: PropTypes.bool,
  sx: PropTypes.shape({}),
};
Text.defaultProps = { nospace: false, interactive: false, primary: false, uppercase: false, sx: undefined };

/** @component */
export default Text;
