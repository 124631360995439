const HeartIcon = ({ ...props }) => (
  <svg
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
  >
    <circle cx="18" cy="18" r="18" fill="#E2001A" fillOpacity="0.1" />
    <path
      d="M21.7694 11C19.968 11 18.4512 12.1237 17.7206 12.7777C16.9899 12.1237 15.4732 11 13.6717 11C11.0954 11 9 13.0954 9 15.6717C9 17.285 10.4732 18.7532 10.5323 18.8118L17.7206 26L24.9051 18.8155C24.968 18.7532 26.4412 17.285 26.4412 15.6717C26.4412 13.0954 24.3457 11 21.7694 11L21.7694 11Z"
      stroke="#E2001A"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default HeartIcon;
