const BusIcon = ({ ...props }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg xmlns="http://www.w3.org/2000/svg" width="16.25" height="15" viewBox="0 0 16.25 15" {...props}>
    <path
      fill="#c5231f"
      d="M6.75 4a2.508 2.508 0 0 0-2.5 2.5v3.125H3V11.5h1.25v6.875a.626.626 0 0 0 .625.625H6.75l.215-.625h8.32L15.5 19h1.875a.626.626 0 0 0 .625-.625V11.5h1.25V9.625H18V6.5A2.508 2.508 0 0 0 15.5 4zm0 1.25h8.75a1.24 1.24 0 0 1 1.25 1.25H5.5a1.24 1.24 0 0 1 1.25-1.25zM5.5 7.75h5v4.375h-5zm6.25 0h5v4.375h-5zM5.5 13.375h11.25v3.75H5.5zm.625 1.25v1.25h2.5v-1.25zm7.5 0v1.25h2.5v-1.25z"
      transform="translate(-3 -4)"
    />
  </svg>
);

export default BusIcon;
