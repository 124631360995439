const CheckFinish = (props) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg xmlns="http://www.w3.org/2000/svg" width="71" height="71" viewBox="0 0 71 71" {...props}>
    <defs>
      <filter id="Ellipse_764" width="71" height="71" x="0" y="0" filterUnits="userSpaceOnUse">
        <feOffset dx="1" dy="1" />
        <feGaussianBlur result="blur" stdDeviation="2.5" />
        <feFlood floodOpacity=".161" />
        <feComposite in2="blur" operator="in" />
        <feComposite in="SourceGraphic" />
      </filter>
    </defs>
    <g id="Gruppe_5161" transform="translate(-842.571 -1659.571)">
      <g filter="url(#Ellipse_764)" transform="translate(842.57 1659.57)">
        <circle id="Ellipse_764-2" cx="28" cy="28" r="28" fill="#fff" transform="translate(6.5 6.5)" />
      </g>
      <circle id="Ellipse_765" cx="16" cy="16" r="16" fill="#e2001a" transform="translate(861.071 1678.071)" />
      <g id="Gruppe_5069" transform="translate(858.072 1665.65)">
        <g id="Gruppe_5100" transform="translate(3 13.152)">
          <path
            id="Pfad_3427"
            d="M590.542 424.076a15.635 15.635 0 1 0 0 31.269 15.635 15.635 0 1 0 0-31.269z"
            fill="#e2001a"
            transform="translate(-574.908 -424.076)"
          />
          <path
            id="Path_395"
            d="M597.371 454.8a1.9 1.9 0 0 1-1.142-.379l-.02-.015-4.3-3.289a1.911 1.911 0 0 1 2.325-3.033l2.785 2.136 6.581-8.585a1.91 1.91 0 0 1 2.678-.354l-.041.057.042-.057a1.912 1.912 0 0 1 .353 2.678l-7.741 10.094a1.911 1.911 0 0 1-1.52.745z"
            fill="#fff"
            transform="translate(-583.22 -432.673)"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default CheckFinish;
