const SearchPlusIcon = ({ ...props }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" {...props}>
    <path
      fill="#e2001a"
      d="M12.449 3a5.829 5.829 0 0 0-4.52 9.514l-4.648 4.648.839.838 4.648-4.648A5.831 5.831 0 1 0 12.449 3zm0 1.166a4.666 4.666 0 1 1-4.666 4.666 4.657 4.657 0 0 1 4.666-4.666zM11.866 6.5v1.75h-1.75v1.166h1.75v1.75h1.166v-1.75h1.75V8.249h-1.75V6.5z"
      transform="translate(-3.281 -3)"
    />
  </svg>
);

export default SearchPlusIcon;
