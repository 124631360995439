const ArrowIconRight = ({ ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="7.335"
    height="13.491"
    viewBox="0 0 7.335 13.491"
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
  >
    <path
      d="m3.434 10.781-.652.59L8.455 16.5l.326.282.326-.282 5.674-5.128-.652-.59-5.348 4.833z"
      transform="rotate(90 9.739 7.703)"
      fill="#333333"
      stroke="#333333"
    />
  </svg>
);

export default ArrowIconRight;
