import '../styles/globals.css';

import { IdProvider } from '@radix-ui/react-id';
import PropTypes from 'prop-types';
import { cloneElement } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Hydrate } from 'react-query/hydration';
import { ThemeProvider } from 'styled-components';

import Head from '@/components/Head';
import Layout from '@/components/Layout/Layout';
import Toaster from '@/components/Toaster/Toaster';
import { Keycloack } from '@/context';
import appWithI18n from '@/context/appWithi18n';
import { LoginRegisterDialogProvider } from '@/context/LoginRegisterDialog';
import { NotificationCardProvider } from '@/context/NotificationCard';
import { ContentContext, useCurrentNavPage } from '@/hooks';
import errorPage from '@/mockData/errorPage';
import { footerTypes, headerTypes, seoTypes } from '@/proptypes';
import { configApi } from '@/services';
import { DEFAULT_THEME } from '@/themes';
import { parseCookies } from '@/utils';
import getHostname from '@/utils/getHostname';

const queryClient = new QueryClient();

const LayoutSwitch = ({ LayoutComponent, children, hideLayout }) => {
  if (hideLayout) return children;
  return cloneElement(LayoutComponent, undefined, children);
};

function MyApp({ Component, pageProps, cookies, keycloakConfig }) {
  const activePage = useCurrentNavPage();

  const resilientPageProps = pageProps ?? { content: errorPage, dehydratedState: null, hideLayout: false };

  return (
    <IdProvider>
      <QueryClientProvider client={queryClient}>
        <Hydrate state={resilientPageProps?.dehydratedState}>
          <Keycloack config={keycloakConfig} cookies={cookies}>
            <ThemeProvider theme={DEFAULT_THEME}>
              <ContentContext.Provider value={resilientPageProps?.content}>
                <LoginRegisterDialogProvider>
                  <NotificationCardProvider>
                    <Head cookies={cookies} />
                    <LayoutSwitch
                      hideLayout={resilientPageProps?.hideLayout}
                      LayoutComponent={
                        <Layout
                          headerContent={resilientPageProps?.content?.header}
                          footerContent={resilientPageProps?.content?.footer}
                          activePage={activePage}
                        />
                      }
                    >
                      <Component {...resilientPageProps} />
                    </LayoutSwitch>
                    <Toaster />
                  </NotificationCardProvider>
                </LoginRegisterDialogProvider>
              </ContentContext.Provider>
            </ThemeProvider>
          </Keycloack>
        </Hydrate>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </IdProvider>
  );
}

MyApp.getInitialProps = async ({ ctx }) => {
  const domain = getHostname(ctx?.req);
  const settings = await configApi('/settings', domain);

  if (ctx.res.statusCode === 404) {
    ctx.res?.writeHead(301, {
      Location: '/nicht-gefunden',
    });
    ctx.res?.end();
  }

  return {
    cookies: parseCookies(ctx?.req),
    keycloakConfig: settings.keycloakConf,
  };
};

MyApp.propTypes = {
  Component: PropTypes.func.isRequired,
  pageProps: PropTypes.shape({
    // eslint-disable-next-line react/forbid-prop-types
    dehydratedState: PropTypes.object,
    content: PropTypes.shape({
      nextseo: seoTypes,
      header: headerTypes,
      footer: footerTypes,
    }).isRequired,
  }),
  cookies: PropTypes.shape({}).isRequired,
  keycloakConfig: PropTypes.shape({
    realm: PropTypes.string,
    url: PropTypes.string,
    'ssl-required': PropTypes.string,
    resource: PropTypes.string,
    'public-client': PropTypes.bool,
    'verify-token-audience': PropTypes.bool,
    'use-resource-role-mappings': PropTypes.bool,
    'confidential-port': PropTypes.number,
    clientId: PropTypes.string,
  }).isRequired,
};

MyApp.defaultProps = {
  pageProps: undefined,
};

export default appWithI18n(MyApp);
