import { Form, Formik } from 'formik';
import { AnimatePresence, motion } from 'framer-motion';
import useTranslation from 'next-translate/useTranslation';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Flex } from 'rebass/styled-components';
import styled from 'styled-components';

import Button from '@/components/Button/Button';
import UnstyledButton from '@/components/Button/UnstyledButton';
import Text from '@/components/Text';
import { useBreakpoints } from '@/hooks';

import Overview from './overview';
import Settings from './settings';

export const ModalBox = styled(motion.div)``;

export const ModalContent = styled(motion.div)``;

export const ModalContainer = styled(motion.Box)`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background: rgba(61, 61, 61, 0.73);
  z-index: 3000;
`;
const UnstyledButonLowerCase = styled(UnstyledButton)`
  text-transform: none;
`;
const HoverText = styled(Text)`
  &:hover {
    color: #a70000;
  }
`;
UnstyledButonLowerCase.defaultProps = {
  width: ['100%', 'auto', 'auto'],
};

const ModalDialog = ({ children, showDialog }) => {
  const breakpoint = useBreakpoints();
  const isMobile = ['small', 'medium'].includes(breakpoint);
  const modalStyle = {
    zIndex: 100,
    background: '#fff',
    borderRadius: '4px',
    maxWidth: isMobile ? '480px' : '800px',
    position: isMobile ? 'static' : 'absolute',
    bottom: '-100%',
    margin: isMobile ? '10vh auto' : '0 0',
    padding: '2rem',
    overflow: 'hidden',
  };
  return (
    <AnimatePresence>
      {showDialog && (
        <ModalContainer
          initial={{ opacity: 0 }}
          animate={{
            opacity: 1,
            transition: { duration: 0.3, delay: 1 },
          }}
        >
          <ModalBox
            style={modalStyle}
            animate={{ bottom: 0 }}
            transition={{ duration: 0.4, delay: 1 }}
            exit={{ bottom: '-100%', transition: { delay: 0, duration: 0.4 } }}
          >
            <ModalContent>{children}</ModalContent>
          </ModalBox>
        </ModalContainer>
      )}
    </AnimatePresence>
  );
};

ModalDialog.propTypes = {
  children: PropTypes.element.isRequired,
  showDialog: PropTypes.bool.isRequired,
};

const CookieConsentDialog = ({ onChange, hasCookies }) => {
  const [showDialog, setShowDialog] = useState(() => !!Array.isArray(hasCookies));
  const { t } = useTranslation('common-cookie-consent');
  const { push, pathname, reload } = useRouter();
  const [page, setPage] = useState('overview');

  useEffect(() => {
    const isAllowedPageShowCookieBanner = ['/datenschutz', '/impressum'].includes(pathname);

    if (!isAllowedPageShowCookieBanner && !hasCookies) {
      setShowDialog(true);
    } else {
      setShowDialog(false);
    }
  }, [hasCookies, pathname]);

  useEffect(() => {
    if (page === 'overview') {
      setPage('overview');
    } else {
      setPage('settings');
    }
  }, [page]);

  const changeConsent = (consentList) => {
    const date = new Date();
    date.setTime(date.getTime());
    const obj = { creationDate: date.toUTCString() };
    consentList.unshift(obj);
    onChange(consentList);
    setShowDialog(false);
    reload();
  };

  const toggle = t('toggle', {}, { returnObjects: true });

  const allowNecessary = () => {
    const cookieNames = toggle?.filter?.((item) => item.disabled).map((item) => item.name);
    changeConsent(cookieNames);
  };

  const AllowAllCookie = () => {
    const allowAll = () => {
      const cookieNames = toggle?.map?.((item) => item.name);
      changeConsent(cookieNames);
    };
    return (
      <Button onClick={allowAll} mb={['1.25rem', '1.25rem', '1.25rem']} width={['100%', '100%', 'auto']}>
        {page === 'overview' ? t('overview.buttonTextAll') : t('settings.buttonTextAll')}
      </Button>
    );
  };

  const formikConfig = {
    initialValues: toggle
      ?.map((item) => item.name)
      .reduce(
        (prev, current) => ({
          ...prev,
          [current]: toggle[toggle?.findIndex((item) => item.name === current)].toggled,
        }),
        {}
      ),
    onSubmit: async (values) => {
      const cookieNames = Object.keys(values).filter((item) => values[item]);
      changeConsent(cookieNames);
    },
  };

  const handleImprintNav = async () => {
    await push(t('urlImpressum' ?? ''));
    setShowDialog(false);
  };

  return (
    <ModalDialog showDialog={showDialog}>
      <Formik {...formikConfig}>
        <Form>
          {page === 'overview' ? <Overview setPage={setPage} AllowAllButton={<AllowAllCookie />} /> : null}
          {page === 'settings' ? <Settings setPage={setPage} AllowAllButton={<AllowAllCookie />} /> : null}
          <Flex justifyContent="space-between" flexWrap="wrap">
            <UnstyledButonLowerCase onClick={allowNecessary}>
              <HoverText variant="smallPrimary">{t('onlyNecessaryCookie')}</HoverText>
            </UnstyledButonLowerCase>
            <UnstyledButonLowerCase>
              <HoverText onClick={handleImprintNav} variant="smallPrimary">
                {t('impressum')}
              </HoverText>
            </UnstyledButonLowerCase>
          </Flex>
        </Form>
      </Formik>
    </ModalDialog>
  );
};

CookieConsentDialog.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  hasCookies: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default CookieConsentDialog;
