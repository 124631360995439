import useScrollPosition from '@react-hook/window-scroll';
import stylePropTypes from '@styled-system/prop-types';
import useTranslation from 'next-translate/useTranslation';
import Link from 'next/link';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Box, Flex, Image } from 'rebass/styled-components';
import styled from 'styled-components';

import { useBreakpoints } from '@/hooks';

import Section from '../../../Section';
import Nav from '../Navigation/Nav';
import MobileNavigation from '../Navigation/NavMobil';

const LogoWrapper = styled(Box)`
  justify-content: left;
  width: fit-content;
  @media (max-width: 1024px) {
    justify-content: left;
    width: fit-content;
    margin-right: 15px;
  }
`;
LogoWrapper.defaultProps = {
  alignItems: 'center',
  display: 'flex',
  pt: '5px',
  pb: '3px',
};

const StyledSection = styled(Section)`
  position: fixed;
`;

const LayoutHeader = ({ activePage, isMobileMenuOpen, setMobileMenuOpen }) => {
  const { t } = useTranslation('common-header', { isObject: true });
  const breakpoint = useBreakpoints();
  const scrollY = useScrollPosition(30);
  const isMobile = ['small'].includes(breakpoint);
  const [openStart, setOpenStart] = useState(false);
  const [openHeader, setOpenHeader] = useState(true);

  const handleClickLogoWrapper = () => {
    setOpenStart(true);
    setMobileMenuOpen(false);
  };
  useEffect(() => {
    let prevScrollpos = window.pageYOffset;
    window.onscroll = function scrolling() {
      const currentScrollPos = window.pageYOffset;
      setOpenHeader(prevScrollpos >= currentScrollPos);
      prevScrollpos = currentScrollPos;
    };
  }, [scrollY]);

  return (
    <div style={isMobile ? { opacity: openHeader ? '1' : '0' } : null}>
      <StyledSection bg="white" style={{ zIndex: '99' }}>
        <Flex justifyContent="space-between">
          <LogoWrapper
            px={['1rem', '1rem', '2rem']}
            my={['14px', '14px', '14px']}
            onClick={() => handleClickLogoWrapper()}
          >
            <Link href="/">
              <a title="">
                {isMobile && t('mobileLogo.url').includes('http') ? (
                  <Image
                    src={t('mobileLogo.url')}
                    alt={t('mobileLogo.alternativeText')}
                    height="28px"
                    style={{ cursor: 'pointer' }}
                  />
                ) : (
                  <Image
                    src={t('logo.url')}
                    alt={t('logo.alternativeText')}
                    height="28px"
                    style={{ cursor: 'pointer' }}
                  />
                )}
              </a>
            </Link>
          </LogoWrapper>
          <Nav activePage={activePage} />
        </Flex>
      </StyledSection>
      <MobileNavigation setOpenStart={setOpenStart} openStart={openStart} isMobileMenuOpen={isMobileMenuOpen} />
      <StyledSection boxShadow="horizontal" style={{ zIndex: '69', height: '65px', backgroundColor: '#fff' }} />
    </div>
  );
};

LayoutHeader.propTypes = {
  ...stylePropTypes.color,
  ...stylePropTypes.space,

  activePage: PropTypes.string.isRequired,
};

export default LayoutHeader;
