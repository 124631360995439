const SchoolIcon = ({ ...props }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg xmlns="http://www.w3.org/2000/svg" width="16.25" height="15" viewBox="0 0 16.25 15" {...props}>
    <path
      fill="#87b44a"
      d="M11.125 4L3 5.875v1.25l8.125-1.875 8.125 1.875v-1.25zm-3.75 3.75a11.865 11.865 0 0 0-3.984.82L3 8.746v9.629h7.051a1.236 1.236 0 0 0 2.148 0h7.051V8.746l-.391-.176a11.865 11.865 0 0 0-3.984-.82 10.809 10.809 0 0 0-3.75.742 10.809 10.809 0 0 0-3.75-.742zm0 1.25a10.389 10.389 0 0 1 3.125.605v6.953a10.127 10.127 0 0 0-3.125-.547 9.813 9.813 0 0 0-3.125.547V9.605A9.606 9.606 0 0 1 7.375 9zm7.5 0A9.606 9.606 0 0 1 18 9.605v6.953a9.813 9.813 0 0 0-3.125-.547 10.127 10.127 0 0 0-3.125.547V9.605A10.389 10.389 0 0 1 14.875 9z"
      transform="translate(-3 -4)"
    />
  </svg>
);

export default SchoolIcon;
