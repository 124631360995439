const BasketIcon = ({ ...props }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg xmlns="http://www.w3.org/2000/svg" width="17.373" height="14.63" viewBox="0 0 17.373 14.63" {...props}>
    <path
      fill="#178038"
      d="M4.731 7a.731.731 0 0 0 0 1.463h1.624l1.92 7.681a1.451 1.451 0 0 0 1.417 1.1h8.389a1.439 1.439 0 0 0 1.394-1.074l1.9-6.972H9.12l.366 1.463h9.99l-1.394 5.12h-8.39L7.772 8.1A1.451 1.451 0 0 0 6.355 7zm12.436 10.241a2.194 2.194 0 1 0 2.194 2.194 2.206 2.206 0 0 0-2.194-2.194zm-6.583 0a2.194 2.194 0 1 0 2.195 2.194 2.206 2.206 0 0 0-2.196-2.194zm0 1.463a.731.731 0 1 1-.731.731.722.722 0 0 1 .73-.735zm6.583 0a.731.731 0 1 1-.732.731.722.722 0 0 1 .732-.735z"
      transform="translate(-4 -7)"
    />
  </svg>
);

export default BasketIcon;
