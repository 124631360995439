import { themeGet } from '@styled-system/theme-get';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import styled from 'styled-components';

import Text from '../Text';

const MarkDownWrapper = styled(Text)`
  a {
    color: ${themeGet('colors.primary', '#e2001a')};
    outline: none;
  }
  p {
    margin: 0;
  }
`;

const Markdown = ({ children, ...props }) => {
  return (
    <MarkDownWrapper variant="body" {...props}>
      <ReactMarkdown
        remarkPlugins={[[remarkGfm, { singleTilde: false }]]}
        renderers={{
          link: (prop) => (
            <a href={prop.href} target="_blank" rel="noreferrer">
              {prop.children}
            </a>
          ),
        }}
      >
        {children}
      </ReactMarkdown>
    </MarkDownWrapper>
  );
};

Markdown.propTypes = {
  children: PropTypes.string.isRequired,
};

Markdown.defaultProps = {};

export default Markdown;
