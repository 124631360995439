const WarningIcon = ({ ...props }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" {...props}>
    <path
      fill="#856404"
      d="M14 4a10 10 0 1 0 10 10A10.011 10.011 0 0 0 14 4zm0 1.667A8.333 8.333 0 1 1 5.667 14 8.32 8.32 0 0 1 14 5.667zM13.167 9v6.667h1.667V9zm0 8.333V19h1.667v-1.667z"
      transform="translate(-4 -4)"
    />
  </svg>
);

export default WarningIcon;
