const CookieCheckIcon = ({ ...props }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg xmlns="http://www.w3.org/2000/svg" width="8.858" height="6.763" viewBox="0 0 8.858 6.763" {...props}>
    <path
      fill="none"
      stroke="#e2001a"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M9.727 6.281L4.778 11.23 2.693 9.145l-.412.412 2.291 2.291.206.2.206-.2 5.155-5.155z"
      transform="translate(-1.781 -5.781)"
    />
  </svg>
);

export default CookieCheckIcon;
