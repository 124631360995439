const Checkhook = ({ ...props }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg xmlns="http://www.w3.org/2000/svg" width="12.044" height="9.196" viewBox="0 0 12.044 9.196" {...props}>
    <path
      fill="#e2001a"
      stroke="#e2001a"
      d="M12.354 6.281l-6.7 6.7-2.816-2.825-.557.557 3.1 3.1.278.266.278-.266 6.974-6.974z"
      transform="translate(-1.574 -5.574)"
    />
  </svg>
);

export default Checkhook;
