import axios from 'axios';
import { useQuery } from 'react-query';

const useGetRegion = (zipCode = '') =>
  useQuery(
    ['getRegion', zipCode],
    async () => {
      const { data } = await axios.get(
        `https://nominatim.openstreetmap.org/search?postalcode=${zipCode}&country=Deutschland&format=json&polygon_geojson=1`
      );

      const [, response] = data;

      return response;
    },
    { enabled: Boolean(zipCode) }
  );

export default useGetRegion;
