const JpegSmall = ({ ...props }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg xmlns="http://www.w3.org/2000/svg" id="JPEG-small" width="30" height="34.583" viewBox="0 0 30 34.583" {...props}>
    <defs>
      <style>{'.cls-5,.cls-6{stroke:none}.cls-6{fill:#707070}'}</style>
    </defs>
    <g id="file">
      <g id="Pfad_648" fill="#f8f8f8">
        <path
          d="M24.359 34.083H1.804c-.719 0-1.304-.578-1.304-1.288V1.789C.5 1.079 1.085.5 1.804.5h15.402c2.752 2.764 7.575 7.608 8.457 8.497v23.798c0 .71-.585 1.288-1.304 1.288z"
          className="cls-5"
        />
        <path
          d="M1.804 1C1.361 1 1 1.354 1 1.789v31.006c0 .435.36.788.804.788H24.36c.443 0 .804-.353.804-.788V9.203C24.014 8.047 19.64 3.653 16.998 1H1.804m0-1h15.61s8.718 8.755 8.75 8.791v24.004c0 .988-.809 1.788-1.805 1.788H1.804c-.996 0-1.804-.8-1.804-1.788V1.789C0 .8.808 0 1.804 0z"
          className="cls-6"
        />
      </g>
      <g id="Differenzmenge_5" fill="#d5d5d5">
        <path
          d="M7.878 8.483H2c-.827 0-1.5-.673-1.5-1.5V1.104l7.378 7.379z"
          className="cls-5"
          transform="translate(17.367 .102)"
        />
        <path
          d="M6.671 7.983L1 2.312v4.671c0 .551.449 1 1 1H6.67m2.013 1H2c-1.103 0-2-.897-2-2V0h.103l8.581 8.582v.4z"
          className="cls-6"
          transform="translate(17.367 .102)"
        />
      </g>
    </g>
    <g id="type" transform="translate(6.08 17.136)">
      <rect id="Rechteck_481" width="24" height="11" fill="#e8835e" rx="2" transform="translate(-.08 -.136)" />
      <path
        id="Pfad_643"
        fill="#fff"
        d="M1.372-4.365a2.04 2.04 0 0 1-.913-.205 1.783 1.783 0 0 1-.683-.584l.63-.836a1.116 1.116 0 0 0 .91.6q.693 0 .693-.9v-2.579H.287v-1.006h2.85v3.513a2.181 2.181 0 0 1-.448 1.5 1.678 1.678 0 0 1-1.317.497zm5.006-5.51a2.5 2.5 0 0 1 1.131.24 1.734 1.734 0 0 1 .739.681 2.026 2.026 0 0 1 .258 1.044 2.032 2.032 0 0 1-.259 1.041 1.7 1.7 0 0 1-.739.681 2.534 2.534 0 0 1-1.131.236h-.986v1.494H4.257v-5.417zm-.063 2.9a1.113 1.113 0 0 0 .777-.244.892.892 0 0 0 .266-.693.9.9 0 0 0-.266-.7 1.113 1.113 0 0 0-.777-.244h-.924v1.88zm6.791 1.509v1.006H9.312v-5.415h3.7v1.006h-2.573v1.176h2.275v.975h-2.275v1.254zm4.2-1.788h1.036v2.2a2.862 2.862 0 0 1-.924.511 3.274 3.274 0 0 1-1.057.178 2.647 2.647 0 0 1-1.372-.36 2.546 2.546 0 0 1-.956-1 2.976 2.976 0 0 1-.347-1.443 2.976 2.976 0 0 1 .347-1.443 2.531 2.531 0 0 1 .963-1 2.7 2.7 0 0 1 1.386-.36 2.781 2.781 0 0 1 1.169.24 2.338 2.338 0 0 1 .882.7l-.728.743a1.614 1.614 0 0 0-1.266-.612 1.6 1.6 0 0 0-.833.217 1.484 1.484 0 0 0-.567.611 1.951 1.951 0 0 0-.2.905 1.934 1.934 0 0 0 .2.9 1.518 1.518 0 0 0 .564.615 1.545 1.545 0 0 0 .823.221 1.7 1.7 0 0 0 .882-.232z"
        transform="translate(3.144 12.832)"
      />
    </g>
  </svg>
);

export default JpegSmall;
