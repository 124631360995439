const TrainIcon = ({ ...props }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg xmlns="http://www.w3.org/2000/svg" width="13.538" height="16" viewBox="0 0 13.538 16" {...props}>
    <path
      fill="#e47401"
      d="M10.538 3a1.757 1.757 0 0 0-1.6 1.231h-.861A3.086 3.086 0 0 0 5 7.308v6.769a3.7 3.7 0 0 0 2.173 3.365L5.615 19h1.443l1.25-1.25c.123.012.257.019.385.019h6.154c.127 0 .262-.007.385-.019L16.481 19h1.442l-1.558-1.558a3.7 3.7 0 0 0 2.173-3.365V7.308a3.086 3.086 0 0 0-3.077-3.077H14.6A1.757 1.757 0 0 0 13 3zm0 1.231H13a.6.6 0 0 1 .615.615v.615h1.846a1.841 1.841 0 0 1 1.75 1.231H6.327a1.841 1.841 0 0 1 1.75-1.231h1.846v-.615a.6.6 0 0 1 .615-.615zM6.231 7.923h11.077V11H6.231zm0 4.308h11.077v1.846a2.454 2.454 0 0 1-2.462 2.462H8.692a2.454 2.454 0 0 1-2.462-2.462zm5.538.615A1.231 1.231 0 1 0 13 14.077a1.23 1.23 0 0 0-1.231-1.231z"
      transform="translate(-5 -3)"
    />
  </svg>
);

export default TrainIcon;
