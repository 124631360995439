const BellIcon = ({ ...props }) => (
  <svg
    width="14"
    height="18"
    viewBox="0 0 14 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
  >
    <path
      d="M7 0C6.22617 0 5.6 0.619291 5.6 1.38462C5.6 1.44411 5.61367 1.5009 5.62187 1.55769C3.20195 2.16887 1.4 4.34315 1.4 6.92308V13.1538C1.4 13.546 1.09648 13.8462 0.7 13.8462H0V15.2308H5.03125C4.95195 15.4498 4.9 15.6797 4.9 15.9231C4.9 17.0616 5.84883 18 7 18C8.15117 18 9.1 17.0616 9.1 15.9231C9.1 15.6797 9.04805 15.4498 8.96875 15.2308H14V13.8462H13.3C12.9035 13.8462 12.6 13.546 12.6 13.1538V7.11779C12.6 4.51623 10.8418 2.19591 8.37813 1.55769C8.38633 1.5009 8.4 1.44411 8.4 1.38462C8.4 0.619291 7.77383 0 7 0ZM6.69375 2.76923C6.79492 2.76112 6.89609 2.76923 7 2.76923C7.04375 2.76923 7.0875 2.76923 7.13125 2.76923C9.41719 2.83684 11.2 4.81911 11.2 7.11779V13.1538C11.2 13.3972 11.252 13.6271 11.3313 13.8462H2.66875C2.74805 13.6271 2.8 13.3972 2.8 13.1538V6.92308C2.8 4.72446 4.51172 2.92608 6.69375 2.76923ZM7 15.2308C7.39375 15.2308 7.7 15.5337 7.7 15.9231C7.7 16.3125 7.39375 16.6154 7 16.6154C6.60625 16.6154 6.3 16.3125 6.3 15.9231C6.3 15.5337 6.60625 15.2308 7 15.2308Z"
      fill="#333333"
    />
  </svg>
);

export default BellIcon;
