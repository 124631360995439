const MinusIconSecondary = ({ ...rest }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="8"
    height="1.364"
    viewBox="0 0 15 1.364"
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...rest}
  >
    <path fill="#333333" d="M5 15v1.364h15V15z" transform="translate(-5 -15)" />
  </svg>
);

export default MinusIconSecondary;
