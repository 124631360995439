const CookieIcon = ({ ...props }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg xmlns="http://www.w3.org/2000/svg" width="47.451" height="50.041" viewBox="0 0 47.451 50.041" {...props}>
    <g id="cookie" transform="translate(.001)">
      <g id="Ebene_1" transform="translate(-.001)">
        <path
          id="Pfad_3115"
          fill="#9b610d"
          d="M45.267 34.891a3.808 3.808 0 0 1-3.188-4.76l-.088-.044a3.81 3.81 0 0 1-1.571-5.154c.184-.346.066-1.187.331-1.451-.989-1.156-.879-2.309-.119-3.734.3-.573.519-2.93 1.027-3.267-.214-.878-2.532-1.733-2.074-2.593.506-.948.73-1.67 1.685-1.945-.034-.347 3.228 1.167 3.29.818A24.785 24.785 0 0 0 24.825 3C11.114 3 .043 10.13 0 27.826c-.014 5.755.778 10.5 4.063 14.714a27.683 27.683 0 0 0 20.762 10.112 24.829 24.829 0 0 0 22.394-14.1 3.811 3.811 0 0 1-1.952-3.664z"
          transform="translate(.001 -2.611)"
        />
        <path
          id="Pfad_3116"
          fill="#c48116"
          d="M50.9 31.63c-.276-.345-1.678-.512-2.326-1.031-.966-.778-.659-1.492-.609-2.379A2.218 2.218 0 0 0 47 26.364c-1.7-1-1.853-2.821-.9-4.61a7.952 7.952 0 0 0 .535-1.4c.252-1.007-1.079-2.333-.325-3.745.3-.567 1.01-.8 1.228-1.36.324-.835-.118-1.815.336-2.671.5-.94 1.736-1.037 2.289-1.854a.633.633 0 0 0 .078-.43 2.2 2.2 0 0 0-.497-1.194A24.568 24.568 0 0 0 30.525 0c-5.782.026-13.147 2.2-17.339 5.574C7.481 10.173 5.9 17.127 6.019 25c.109 7.081 1.767 12.186 6.648 16.613 4.369 3.96 11.607 7.62 17.967 7.62 5.943 0 12.654-2.041 16.906-5.546 2.621-2.16 3.528-4.916 5.074-7.974a.627.627 0 0 0-.145-.758c-1.803-1.592-.71-2.268-1.569-3.325z"
          transform="translate(-5.233 .003)"
        />
        <ellipse
          id="Ellipse_701"
          cx=".907"
          cy="1.361"
          fill="#5b3600"
          className="cls-3"
          rx=".907"
          ry="1.361"
          transform="translate(21.305 11.02)"
        />
        <ellipse
          id="Ellipse_702"
          cx=".907"
          cy="1.361"
          fill="#5b3600"
          className="cls-3"
          rx=".907"
          ry="1.361"
          transform="rotate(-45 41.327 -14.778)"
        />
        <ellipse
          id="Ellipse_703"
          cx=".907"
          cy="1.361"
          fill="#5b3600"
          className="cls-3"
          rx=".907"
          ry="1.361"
          transform="rotate(-30 47.018 -8.152)"
        />
        <ellipse
          id="Ellipse_704"
          cx="1.361"
          cy=".907"
          fill="#5b3600"
          className="cls-3"
          rx="1.361"
          ry=".907"
          transform="translate(26.945 35.586)"
        />
        <ellipse
          id="Ellipse_705"
          cx="1.361"
          cy=".907"
          fill="#5b3600"
          className="cls-3"
          rx="1.361"
          ry=".907"
          transform="rotate(-63.14 41.153 -13.127)"
        />
        <ellipse
          id="Ellipse_706"
          cx="1.361"
          cy=".907"
          fill="#5b3600"
          className="cls-3"
          rx="1.361"
          ry=".907"
          transform="rotate(-75.67 29.797 8.83)"
        />
        <ellipse
          id="Ellipse_707"
          cx="1.361"
          cy=".907"
          fill="#5b3600"
          className="cls-3"
          rx="1.361"
          ry=".907"
          transform="rotate(-45 33.516 -32.679)"
        />
      </g>
    </g>
  </svg>
);

export default CookieIcon;
