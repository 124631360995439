import useTranslation from 'next-translate/useTranslation';

const Footermenu = () => {
  const { t } = useTranslation('navigation-footer-menu');
  const portalTitleIsSet = t('portalTitle');

  return {
    isCopyright: t('portalTitle'),
    nav: [
      {
        title: portalTitleIsSet ? `© ${new Date().getFullYear()} ${t('portalTitle')}` : `© ${new Date().getFullYear()}`,
      },
      {
        title: t('hilfeTitle'),
        url: '/hilfe',
        portalLoginRequired: false,
      },
      {
        title: t('impressumTitle'),
        url: '/impressum',
        portalLoginRequired: false,
      },
      {
        title: t('agbTitle'),
        url: '/agb',
        portalLoginRequired: false,
      },
      {
        title: t('datenschutzTitle'),
        url: '/datenschutz',
        portalLoginRequired: false,
      },
      /* {
        title: 'Mein Bereich',
        url: '/dashboard',
        portalLoginRequired: true,
      },
      */
    ],
  };
};

export default Footermenu;
