const Pen = ({ ...props }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg xmlns="http://www.w3.org/2000/svg" width="1.658" height="8" viewBox="0 0 1.658 8" {...props}>
    <path
      d="M1.021-7.57h1.3v5.534h-1.3zm.653-.912a.832.832 0 0 1-.591-.218.728.728 0 0 1-.238-.554.728.728 0 0 1 .238-.554.832.832 0 0 1 .591-.223.855.855 0 0 1 .591.212.685.685 0 0 1 .235.529.781.781 0 0 1-.233.575.807.807 0 0 1-.593.233z"
      transform="rotate(180 1.252 -1.018)"
      fill="#fff"
    />
  </svg>
);

export default Pen;
