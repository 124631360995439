import PropTypes from 'prop-types';
import React, { forwardRef, useContext } from 'react';
import { Box, Button as RButton } from 'rebass/styled-components';
import styled, { ThemeContext } from 'styled-components';

import IconWrapper from '../IconWrapper/IconWrapper';
import Loader from '../Loader';

const ButtonLoaderWrapper = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledButton = styled(RButton)`
  &:focus {
    box-shadow: none;
  }
`;

const Button = forwardRef(({ children, isLoading, submit, variant, disabled, ...props }, ref) => {
  const theme = useContext(ThemeContext);

  const type = submit ? 'submit' : 'button';
  const iconColor = theme.buttons[variant].color;
  const isDisabled = disabled || isLoading;

  return (
    <StyledButton
      {...props}
      type={type}
      variant={variant}
      disabled={isDisabled}
      ref={ref}
      fontSize={['12px', '12px', '14px']}
    >
      <Box style={{ position: 'relative' }}>
        {isLoading ? (
          <ButtonLoaderWrapper>
            <Loader color={theme.colors.white} />
          </ButtonLoaderWrapper>
        ) : null}

        <IconWrapper {...props} iconColor={iconColor} isLoading={isLoading}>
          {children}
        </IconWrapper>
      </Box>
    </StyledButton>
  );
});

Button.propTypes = {
  children: PropTypes.node,
  variant: PropTypes.string,
  submit: PropTypes.bool,
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
};

Button.defaultProps = {
  children: null,
  variant: 'primary',
  submit: false,
  disabled: false,
  isLoading: false,
};

/** @component */
export default Button;
