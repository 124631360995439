import P from 'prop-types';

export default P.shape({
  title: P.string.isRequired,
  subtitle: P.string.isRequired,
  backgroundImage: P.string.isRequired,
  cards: P.arrayOf(
    P.shape({
      title: P.string.isRequired,
      icon: P.string.isRequired,
      subtitle: P.string.isRequired,
      subtext: P.string.isRequired,
      button: P.string.isRequired,
      url: P.string.isRequired,
    }).isRequired
  ).isRequired,
}).isRequired;
