const axios = require('axios').default;
const https = require('https');

const api = axios.create({
  baseURL: process.env.NEXT_PUBLIC_CMS,
  httpsAgent: new https.Agent({
    rejectUnauthorized: false,
  }),
});

module.exports = api;
