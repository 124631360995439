const ArrowIconBreadcrumb = ({ ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="4.3"
    height="8.3"
    viewBox="0 0 4.3 8.3"
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
  >
    <path
      d="M.435 4 0 3.607 3.783.188 4 0l.217.188L8 3.607 7.565 4 4 .778z"
      transform="rotate(90 2 2.15)"
      fill="#a09e9e"
      stroke="#a09e9e"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth=".3px"
    />
  </svg>
);

export default ArrowIconBreadcrumb;
