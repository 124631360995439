import useTranslation from 'next-translate/useTranslation';
import { useMemo } from 'react';

import { BellIcon } from '@/components/Icon';
import { useAccessMenu } from '@/hooks';
import { titleCase } from '@/utils';

const Mainmenu = () => {
  const { t } = useTranslation('navigation-header-menu');
  const { data, isSuccess } = useAccessMenu();

  const homeManagement = useMemo(
    () =>
      data?.homemanagement
        ? {
            title: t('subNavImmoVerwaltungTitle'),
            url: '/immobilien/eigenheim-verwaltung',
            text: t('subNavImmoVerwaltungText'),
            loginRequired: false,
          }
        : null,
    [data?.homemanagement, t]
  );

  const projectsHome = useMemo(
    () =>
      data?.projects
        ? {
            title: t('mbSubNavProjektPlaner'),
            url: '/projekte',
            loginRequired: true,
          }
        : null,
    [data?.projects, t]
  );

  const homeManagementHome = useMemo(
    () =>
      data?.homemanagement
        ? {
            title: t('mbSubNavImmoVerwaltung'),
            url: '/dashboard?area=realEstateManagement',
            loginRequired: true,
          }
        : null,
    [data?.homemanagement, t]
  );

  const services = useMemo(
    () =>
      data?.services
        ? {
            title: t('subNavServiceTitle'),
            url: '/projekte/dienstleister-finden',
            text: t('subNavServiceText'),
            loginRequired: false,
          }
        : null,
    [data?.services, t]
  );

  const projects = useMemo(
    () =>
      data?.projects
        ? {
            title: t('navProjekteTitle'),
            withInfo: t('navProjekteBadge', {}, { returnObjects: true }),
            infoText: t('navProjekteBadgeText'),
            url: '/projekte',
            type: 'nav',
            image: t('navProjekteImg.url'),
            subnav: [
              {
                title: t('subNavProjektTitle'),
                url: '/projekte',
                text: t('subNavProjektText'),
                loginRequired: false,
              },
              /* {
            title: t('subNavProjektNeuTitle'),
            url: '/projekte?newProject=true',
            text: t('subNavProjektNeuText'),
            loginRequired: false,
          }, */
              services,
            ].filter(Boolean),
          }
        : null,
    [data?.projects, services, t]
  );

  const insurance = useMemo(
    () =>
      data?.insurance
        ? {
            title: t('navVersicherungenTitle'),
            withInfo: t('navVersicherungenBadge', {}, { returnObjects: true }),
            infoText: t('navVersicherungenBadgeText'),
            url: '/versicherungen',
            type: 'nav',
            image: t('navVersicherungenImg.url'),
            subnav: [
              {
                title: t('subNavVersicherungenTitle'),
                url: '/versicherungen',
                text: t('subNavVersicherungenText'),
                loginRequired: false,
              },
              {
                title: t('subNavVersicherungenPhaseTitle'),
                url: '/versicherungen?abschnitt=phase',
                text: t('subNavVersicherungenPhaseText'),
                loginRequired: false,
              },
            ].filter(Boolean),
          }
        : null,
    [data?.insurance, t]
  );

  const subNavTariffCheck = useMemo(() => {
    return ['strom', 'gas', 'dsl'].map((v) => {
      const key = v === 'strom' ? 'energy' : v;

      if (!data?.[key]) {
        return null;
      }

      return {
        title: t(`subNav${titleCase(v)}Title`),
        url: `/tarifrechner/${v}`,
        text: t(`subNav${titleCase(v)}Text`),
        isEnabled: t(`subNav${titleCase(v)}Enable`, {}, { returnObjects: true }),
        loginRequired: true,
      };
    });
  }, [data, t]).filter(Boolean);

  const tariffCheck = useMemo(
    () =>
      data?.tariffcheck
        ? {
            title: t('navTarifTitle'),
            withInfo: t('navTarifBadge', {}, { returnObjects: true }),
            infoText: t('navTarifBadgeText'),
            url: '/tarifrechner',
            type: 'nav',
            enabled: t('tarifVergleich', {}, { returnObjects: true }),
            image: t('navTarifImg.url'),
            subnav: subNavTariffCheck,
          }
        : null,
    [data?.tariffcheck, subNavTariffCheck, t]
  );

  return {
    nav: [
      {
        title: t('navImmoTitle'),
        withInfo: t('navImmoBadge', {}, { returnObjects: true }),
        infoText: t('navImmoBadgeText'),
        url: '/immobilien',
        type: 'nav',
        image: t('navImmoImg.url'),
        subnav: [
          {
            title: t('subNavImmoTitle'),
            url: '/immobilien/immobiliensuche',
            text: t('subNavImmoText'),
            loginRequired: false,
          },
          homeManagement,
          /*
          {
            title: t('subNavSearchTitle'),
            url: '/mein-bereich',
            text: t('subNavSearchText'),
            loginRequired: true,
          },
          {
            title: t('subNavMemoTitle'),
            url: '/mein-bereich/merkzettel',
            text: t('subNavMemoText'),
            loginRequired: true,
          }, */
        ].filter(Boolean),
      },
      {
        title: t('navFinTitle'),
        withInfo: t('navFinBadge', {}, { returnObjects: true }),
        infoText: t('navFinBadgeText'),
        url: '/finanzierung',
        type: 'nav',
        image: t('navFinImg.url'),
        subnav: [
          {
            title: t('subNavBaufiTitle'),
            url: '/finanzierung/baufinanzierung',
            text: t('subNavBaufiText'),
            loginRequired: false,
          },
          {
            title: t('subNavHhrTitle'),
            url: '/finanzierung/haushaltsrechner?abschnitt=formular',
            text: t('subNavHhrText'),
            loginRequired: false,
          },
          {
            title: t('subNavZertTitle'),
            url: '/finanzierung/finanzierungszertifikat?abschnitt=formular',
            text: t('subNavZertText'),
            loginRequired: false,
          },
        ],
      },
      insurance,
      tariffCheck,
      projects,
      {
        title: 'notification',
        withInfo: t('navMbBadge', {}, { returnObjects: true }),
        infoText: t('navMbBadgeText'),
        type: 'notification',
        image: <BellIcon />,
      },
      {
        title: t('navMbTitle'),
        withInfo: t('navMbBadge', {}, { returnObjects: true }),
        infoText: t('navMbBadgeText'),
        url: '/dashboard',
        loginRequired: true,
        type: 'myPlace',
        subnav: isSuccess
          ? [
              {
                title: t('subNavAnfragenTitle'),
                url: '/mein-bereich/anfragen',
                loginRequired: true,
              },
              {
                title: t('subNavMerkzettelTitle'),
                url: '/mein-bereich/merkzettel',
                loginRequired: true,
              },
              {
                title: t('subNavDokuTitle'),
                url: '/dashboard?area=dokumentenCenter',
                loginRequired: true,
              },
              projectsHome,
              homeManagementHome,
              {
                title: t('subNavProfilTitle'),
                url: '/mein-bereich/profil-einstellungen',
                loginRequired: true,
              },
            ].filter(Boolean)
          : [],
      },
    ].filter(Boolean),
    additionLinkList: [
      {
        title: t('navHilfeTitle'),
        url: '/hilfe',
      },
    ],
    loginLogout: {
      logout: t('logout'),
      registered: {
        title: t('regTitle'),
        icon: '',
        url: '/mein-bereich/profil-einstellungen',
      },
      notRegistered: {
        title: t('notRegTitle'),
        button: t('notRegButton'),
      },
    },
    loginRegisterDialog: {
      title: t('regDialogTitle'),
      subtitle: t('regDialogSubtitle'),
      list: [
        'Anfragen einfach online stellen',
        'Ganz bequem Ihre Traumimmobilie finden',
        'Schnell und direkt Kontakt zu Experten aufnehmen',
      ],
      registerButton: 'KOSTENLOS REGISTRIEREN',
      loginButton: 'ANMELDEN',
    },
  };
};

export default Mainmenu;
