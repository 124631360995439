export default {
  /*
    0.75 = 12px
    0.875= 14px
    1rem = 16px
    1.5rem = 24px
    2rem = 32px
  */
  title: {
    color: 'darkGrey',
    fontSize: ['1.5rem', '2rem', '2rem'],
    fontWeight: ['800', '800', '800'],
    lineHeight: ['1.6', '1.44', '1.44'],
    marginBottom: ['20px', '32px', '32px'],
    marginTop: ['32px', '64px', '64px'],
  },
  headline: {
    fontSize: ['1.25rem', '1.5rem', '1.5rem'],
    fontWeight: ['700', '700', '700'],
    lineHeight: ['1.6', '1.4', '1.4'],
    marginBottom: ['32px', '32px', '32px'],
    marginTop: ['32px', '32px', '32px'],
  },
  headlineEstateData: {
    fontSize: ['0.875rem', '1rem', '1rem'],
    fontWeight: ['700', '700', '700'],
    lineHeight: ['1.6', '1.4', '1.4'],
    marginBottom: ['0rem', '0rem', '0rem'],
    marginTop: ['0rem', '0rem', '0rem'],
  },
  headlineProjects: {
    fontSize: ['1rem', '1rem', '1.5rem'],
    fontWeight: ['700', '700', '700'],
    lineHeight: ['1.6', '1.4', '1.4'],
    marginBottom: ['32px', '32px', '32px'],
    marginTop: ['32px', '32px', '32px'],
  },
  headlineGrey: {
    color: 'darkGrey',
    fontSize: ['1.25rem', '1.5rem', '1.5rem'],
    fontWeight: ['700', '700', '700'],
    lineHeight: ['1.6', '1.4', '1.4'],
    marginBottom: ['4px', '4px', '4px'],
    marginTop: ['4px', '4px', '4px'],
  },
  headlineLegal: {
    color: 'brownGrey',
    fontSize: ['1rem', '1rem', '1rem'],
    fontWeight: ['500', '500', '500'],
    lineHeight: ['1.6', '1.4', '1.4'],
    marginBottom: ['4px', '4px', '4px'],
    marginTop: ['4px', '4px', '4px'],
  },
  subtitle: {
    fontSize: ['1rem', '1.25rem', '1.25rem'],
    fontWeight: ['700', '700', '700'],
    lineHeight: ['1.6', '1.6', '1.6'],
    marginBottom: ['20px', '32px', '32px'],
    marginTop: ['32px', '64px', '64px'],
  },
  subtitlePrimary: {
    color: 'primary',
    fontSize: ['1.25rem', '1.25rem', '1.25rem'],
    fontWeight: ['700', '700', '700'],
    lineHeight: ['1.6', '1.6', '1.6'],
    marginBottom: ['1.25rem', '1.25rem', '1.25rem'],
    marginTop: ['0', '0', '0'],
  },
  amount: {
    color: 'primary',
    fontSize: ['1.2rem', '1.2rem', '1.2rem'],
    fontWeight: ['700', '700', '700'],
    letterSpacing: ['-0.26px', '-0.26px', '-0.26px'],
    marginBottom: ['6px', '6px', '6px'],
    marginTop: ['8px', '8px', '8px'],
  },
  cardHedline: {
    color: 'darkGrey',
    fontSize: ['1.2rem', '1.2rem', '1.2rem'],
    fontWeight: ['700', '700', '700'],
    letterSpacing: ['-0.26px', '-0.26px', '-0.26px'],
    marginBottom: ['6px', '6px', '6px'],
    marginTop: ['8px', '8px', '8px'],
  },
  cardHedlineRegular: {
    color: 'darkGrey',
    fontSize: ['1.2rem', '1.2rem', '1.2rem'],
    fontWeight: ['600', '600', '600'],
    letterSpacing: ['-0.26px', '-0.26px', '-0.26px'],
    marginBottom: ['6px', '6px', '6px'],
    marginTop: ['8px', '8px', '8px'],
  },
  subtitleSemiBold: {
    color: 'darkGrey',
    fontSize: ['1rem', '1.25rem', '1.25rem'],
    fontWeight: ['600', '600', '600'],
    lineHeight: ['1.6', '1.6', '1.6'],
    marginBottom: ['20px', '32px', '32px'],
    marginTop: ['32px', '50px', '50px'],
  },
  subtitleSemiBoldws: {
    color: 'darkGrey',
    fontSize: ['1.25rem', '1.5rem', '1.5rem'],
    fontWeight: ['700', '700', '700'],
    lineHeight: ['1.6', '1.6', '1.6'],
    marginBottom: ['0', '0', '0'],
    marginTop: ['0', '0', '0'],
  },
  subhead: {
    color: 'darkGrey',
    fontSize: ['0.875rem', '1rem', '1rem'],
    fontWeight: ['700', '700', '700'],
    lineHeight: ['1.6', '1.6', '1.6'],
    marginBottom: ['32px', '32px', '32px'],
    marginTop: ['0px', '0px', '0px'],
  },
  subheadAccordion: {
    color: 'darkGrey',
    fontSize: ['0.875rem', '1rem', '1rem'],
    fontWeight: ['700', '700', '700'],
    lineHeight: ['1', '1', '1'],
    marginBottom: ['0px', '0px', '0px'],
    marginTop: ['0px', '0px', '0px'],
  },
  accordionHeader: {
    color: 'darkGrey',
    fontSize: ['0.875rem', '1rem', '1rem'],
    fontWeight: ['500', '600', '600'],
    lineHeight: ['1.5', '1.5', '1.5'],
    marginBottom: ['6', '6', '6'],
    marginTop: ['6', '6', '6'],
  },
  accordionHeaderInsurance: {
    color: 'darkGrey',
    fontSize: ['0.875rem', '0.875rem', '0.875rem'],
    fontWeight: ['400', '400', '400'],
    lineHeight: ['1.5', '1.5', '1.5'],
    marginBottom: ['6', '6', '6'],
    marginTop: ['6', '6', '6'],
  },
  input: {
    color: 'darkGrey',
    fontSize: ['1rem', '1rem', '1rem'],
    fontWeight: ['600', '600', '600'],
    lineHeight: ['1.4', '1.4', '1.4'],
    marginBottom: ['4px', '4px', '4px'],
    marginTop: ['4px', '4px', '4px'],
  },
  inputLight: {
    color: 'darkGrey',
    fontSize: ['.875rem', '.875rem', '.875rem'],
    fontWeight: ['500', '500', '500'],
    lineHeight: ['1.5', '1.5', '1.5'],
    marginBottom: ['4px', '4px', '4px'],
    marginTop: ['4px', '4px', '4px'],
  },
  modalMenuItem: {
    color: 'darkGrey',
    fontSize: ['0.875rem', '0.875rem', '0.875rem'],
    fontWeight: ['600', '600', '600'],
    lineHeight: ['1.4', '1.4', '1.4'],
    marginBottom: ['0', '0', '0'],
    marginTop: ['0', '0', '0'],
    whiteSpace: 'nowrap',
  },
  inputLabelError: {
    color: 'primary',
    fontSize: ['0.75rem', '0.75rem', '0.75rem'],
    fontWeight: ['500', '500', '500'],
    lineHeight: ['1.3rem', '1.3rem', '1.3rem'],
    marginBottom: ['0', '0', '0'],
    marginTop: ['0', '0', '0'],
    maginLeft: ['4', '4', '4'],
    display: 'flex',
    alignItems: 'center',
  },
  inputLabel: {
    color: 'darkGrey',
    fontSize: ['0.75rem', '0.75rem', '0.75rem'],
    fontWeight: ['500', '500', '500'],
    lineHeight: ['1.3rem', '1.3rem', '1.3rem'],
    marginBottom: ['0', '0', '0'],
    marginTop: ['0', '0', '0'],
    maginLeft: ['4', '4', '4'],
    paddingTop: ['5', '5', '5'],
    paddingBottom: ['5', '5', '5'],
  },
  tooltipTitle: {
    color: '#FFF',
    fontSize: ['0.85rem', '0.85rem', '0.85rem'],
    fontWeight: ['600', '600', '600'],
    lineHeight: ['1.3rem', '1.3rem', '1.3rem'],
    marginBottom: ['0.75rem', '0.75rem', '0.75rem'],
    marginTop: ['0', '0', '0'],
    maginLeft: ['0', '0', '0'],
  },
  tooltipText: {
    color: '#FFF',
    fontSize: ['0.75rem', '0.75rem', '0.75rem'],
    fontWeight: ['500', '500', '500'],
    lineHeight: ['1.3rem', '1.3rem', '1.3rem'],
    marginBottom: ['0', '0', '0'],
    marginTop: ['0', '0', '0'],
    maginLeft: ['0', '0', '0'],
  },
  tooltipValue: {
    color: '#FFF',
    fontSize: ['0.875rem', '0.875rem', '0.875rem'],
    fontWeight: ['600', '600', '600'],
    lineHeight: ['1.3rem', '1.3rem', '1.3rem'],
    marginBottom: ['0', '0', '0'],
    marginTop: ['0', '0', '0'],
    maginLeft: ['0', '0', '0'],
  },
  tooltipTextBold: {
    color: '#FFF',
    fontSize: ['1rem', '1rem', '1rem'],
    fontWeight: ['800', '800', '800'],
    lineHeight: ['1.3rem', '1.3rem', '1.3rem'],
    marginBottom: ['0', '0', '0'],
    marginTop: ['0', '0', '0'],
    maginLeft: ['0', '0', '0'],
  },
  checklist: {
    color: 'darkGrey',
    fontSize: ['0.875rem', '0.875rem', '0.875rem'],
    fontWeight: ['500', '500', '500'],
    lineHeight: ['1.6', '1.6', '1.6'],
    marginBottom: ['4px', '4px', '4px'],
    marginTop: ['4px', '4px', '4px'],
    hyphens: ['auto', 'auto', 'auto'],
  },
  checklistInsurance: {
    color: 'darkGrey',
    fontSize: ['0.875rem', '0.875rem', '0.875rem'],
    fontWeight: ['400', '400', '400'],
    lineHeight: ['1.6', '1.6', '1.6'],
    marginBottom: ['4px', '4px', '4px'],
    marginTop: ['4px', '4px', '4px'],
    hyphens: ['auto', 'auto', 'auto'],
  },
  body: {
    color: 'darkGrey',
    fontSize: ['0.875rem', '1rem', '1rem'],
    fontWeight: ['500', '500', '500'],
    lineHeight: ['1.6', '1.6', '1.6'],
    marginBottom: ['4px', '4px', '4px'],
    marginTop: ['4px', '4px', '4px'],
    hyphens: ['auto', 'auto', 'auto'],
  },
  bodyLightSmall: {
    color: 'darkGrey',
    fontSize: ['0.8rem', '0.8rem', '0.875rem'],
    fontWeight: ['500', '500', '500'],
    lineHeight: ['1.71', '1.71', '1.71'],
    marginBottom: ['1rem', '1rem', '1rem'],
    marginTop: ['0', '0', '0'],
  },
  bodyLightSmallNativ: {
    color: 'darkGrey',
    fontSize: ['0.8rem', '0.8rem', '0.875rem'],
    fontWeight: ['500', '500', '500'],
    lineHeight: ['1.67', '1.67', '1.67'],
    marginBottom: ['0', '0', '0'],
    marginTop: ['0', '0', '0'],
  },
  bodyBoldSmall: {
    color: 'darkGrey',
    fontSize: ['0.8rem', '0.8rem', '0.875rem'],
    fontWeight: ['600', '600', '600'],
    lineHeight: ['1.5', '1.5', '1.5'],
    marginBottom: ['0.75rem', '0.75rem', '0.75rem'],
    marginTop: ['0', '0', '0'],
  },
  bodyBoldSmallNativ: {
    color: 'darkGrey',
    fontSize: ['0.8rem', '0.8rem', '0.875rem'],
    fontWeight: ['600', '600', '600'],
    lineHeight: ['1.5', '1.5', '1.5'],
    marginBottom: ['0', '0', '0'],
    marginTop: ['0', '0', '0'],
    cursor: 'pointer',
  },
  bodyLightSmallOc: {
    color: 'darkGrey',
    fontSize: ['0.875rem', '0.875rem', '0.875rem'],
    fontWeight: ['500', '500', '500'],
    lineHeight: ['1.71', '1.71', '1.71'],
    marginBottom: ['1rem', '1rem', '1rem'],
    marginTop: ['0', '0', '0'],
  },
  bodySubtaskDetail: {
    color: 'darkGrey',
    fontSize: ['0.75rem', '0.75rem', '0.875rem'],
    fontWeight: ['500', '500', '500'],
    lineHeight: ['1.25', '1.25', '1.5'],
    marginBottom: ['1rem', '1rem', '1rem'],
    marginTop: ['0', '0', '0'],
    cursor: 'pointer',
  },
  bodyPrimary: {
    color: 'primary',
    fontSize: ['0.875rem', '1rem', '1rem'],
    fontWeight: ['500', '500', '500'],
    lineHeight: ['1.6', '1.6', '1.6'],
    marginBottom: ['4px', '4px', '4px'],
    marginTop: ['4px', '4px', '4px'],
  },
  bodyBold: {
    color: 'darkGrey',
    fontSize: ['0.875rem', '1rem', '1rem'],
    fontWeight: ['600', '600', '600'],
    lineHeight: ['1.6', '1.6', '1.6'],
    marginBottom: ['4px', '4px', '4px'],
    marginTop: ['4px', '4px', '4px'],
    hyphens: ['auto', 'auto', 'auto'],
  },
  bodyBoldRight: {
    color: 'darkGrey',
    fontSize: ['0.875rem', '1rem', '1rem'],
    fontWeight: ['600', '600', '600'],
    lineHeight: ['1.6', '1.6', '1.6'],
    marginBottom: ['4px', '4px', '4px'],
    marginTop: ['4px', '4px', '4px'],
    textAlign: 'right',
  },
  bodyBoldEstate: {
    color: 'darkGrey',
    fontSize: ['0.875rem', '1rem', '1rem'],
    fontWeight: ['600', '600', '600'],
    lineHeight: ['1.6', '1.6', '1.6'],
    marginBottom: ['4px', '4px', '4px'],
    marginTop: ['4px', '4px', '4px'],
  },
  subheadSmallCount: {
    lineHeight: ['1.875', '1.875', '1.875'],
    fontSize: ['0.75rem', '0.75rem', '0.75rem'],
    fontWeight: ['700', '700', '700'],
  },
  subheadSmallEstate: {
    color: 'darkGrey',
    fontSize: ['0.875rem', '0.875rem', '0.875rem'],
    fontWeight: ['700', '700', '700'],
    lineHeight: ['1.6', '1.6', '1.6'],
    marginBottom: ['1.25rem', '1.25rem', '1.25rem'],
    marginTop: ['0px', '0px', '0px'],
  },
  subheadSmallService: {
    color: 'darkGrey',
    fontSize: ['0.875rem', '0.875rem', '0.875rem'],
    fontWeight: ['600', '600', '600'],
    lineHeight: ['1.6', '1.6', '1.6'],
    marginBottom: ['1rem', '1rem', '1rem'],
    marginTop: ['1.125rem', '1.125rem', '1.125rem'],
  },
  smallEstate: {
    color: 'darkGrey',
    fontSize: ['0.75rem', '0.75rem', '0.75rem'],
    fontWeight: ['500', '500', '500'],
    lineHeight: ['1.3rem', '1.3rem', '1.3rem'],
    marginBottom: ['10px', '10px', '10px'],
    marginTop: ['2px', '2px', '2px'],
    whiteSpace: ['pre-wrap', 'pre-wrap', 'pre-wrap'],
  },
  smallBoldEstate: {
    color: 'darkGrey',
    fontSize: ['0.75rem', '0.75rem', '0.75rem'],
    fontWeight: ['600', '600', '600'],
    lineHeight: ['1.3rem', '1.3rem', '1.3rem'],
    marginBottom: ['10px', '10px', '10px'],
    marginTop: ['2px', '2px', '2px'],
    whiteSpace: ['pre-wrap', 'pre-wrap', 'pre-wrap'],
    hyphens: ['auto', 'auto', 'auto'],
  },
  smallBoldWithoutSpace: {
    color: 'darkGrey',
    fontSize: ['0.75rem', '0.75rem', '0.75rem'],
    fontWeight: ['600', '600', '600'],
    lineHeight: ['1.3rem', '1.3rem', '1.3rem'],
    marginBottom: ['4px', '4px', '4px'],
    marginTop: ['4px', '4px', '4px'],
    whiteSpace: ['nowrap', 'nowrap', 'nowrap'],
  },
  checkListItemText: {
    color: 'darkGrey',
    fontSize: ['0.75rem', '0.75rem', '0.75rem'],
    fontWeight: ['500', '500', '500'],
    lineHeight: ['1.3rem', '1.3rem', '1.3rem'],
    marginBottom: ['4px', '4px', '4px'],
    marginTop: ['4px', '4px', '4px'],
    whiteSpace: ['nowrap', 'nowrap', 'nowrap'],
  },
  subheadSmall: {
    color: 'darkGrey',
    fontSize: ['0.875rem', '0.875rem', '0.875rem'],
    fontWeight: ['700', '700', '700'],
    lineHeight: ['1.6', '1.6', '1.6'],
    marginBottom: ['32px', '32px', '32px'],
    marginTop: ['0px', '0px', '0px'],
  },
  estateCardText: {
    color: 'darkGrey',
    fontSize: ['0.875rem', '0.875rem', '0.875rem'],
    fontWeight: ['500', '500', '500'],
    lineHeight: ['1.6', '1.6', '1.6'],
    marginBottom: ['4px', '4px', '4px'],
    marginTop: ['4px', '4px', '4px'],
  },
  taskTextLight: {
    color: 'primaryDisabled',
    fontSize: ['0.875rem', '0.875rem', '1rem'],
    fontWeight: ['600', '600', '600'],
    lineHeight: ['1.8', '1.8', '1.8'],
    marginBottom: ['4px', '4px', '4px'],
    marginTop: ['4px', '4px', '4px'],
  },
  taskText: {
    color: 'darkGrey',
    fontSize: ['0.875rem', '0.875rem', '1rem'],
    fontWeight: ['600', '600', '600'],
    lineHeight: ['1.8', '1.8', '1.8'],
    marginBottom: ['4px', '4px', '4px'],
    marginTop: ['4px', '4px', '4px'],
  },
  taskTextDel: {
    color: 'primaryDisabled',
    fontSize: ['0.875rem', '0.875rem', '1rem'],
    fontWeight: ['600', '600', '600'],
    lineHeight: ['1.8', '1.8', '1.8'],
    marginBottom: ['4px', '4px', '4px'],
    marginTop: ['4px', '4px', '4px'],
    textDecoration: 'line-through',
  },
  subTaskText: {
    color: 'darkGrey',
    fontSize: ['0.75rem', '0.75rem', '0.875rem'],
    fontWeight: ['600', '600', '600'],
    lineHeight: ['1.5', '1.5', '1.5'],
    marginBottom: ['4px', '4px', '4px'],
    marginTop: ['4px', '4px', '4px'],
  },
  subTaskTextDel: {
    color: 'primaryDisabled',
    fontSize: ['0.75rem', '0.75rem', '0.875rem'],
    fontWeight: ['600', '600', '600'],
    lineHeight: ['1.5', '1.5', '1.5 '],
    marginBottom: ['4px', '4px', '4px'],
    marginTop: ['4px', '4px', '4px'],
    textDecoration: 'line-through',
  },
  tabTitle: {
    color: 'darkGrey',
    fontSize: ['0.875rem', '0.875rem', '0.875rem'],
    fontWeight: ['600', '600', '600'],
    lineHeight: ['1.6', '1.6', '1.6'],
  },
  tabTitleInactive: {
    color: 'primaryDisabled',
    fontSize: ['0.875rem', '0.875rem', '0.875rem'],
    fontWeight: ['600', '600', '600'],
    lineHeight: ['1.6', '1.6', '1.6'],
  },
  subheadCard: {
    color: 'darkGrey',
    fontSize: ['0.875rem', '0.875rem', '0.875rem'],
    fontWeight: ['600', '600', '600'],
    lineHeight: ['1.6', '1.6', '1.6'],
    marginBottom: ['0px', '0px', '0px'],
    marginTop: ['0px', '0px', '0px'],
  },
  capsTitle: {
    color: 'primaryDisabled',
    fontSize: ['0.875rem', '0.875rem', '0.875rem'],
    fontWeight: ['500', '500', '500'],
    lineHeight: ['1.6', '1.6', '1.6'],
    marginBottom: ['20px', '20px', '20px'],
    marginTop: ['0px', '0px', '0px'],
    textTransform: 'uppercase',
  },
  capsTitleBold: {
    color: 'primaryDisabled',
    fontSize: ['0.875rem', '0.875rem', '0.875rem'],
    fontWeight: ['600', '600', '600'],
    lineHeight: ['1.6', '1.6', '1.6'],
    marginBottom: ['20px', '20px', '20px'],
    marginTop: ['0px', '0px', '0px'],
    textTransform: 'uppercase',
  },
  capsTitleDark: {
    color: 'darkGrey',
    fontSize: ['0.875rem', '0.875rem', '0.875rem'],
    fontWeight: ['500', '500', '500'],
    hyphens: 'auto',
    lineHeight: ['1.6', '1.6', '1.6'],
    marginBottom: ['4px', '4px', '4px'],
    marginTop: ['4px', '4px', '4px'],
    textTransform: 'uppercase',
  },
  small: {
    color: 'darkGrey',
    fontSize: ['0.75rem', '0.75rem', '0.75rem'],
    fontWeight: ['500', '500', '500'],
    lineHeight: ['1.3rem', '1.3rem', '1.3rem'],
    marginBottom: ['4px', '4px', '4px'],
    marginTop: ['4px', '4px', '4px'],
  },
  smallHyphens: {
    color: 'darkGrey',
    fontSize: ['0.75rem', '0.75rem', '0.75rem'],
    fontWeight: ['500', '500', '500'],
    lineHeight: ['1.3rem', '1.3rem', '1.3rem'],
    marginBottom: ['4px', '4px', '4px'],
    marginTop: ['4px', '4px', '4px'],
    hyphens: ['auto', 'auto', 'auto'],
  },
  smallEstateData: {
    color: 'darkGrey',
    fontSize: ['0.625rem', '0.75rem', '0.75rem'],
    fontWeight: ['500', '500', '500'],
    lineHeight: ['1.3rem', '1.3rem', '1.3rem'],
    marginBottom: ['4px', '4px', '4px'],
    marginTop: ['4px', '4px', '4px'],
    hyphens: ['auto', 'auto', 'auto'],
  },
  smallLight: {
    color: 'primaryDisabled',
    fontSize: ['0.75rem', '0.75rem', '0.75rem'],
    fontWeight: ['500', '500', '500'],
    lineHeight: ['1.3rem', '1.3rem', '1.3rem'],
    marginBottom: ['4px', '4px', '4px'],
    marginTop: ['4px', '4px', '4px'],
  },
  smallLightNobreak: {
    color: 'primaryDisabled',
    fontSize: ['0.75rem', '0.75rem', '0.75rem'],
    fontWeight: ['500', '500', '500'],
    lineHeight: ['1.3rem', '1.3rem', '1.3rem'],
    marginBottom: ['4px', '4px', '4px'],
    marginTop: ['4px', '4px', '4px'],
    whiteSpace: ['nowrap', 'nowrap', 'nowrap'],
  },
  smallBold: {
    color: 'darkGrey',
    fontSize: ['0.75rem', '0.75rem', '0.75rem'],
    fontWeight: ['700', '700', '700'],
    lineHeight: ['1.3rem', '1.3rem', '1.3rem'],
    marginBottom: ['10px', '10px', '10px'],
    marginTop: ['2px', '2px', '2px'],
    whiteSpace: ['pre-wrap', 'pre-wrap', 'pre-wrap'],
  },
  smallPrimary: {
    color: 'primary',
    fontSize: ['0.75rem', '0.75rem', '0.75rem'],
    fontWeight: ['500', '500', '500'],
    lineHeight: ['1.3rem', '1.3rem', '1.3rem'],
    marginBottom: ['4px', '4px', '4px'],
    marginTop: ['4px', '4px', '4px'],
  },
  smallPrimaryLink: {
    color: 'primary',
    fontSize: ['0.75rem', '0.75rem', '0.75rem'],
    fontWeight: ['500', '500', '500'],
    lineHeight: ['1.3rem', '1.3rem', '1.3rem'],
    marginBottom: ['4px', '4px', '4px'],
    marginTop: ['4px', '4px', '4px'],
    cursor: 'pointer',
  },
  primaryLink: {
    color: 'primary',
    fontSize: ['0.875rem', '0.875rem', '0.875rem'],
    fontWeight: ['600', '600', '600'],
    lineHeight: ['1.3rem', '1.3rem', '1.3rem'],
    marginBottom: ['4px', '4px', '4px'],
    marginTop: ['4px', '4px', '4px'],
    cursor: 'pointer',
  },
  smallPrimaryBold: {
    color: 'primary',
    fontSize: ['0.75rem', '0.75rem', '0.75rem'],
    fontWeight: ['700', '700', '700'],
    lineHeight: ['1.3rem', '1.3rem', '1.3rem'],
    marginBottom: ['4px', '4px', '4px'],
    marginTop: ['4px', '4px', '4px'],
  },
  extendCard: {
    color: 'primary',
    fontSize: ['0.875rem', '0.875rem', '0.875rem'],
    fontWeight: ['500', '500', '500'],
    lineHeight: ['1.3rem', '1.3rem', '1.3rem'],
    marginBottom: ['4px', '4px', '4px'],
    marginTop: ['4px', '4px', '4px'],
  },
  noisyButton: {
    fontSize: ['0.5625rem', '0.5625rem', '0.875rem'],
    fontWeight: ['500', '500', '500'],
  },
  contactTitle: {
    color: 'darkGrey',
    fontSize: ['0.625rem', '0.625rem', '0.625rem'],
    fontWeight: ['500', '500', '500'],
    marginTop: ['.3rem', '.3rem', '.3rem'],
  },
  link: {
    color: 'primary',
    fontSize: ['0.75rem', '0.75rem', '0.75rem'],
    fontWeight: ['500', '500', '500'],
    lineHeight: ['1.5', '1.5', '1.5'],
    marginBottom: ['15px', '15px', '15px'],
    marginTop: ['15px', '15px', '15px'],
  },
  nav: {
    color: 'darkGrey',
    fontSize: ['0.875rem', '0.875rem', '0.875rem'],
    fontWeight: ['500', '500', '500'],
    lineHeight: ['1.5', '1.5', '1.5'],
    marginBottom: ['0', '0', '0'],
    marginTop: ['0', '0', '0'],
  },
  navItem: {
    color: 'darkGrey',
    fontSize: ['0.813rem', '0.813rem', '0.813rem'],
    fontWeight: ['500', '500', '500'],
    lineHeight: ['65px', '65px', '65px'],
    marginBottom: ['0', '0', '0'],
    marginTop: ['0', '0', '0'],
  },
  navBold: {
    color: 'darkGrey',
    fontSize: ['0.875rem', '0.875rem', '0.875rem'],
    fontWeight: ['600', '600', '600'],
    marginBottom: ['0', '0', '0'],
    marginTop: ['0', '0', '0'],
    lineHeight: ['1.5', '1.5', '1.5'],
    cursor: 'pointer',
  },
  navSub: {
    color: 'darkGrey',
    fontSize: ['0.875rem', '0.875rem', '0.875rem'],
    fontWeight: ['500', '500', '500'],
    lineHeight: ['1.5', '1.5', '1.5'],
    marginBottom: ['0', '0', '0'],
    marginTop: ['0.5rem', '0.5rem', '0.5rem'],
    paddingTop: ['0.5rem', '0.5rem', '0.5rem'],
    pddingBottom: ['0.5rem', '0.5rem', '0.5rem'],
    cursor: 'pointer',
  },
  navSubMobil: {
    color: 'darkGrey',
    fontSize: ['0.875rem', '0.875rem', '0.875rem'],
    fontWeight: ['500', '500', '500'],
    lineHeight: ['1.5', '1.5', '1.5'],
    marginBottom: ['1rem', '1rem', '1rem'],
    marginTop: ['0', '0', '0'],
    cursor: 'pointer',
  },
  navBoldMobil: {
    color: 'darkGrey',
    fontSize: ['1rem', '1rem', '1rem'],
    fontWeight: ['600', '600', '600'],
    lineHeight: ['1.5', '1.5', '1.5'],
    marginBottom: ['6', '6', '6'],
    marginTop: ['6', '6', '6'],
  },
  navInfoText: {
    color: 'white',
    fontSize: ['0.563rem', '0.563rem', '0.563rem'],
    fontWeight: ['500', '500', '500'],
    lineHeight: ['1.125', '1.125', '1.125'],
    marginTop: ['4px', '4px', '4px'],
    marginBottom: ['4px', '4px', '4px'],
    marginLeft: ['4px', '4px', '4px'],
    marginRight: ['4px', '4px', '4px'],
    textTransform: 'uppercase',
  },
  notificationCount: {
    color: 'white',
    fontSize: ['0.563rem', '0.563rem', '0.563rem'],
    fontWeight: ['500', '500', '500'],
  },
  cardInfoText: {
    color: 'darkGrey',
    fontSize: ['0.563rem', '0.563rem', '0.563rem'],
    fontWeight: ['500', '500', '500'],
    lineHeight: ['1.125', '1.125', '1.125'],
  },
  navFooter: {
    color: 'primary',
    fontSize: ['0.75rem', '0.75rem', '0.75rem'],
    fontWeight: ['500', '500', '500'],
    lineHeight: ['1.5', '1.5', '1.5'],
    marginBottom: ['15px', '15px', '15px'],
    marginTop: ['15px', '15px', '15px'],
  },
  navFooterItem: {
    color: 'primaryDisabled',
    fontSize: ['0.875rem', '0.875rem', '0.875rem'],
    fontWeight: ['600', '600', '600'],
    lineHeight: ['1.25rem', '1.25rem', '1.25rem'],
    marginBottom: ['0.7rem', '0.7rem', '0.7rem'],
    marginTop: ['0.7rem', '0.7rem', '0.7rem'],
    textTransform: 'uppercase',
  },
  navFooterLink: {
    color: 'darkGrey',
    fontSize: ['0.875rem', '0.875rem', '0.875rem'],
    fontWeight: ['600', '600', '600'],
    lineHeight: ['1.25rem', '1.25rem', '1.25rem'],
    marginBottom: ['0.6rem', '0.6rem', '0.6rem'],
    marginTop: ['0.6rem', '0.6rem', '0.6rem'],
    cursor: 'pointer',
    '&:hover': {
      color: 'hoverRed',
    },
  },
  navFooterLinkDefault: {
    color: 'darkGrey',
    fontSize: ['0.875rem', '0.875rem', '0.875rem'],
    fontWeight: ['500', '500', '500'],
    lineHeight: ['2.25rem', '2.25rem', '2.25rem'],
    marginBottom: ['0', '0', '0'],
    marginTop: ['0', '0', '0'],
    cursor: 'pointer',
    '&:hover': {
      color: 'hoverRed',
    },
  },
  navFooterLinkSpecial: {
    color: 'darkGrey',
    fontSize: ['0.75rem', '0.75rem', '0.75rem'],
    fontWeight: ['500', '500', '500'],
    lineHeight: ['2.25rem', '2.25rem', '2.25rem'],
    whiteSpace: 'nowrap',
    display: 'inline',
    cursor: 'pointer',
    '&:hover': {
      color: 'hoverRed',
    },
  },
  navFooterText: {
    color: 'darkGrey',
    fontSize: ['0.875rem', '0.875rem', '0.875rem'],
    fontWeight: ['500', '500', '500'],
    lineHeight: ['1.5rem', '1.5rem', '1.5rem'],
    marginBottom: ['0', '0', '0'],
    marginTop: ['0', '0', '0'],
  },
  inputProject: {
    fontFamily: 'Plus Jakarta Sans',
    color: 'darkGrey',
    fontSize: ['1rem', '1rem', '1.5rem'],
    fontWeight: ['700', '700', '700'],
    lineHeight: ['1.6', '1.4', '1.4'],
    marginBottom: ['0', '0', '0'],
    marginTop: ['0', '0', '0'],
    border: '1px solid',
    borderRadius: '3px',
    paddingLeft: '0.422rem',
    paddingRight: '0.422rem',
    paddingTop: '0.438rem',
    paddingBottom: '0.375rem',
  },
  inputTask: {
    fontFamily: 'Plus Jakarta Sans',
    color: 'darkGrey',
    fontSize: ['0.875rem', '0.875rem', '1rem'],
    fontWeight: ['600', '600', '600'],
    lineHeight: ['1.8', '1.8', '1.8'],
    border: '1px solid',
    borderRadius: '3px',
    paddingLeft: '0.422rem',
    paddingRight: '0.422rem',
    paddingTop: '0.719rem',
    paddingBottom: '0.719rem',
  },
  inputTaskDel: {
    fontFamily: 'Plus Jakarta Sans',
    color: 'primaryDisabled',
    fontSize: ['0.875rem', '0.875rem', '1rem'],
    fontWeight: ['600', '600', '600'],
    lineHeight: ['1.8', '1.8', '1.8'],
    border: '1px solid',
    borderRadius: '3px',
    paddingLeft: '0.422rem',
    paddingRight: '0.422rem',
    paddingTop: '0.719rem',
    paddingBottom: '0.719rem',
    textDecoration: 'line-through',
  },
  inputSubTask: {
    fontFamily: 'Plus Jakarta Sans',
    color: 'darkGrey',
    fontSize: ['0.875rem', '0.875rem', '0.875rem'],
    fontWeight: ['600', '600', '600'],
    lineHeight: ['1.5', '1.5', '1.5'],
    border: '1px solid',
    borderRadius: '3px',
    paddingLeft: '0.422rem',
    paddingRight: '0.422rem',
    paddingTop: '0.719rem',
    paddingBottom: '0.719rem',
  },
  inputSubTaskDel: {
    fontFamily: 'Plus Jakarta Sans',
    color: 'primaryDisabled',
    fontSize: ['0.5rem', '0.5rem', '0.875rem'],
    fontWeight: ['600', '600', '600'],
    lineHeight: ['1.5', '1.5', '1.5'],
    border: '1px solid',
    borderRadius: '3px',
    paddingLeft: '0.422rem',
    paddingRight: '0.422rem',
    paddingTop: '0.719rem',
    paddingBottom: '0.719rem',
    textDecoration: 'line-through',
  },
  bodySubtaskDetailEditable: {
    fontFamily: 'Plus Jakarta Sans',
    color: 'darkGrey',
    fontSize: ['0.75rem', '0.75rem', '0.875rem'],
    fontWeight: ['500', '500', '500'],
    lineHeight: ['1.25', '1.25', '1.5'],
    border: '1px solid',
    borderRadius: '3px',
    paddingLeft: '0.422rem',
    paddingRight: '0.422rem',
    paddingTop: '0.719rem',
    paddingBottom: '0.719rem',
  },
  textPlaceholder: {
    color: 'primaryDisabled',
    fontSize: ['0.875rem', '0.875rem', '0.875rem'],
    fontWeight: ['500', '500', '500'],
    lineHeight: ['1.6', '1.6', '1.6'],
    marginBottom: ['20px', '20px', '20px'],
    marginTop: ['0px', '0px', '0px'],
  },
  textDue: {
    fontSize: ['0.75rem', '0.75rem', '0.75rem'],
    fontWeight: ['600', '600', '600'],
  },
  calendarEntry: {
    color: 'darkGrey',
    fontSize: ['0.625rem', '0.625rem', '0.625rem'],
    fontWeight: ['500', '500', '500'],
  },
  calendarHasDate: {
    color: 'primaryDisabled',
    fontSize: ['0.75rem', '0.75rem', '0.75rem'],
    fontWeight: ['600', '600', '600'],
    textTransform: 'uppercase',
  },
  chipText: {
    color: 'darkGrey',
    fontSize: ['0.75rem', '0.75rem', '0.75rem'],
  },
  dashBoardWidgetTitle: {
    color: 'darkGrey',
    fontSize: ['1rem', '1rem', '1rem'],
    fontWeight: ['600', '600', '600'],
    lineHeight: ['1.19', '1.19', '1.19'],
    marginBottom: ['0', '0', '0'],
    marginTop: ['0', '0', '0'],
  },
  cardDashboardTitle: {
    color: 'darkGrey',
    fontSize: ['0.75rem', '0.938rem', '0.938rem'],
  },
  cardDashboardHeadline: {
    color: 'darkGrey',
    fontSize: ['1rem', '1.25rem', '1.25rem'],
    fontWeight: ['700', '700', '700'],
    marginBottom: ['0.563rem', '0.563rem', '0.563rem'],
    marginTop: ['0.563rem', '0.563rem', '0.563rem'],
  },
  cardDashboardNumber: {
    color: 'white',
    fontSize: ['1.375rem', '1.375rem', '1.375rem'],
    fontWeight: ['600', '600', '600'],
  },
  modalHeadline: {
    fontSize: ['1.25rem', '1.25rem', '1.25rem'],
    fontWeight: ['700', '700', '700'],
    lineHeight: ['1.6', '1.4', '1.4'],
    marginBottom: ['32px', '32px', '32px'],
    marginTop: ['32px', '32px', '32px'],
  },
  notificationTitle: {
    color: 'darkGrey',
    fontSize: ['0.875rem', '0.875rem', '0.875rem'],
    fontWeight: ['600', '600', '600'],
    lineHeigh: ['1.6', '1.44', '1.44'],
    marginBottom: ['0', '0', '0'],
    marginTop: ['0', '0', '0'],
    paddingTop: ['0', '0', '0'],
  },
  notificationText: {
    color: 'darkGrey',
    fontSize: ['0.75rem', '0.75rem', '0.75rem'],
    fontWeight: ['500', '500', '500'],
    lineHeight: ['20px', '20px', '20px'],
    marginBottom: ['0', '0', '0'],
    marginTop: ['0', '0', '0'],
  },
  formAreaTitle: {
    color: '#A09E9E',
    textTransform: 'uppercase',
    fontWeight: '600',
    lineHeight: '1.25rem',
    fontSize: '.75rem',
    marginBottom: '.875rem',
  },
  formFootNote: {
    color: '#A09E9E',
    fontWeight: '500',
    lineHeight: '1.3rem',
    fontSize: '.75rem',
  },
  errorTitle: {
    color: '#A70000',
    fontWeight: ['700', '700', '700'],
    fontSize: ['.875rem', '.875rem', '.875rem'],
    lineHeight: ['24px', '24px', '24px'],
  },
  errorText: {
    color: 'darkGrey',
    fontWeight: ['500', '500', '500'],
    fontSize: ['.875rem', '.875rem', '.875rem'],
    lineHeight: ['24px', '24px', '24px'],
  },
};
