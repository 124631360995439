import React from 'react';

const LocationIcon = ({ width = '9.455px', height = '13px', ...props }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 9.455 13" {...props}>
    <path
      fill="#333333"
      d="M19.727 8A4.727 4.727 0 0 0 15 12.727C15 16.273 19.727 21 19.727 21s4.727-4.727 4.727-8.273A4.727 4.727 0 0 0 19.727 8zm0 2.364a2.364 2.364 0 1 1-2.364 2.364 2.363 2.363 0 0 1 2.364-2.364z"
      transform="translate(-15 -8)"
    />
  </svg>
);

export default LocationIcon;
