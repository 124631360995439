import { useFormikContext } from 'formik';
import { useEffect, useState } from 'react';

const checkErrors = (deps, errors) => deps.reduce((prev, curr) => prev && !errors[curr], true);

const useValidDependingFields = (deps, initialValid, ignoreIsDirty = false) => {
  const { dirty, errors, validateForm } = useFormikContext();
  const [valid, setValid] = useState(false);

  useEffect(() => {
    if (dirty || ignoreIsDirty) {
      if (Object.keys(errors).length === 0) {
        validateForm().then((ferrors) => {
          setValid(checkErrors(deps, ferrors));
        });
      } else {
        setValid(checkErrors(deps, errors));
      }
    }
  }, [deps, dirty, errors, ignoreIsDirty, validateForm]);

  if (!dirty && !ignoreIsDirty) return !!initialValid;

  return valid;
};

export default useValidDependingFields;
