const Check = (props) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg xmlns="http://www.w3.org/2000/svg" width="10" height="8" viewBox="0 0 10 8" fill="none" {...props}>
    <path
      d="M8.66814 1.49756L3.90374 6.26196L1.89632 4.25454L1.5 4.65085L3.70558 6.85643L3.90374 7.04597L4.1019 6.85643L9.06445 1.89387L8.66814 1.49756Z"
      fill="white"
      stroke="white"
    />
  </svg>
);

export default Check;
