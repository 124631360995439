import PropTypes from 'prop-types';

const FeedbackSuccess = ({ color, ...rest }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" {...rest}>
    <path
      fill={color}
      d="M13 3a10 10 0 1 0 10 10A10.012 10.012 0 0 0 13 3zm0 1.538A8.462 8.462 0 1 1 4.538 13 8.451 8.451 0 0 1 13 4.538zm-.769 3.846v1.539h1.538V8.385zm0 3.077v6.154h1.538v-6.153z"
      transform="translate(-3 -3)"
    />
  </svg>
);

FeedbackSuccess.propTypes = {
  color: PropTypes.string,
};

FeedbackSuccess.defaultProps = {
  color: '155724',
};

export default FeedbackSuccess;
