import { themeGet } from '@styled-system/theme-get';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { Flex } from 'rebass/styled-components';
import { ThemeContext } from 'styled-components';

const IconWrapper = ({ children, Icon, iconPosition, iconColor, isLoading }) => {
  const theme = useContext(ThemeContext);
  let content = children;

  if (Icon?.length > 0) {
    const isRight = iconPosition === 'right';
    const icon = (
      <Icon
        color={themeGet(iconColor, iconColor ?? 'white')({ theme })}
        style={{ [isRight ? 'marginLeft' : 'marginRight']: '10px' }}
      />
    );

    if (isRight)
      content = (
        <>
          {children}
          {icon}
        </>
      );
    else
      content = (
        <>
          {icon}
          {children}
        </>
      );
  }

  return (
    <Flex style={{ opacity: isLoading ? 0 : 1 }} alignItems="center" justifyContent="center">
      {content}
    </Flex>
  );
};

IconWrapper.propTypes = {
  Icon: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  iconColor: PropTypes.string,
  iconPosition: PropTypes.oneOf(['right', 'left']),
  isLoading: PropTypes.bool,
};

IconWrapper.defaultProps = {
  isLoading: false,
  iconColor: undefined,
  children: null,
  Icon: false,
  iconPosition: 'right',
};

export default IconWrapper;
