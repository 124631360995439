import { useRouter } from 'next/router';
import { useEffect } from 'react';

const useRedirect = (isUrlValid = false, redirectTo) => {
  const { replace } = useRouter();

  useEffect(() => {
    if (!isUrlValid) {
      replace(redirectTo, redirectTo);
    }
  }, [isUrlValid, redirectTo, replace]);
};

export default useRedirect;
