export default {
  header: {
    logo: {
      altText: 'ImmoHub',
      src: '/assets/logo.svg',
    },
    nav: [
      {
        title: 'Start',
        url: '/',
      },
      {
        title: 'Immobiliensuche',
        url: '/immosuche',
      },
      {
        title: 'Finanzierung',
        url: '/finanzierung',
      },
    ],
    login: {
      loggedIn: {
        title: 'Mein Bereich',
        icon: '/icons/user-icon.svg',
        iconPosition: 'right',
        url: '/mein-bereich',
        sub: [
          {
            title: 'Mein Bereich',
            url: '/mein-bereich',
            showInMobile: 'false',
            sub: [
              {
                title: 'Meine Anfragen',
                url: '/mein-bereich/anfragen',
              },
            ],
          },
          {
            title: 'Abmelden',
            action: 'logout',
          },
        ],
      },
      loggedOut: {
        title: 'Einloggen/Registrieren',
        icon: '/icons/login.svg',
        iconPosition: 'right',
        action: 'login',
      },
    },
  },
  main: {
    title: 'Haushaltsrechnung',
    subtitle: 'Mit nur wenigen Klicks Ihr Eigenheim-Budget errechnen und zu Ihnen passende Immobilien finden.',
    backgroundImage: '/url/',
    backButton: 'Zurück',
    sections: {
      calculation: {
        adults: {
          label: 'Im Haushalt lebende Erwachsene*',
          default: 0,
        },
        children: {
          label: 'Im Haushalt lebende Kinder*',
          default: 0,
        },
        netIncome: {
          label: 'Monatliches Nettoeinkommen aller im Haushalt lebenden Personen in Euro*',
          default: '3.000',
        },
        equity: {
          label: 'Verfügbares Eigenkapital in Euro*',
          default: '30.000',
        },
        cars: {
          label: 'Anzahl Autos*',
          default: 1,
        },
        motorcycles: {
          label: 'Anzahl Motorräder*',
          default: 0,
        },
        repayment: {
          label: 'Tilgung laufender Kredite (Euro/Monat)*',
          default: 0,
        },
        otherCapital: {
          label: 'Sonstiges Vermögen',
          default: 0,
        },
        otherIncome: {
          label: 'Sonstige Einnahmen',
          default: 0,
        },
        otherExpenses: {
          label: 'Sonstige Ausgaben',
          default: 0,
        },
        buttonCalculate: 'Berechnen',
        buttonSave: 'Angaben speichern',
      },
      result: {
        title: 'Monatsberechnung',
        netHouseholdIncome: 'Haushaltsnettoeinkommen gesamt',
        rateAdults: 'Pauschale Erwachsene',
        rateChildren: 'Pauschale Kinder',
        rateCars: 'Pauschale KFZ-Kosten',
        rateMotorcycles: 'Pauschale Motorrad-Kosten',
        rateAdditionalCosts: 'Pauschale ungeplante Ausgaben',
        rateRepayment: 'Tilgung laufender Kredite',
        otherExpenses: 'Sonstige Ausgaben',
        monthlyAvailableAmount: 'Ihr monatlich verfügbarer Betrag',
        maxBuyingPrice: 'Maximaler Kaufpreis',
        disclaimerEmpty: 'Nach Angabe Ihrer Daten erscheint hier Ihre\npersönliche Berechnung.',
        disclaimerCosts:
          'Dies ist eine pauschale und unverbindliche Beispielrechnung.\nUnsere Berater helfen Ihnen gerne weiter.',
        negativeIncome:
          'Ihre Ausgaben scheinen Ihre Einnahmen zu übersteigen.\nBitte prüfen Sie Ihre Eingaben oder kontaktieren Sie Ihren Berater.',
        isCalculating: 'Ihre Monatsberechnung wird berechnet',
        details: {
          title: 'Details',
          maxLoanAmount: {
            label: 'max. möglicher Darlehensbetrag¹:',
            unit: '€',
          },
          additionalCosts: {
            label: 'Nebenkosten²:',
            unit: '€',
          },
          fixedInterestRate: {
            label: 'Sollzinsbindung:',
            unit: 'Jahre',
          },
          boundInterestRate: {
            label: 'Gebundener Sollzins:',
            unit: '% p. a. *',
          },
          effectiveInterestRate: {
            label: 'Effektiver Jahreszins:',
            unit: '% p. a. *',
          },
          initialRepayment: {
            label: 'Anfängliche Tilgung:',
            unit: '% p. a.',
          },
          disclaimers:
            '¹ Dieser Betrag berechnet sich aus dem monatlich verfügbaren Betrag\nx 12 geteilt durch Sollzins + Tilgung x 100\n\n² 10 % vom Kaufpreis, insbesondere Notar- und Grundbuchkosten,\nGrunderwerbsteuer sowie Maklerprovision. Die Nebenkosten sollten\ndurch Eigenkapital gedeckt sein. Die Ergebnisse sind gerundet.\n\n* {effectiveInterestRate} % p.a. effektiver Jahreszins; Repräsentatives Beispiel: {exampleLoanAmount} €;\nNettodarlehensbetrag, {fixedInterestRate} Jahre Sollzinsbindung, {boundInterestRate} % p.a. gebundener\nSollzins, Sicherung durch Grundschuld',
        },
        buttonConsultation: 'Beratung vereinbaren',
        buttonFindImmo: 'Immobilie finden',
      },
    },
    errorMessages: {
      calculation:
        'Mit den vorliegenden Angaben konnte leider keine Berechnung stattfinden.\nBitte prüfen Sie Ihre Eingaben und versuchen Sie es erneut.',
      isInt: 'Bitte nur Ganzzahlen angeben.',
      max: 'Der maximale Wert beträgt 999.999.999,00 €',
      required: 'Bitte einen Wert eingeben',
    },
  },
  footer: [
    {
      title: '© 2020 Immohub',
    },
    {
      title: 'Hilfe und Kontakt',
      url: '/hilfe',
    },
    {
      title: 'Impressum',
      url: '/impressum',
    },
    {
      title: 'AGB',
      url: '/agb',
    },
    {
      title: 'Datenschutz',
      url: '/datenschutz',
    },
  ],
  nextseo: {
    title: 'Startseite',
    titleTemplate: 'ImmoHub | %s',
    description: '',
    canonical: '',
    openGraph: {
      url: 'https://www.url.ie/a',
      title: 'Open Graph Title',
      description: 'Open Graph Description',
      images: [
        {
          url: 'https://www.example.ie/og-image-01.jpg',
          width: 800,
          height: 600,
          alt: 'Og Image Alt',
        },
        {
          url: 'https://www.example.ie/og-image-02.jpg',
          width: 900,
          height: 800,
          alt: 'Og Image Alt Second',
        },
        {
          url: 'https://www.example.ie/og-image-03.jpg',
        },
        {
          url: 'https://www.example.ie/og-image-04.jpg',
        },
      ],
      site_name: 'SiteName',
    },
  },
};
