const LeafIcon = ({ ...props }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg xmlns="http://www.w3.org/2000/svg" width="11.746" height="15" viewBox="0 0 11.746 15" {...props}>
    <path
      fill="#e2001a"
      d="M15.7 4l-.551.25c-1.7.749-4.15 1.03-6.24 1.769a6.564 6.564 0 0 0-2.741 1.652A4.694 4.694 0 0 0 5 10.941a3.658 3.658 0 0 0 1.635 2.987 5.329 5.329 0 0 0 .934.534 10.9 10.9 0 0 0-.734 1.638 5.379 5.379 0 0 0-.317 2 5.654 5.654 0 0 0 .083.8l1.069.1a5.866 5.866 0 0 0-.083-.951 4.2 4.2 0 0 1 .267-1.6c.5-1.479 1.9-3.913 5.539-7.809l-.768-.74a38.414 38.414 0 0 0-4.505 5.627 4.125 4.125 0 0 1-.868-.467 2.511 2.511 0 0 1-1.185-2.119 3.543 3.543 0 0 1 .884-2.553 5.576 5.576 0 0 1 2.3-1.368c1.729-.611 3.869-.92 5.706-1.6a24.984 24.984 0 0 1 .717 6.34 5.572 5.572 0 0 1-1 3.721 2.248 2.248 0 0 1-1.769.8 3.247 3.247 0 0 1-1.735-.667c-.538-.373-.909-.763-1.285-1l-.567.9c.1.06.628.553 1.251.984a4.147 4.147 0 0 0 2.336.851 3.385 3.385 0 0 0 2.586-1.168 6.6 6.6 0 0 0 1.251-4.422 27.938 27.938 0 0 0-.884-7.175z"
      transform="translate(-5 -4)"
    />
  </svg>
);

export default LeafIcon;
