const DownloadSolid = ({ ...props }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg xmlns="http://www.w3.org/2000/svg" width="13.071" height="17.428" viewBox="0 0 13.071 17.428" {...props}>
    <path
      fill="#e2001a"
      d="M12.809 4v12.027l-3.835-3.835-1.044 1.044 5.083 5.083.522.5.522-.5 5.083-5.083-1.04-1.044-3.835 3.835V4zM7 19.975v1.452h13.071v-1.452z"
      transform="translate(-7 -4)"
    />
  </svg>
);

export default DownloadSolid;
