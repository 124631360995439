const ChipOverview = ({ ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15"
    height="15"
    viewBox="0 0 15 15"
    fill="#333333"
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
  >
    <path
      data-name="Pfad 4534"
      d="M6 14.333h6.667V6H6zM6 21h6.667v-5H6zm8.333 0H21v-8.333h-6.667zm0-15v5H21V6z"
      transform="translate(-6 -6)"
    />
  </svg>
);

export default ChipOverview;
