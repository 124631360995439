const RegCheckIcon = ({ ...props }) => (
  <svg
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
  >
    <rect x="36" y="36" width="36" height="36" rx="18" transform="rotate(180 36 36)" fill="#125A41" fillOpacity="0.1" />
    <path
      d="M24.2141 12L14.7665 21.4476L10.7859 17.467L10 18.2528L14.3736 22.6264L14.7665 23.0023L15.1595 22.6264L25 12.7859L24.2141 12Z"
      fill="#125A41"
      stroke="#125A41"
      strokeWidth="0.5"
    />
  </svg>
);

export default RegCheckIcon;
