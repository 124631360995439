import useTranslation from 'next-translate/useTranslation';
import PropTypes from 'prop-types';
import { useMutation, useQueryClient } from 'react-query';
import { Box, Flex } from 'rebass/styled-components';
import styled from 'styled-components';

import { BellGreenIcon, SettingsIcon } from '@/components/Icon';
import Loader from '@/components/Loader';
import LoadingOverlay from '@/components/LoadingOverlay';
import Text from '@/components/Text';
import { toast } from '@/components/Toaster/Toaster';
import { useNotificationCardContext } from '@/context/NotificationCard';
import { useQueryNotificationRequests } from '@/hooks';
import useAuth from '@/hooks/useAuth';
import { userApi } from '@/services';
import { isSSR } from '@/utils';

import NotificationItem from './NotificationItem';

const Navcontainer = styled(Box)`
  border-bottom: solid 1px #ebebeb;
`;
const NavcontainerItems = styled(Box)`
  border-bottom: solid 1px #ebebeb;
`;

const fetchNotificationRead = async () => {
  await userApi.put(`/notification/read/true`);
};

const ContentFlex = styled(Flex)`
  max-height: 480px;
  @media (max-width: 767px) {
    max-height: calc(100vh - 187px);
  }
  @media (max-width: 820px) {
    max-height: calc(100vh - 187px);
  }
`;

const NotificationItems = ({ setOpenNc }) => {
  const { authenticated, login, initialized } = useAuth();
  const { data, status } = useQueryNotificationRequests();
  const { t } = useTranslation('common-notification');

  const handleClick = () => {
    if (initialized) {
      if (!authenticated) {
        login();
      }
    }
  };

  const queryClient = useQueryClient();

  const { mutateAsync: readNotification } = useMutation(fetchNotificationRead, {
    onSuccess: () => {
      queryClient.invalidateQueries();
      toast({
        type: 'success',
        message: 'Alle Mitteilungen als gelesen markiert.',
        dismissable: true,
        withIcon: true,
      });
    },
  });

  const handleReadClick = () => {
    readNotification();
  };
  const { setShowDialog } = useNotificationCardContext();
  const doLoginDialog = () => {
    setTimeout(() => {
      setShowDialog(true);
      setOpenNc(false);
    }, 500);
  };

  return (
    <>
      <Flex flexDirection="column" width="100%">
        {!isSSR && authenticated ? (
          <>
            {data?.meta?.cnt !== 0 ? (
              <Box>
                <Navcontainer py={['1rem', '1rem', '1rem']} px={['1.25rem', '1.25rem', '1.25rem']}>
                  <Flex alignItems="center" justifyContent="space-between">
                    <Text
                      lineHeight={['1rem', '1rem', '1rem']}
                      mb={['1px', '0', '0']}
                      mt={['1px', '0', '0']}
                      variant="notificationTitle"
                    >
                      {t('title')}
                    </Text>
                    <Box onClick={() => doLoginDialog()} style={{ cursor: 'pointer' }}>
                      <SettingsIcon />
                    </Box>
                  </Flex>
                </Navcontainer>
                <NavcontainerItems>
                  <LoadingOverlay status={status} loadOnMount minHeight="480px">
                    <ContentFlex flexDirection="column" sx={{ overflowY: 'scroll' }}>
                      {data?.notification?.map((item) => (
                        <NotificationItem item={item} setOpenNc={setOpenNc} />
                      ))}
                    </ContentFlex>
                  </LoadingOverlay>
                </NavcontainerItems>
                <Box py={['1.25rem', '1.25rem', '1.25rem']}>
                  <>
                    {data?.meta?.cnt_unread !== 0 ? (
                      <Text
                        textAlign="center"
                        lineHeight={['1rem', '1rem', '1rem']}
                        mb={['0', '0', '0']}
                        mt={['0', '0', '0']}
                        variant="smallPrimaryLink"
                        onClick={handleReadClick}
                      >
                        {t('deleteOneText')}
                      </Text>
                    ) : (
                      <>
                        <Box minHeight={['1rem', '1rem', '1rem']} />
                      </>
                    )}
                  </>
                </Box>
              </Box>
            ) : (
              <Box>
                <Navcontainer py={['1rem', '1rem', '1rem']} px={['1.25rem', '1.25rem', '1.25rem']}>
                  <Flex alignItems="center" justifyContent="space-between">
                    <Text
                      lineHeight={['1rem', '1rem', '1rem']}
                      mb={['1px', '0', '0']}
                      mt={['1px', '0', '0']}
                      variant="notificationTitle"
                    >
                      {t('title')}
                    </Text>
                    <Box onClick={() => doLoginDialog()} style={{ cursor: 'pointer' }}>
                      <SettingsIcon />
                    </Box>
                  </Flex>
                </Navcontainer>
                <Navcontainer px={['15px', '30px', '30px']}>
                  <Flex flexDirection="column">
                    <Flex justifyContent="center">
                      <BellGreenIcon />
                    </Flex>
                    <Text textAlign="center" variant="bodyBoldSmall">
                      {t('headlineEmpty')}
                    </Text>
                    <Text textAlign="center" variant="smallEstate">
                      {t('textEmpty')}
                    </Text>
                  </Flex>
                </Navcontainer>
                <Box minHeight={['3.5rem', '3.5rem', '3.5rem']} />
              </Box>
            )}
          </>
        ) : (
          <>
            {!isSSR && initialized ? (
              <Box>
                <Navcontainer py={['1.25rem', '1.25rem', '1.25rem']} px={['1.25rem', '1.25rem', '1.25rem']}>
                  <Flex alignItems="center" justifyContent="space-between">
                    <Text
                      lineHeight={['1rem', '1rem', '1rem']}
                      mb={['1px', '0', '0']}
                      mt={['1px', '0', '0']}
                      variant="notificationTitle"
                    >
                      {t('title')}
                    </Text>
                  </Flex>
                </Navcontainer>
                <Navcontainer px={['15px', '30px', '30px']}>
                  <Flex flexDirection="column">
                    <Flex justifyContent="center">
                      <BellGreenIcon />
                    </Flex>
                    <Text textAlign="center" variant="bodyBoldSmall">
                      {t('headlineNologin')}
                    </Text>
                    <Text textAlign="center" variant="smallEstate">
                      {t('textNologin')}
                    </Text>
                  </Flex>
                </Navcontainer>
                <Box py={['1.25rem', '1.25rem', '1.25rem']}>
                  <Text
                    onClick={handleClick}
                    textAlign="center"
                    lineHeight={['1rem', '1rem', '1rem']}
                    mb={['0', '0', '0']}
                    mt={['0', '0', '0']}
                    variant="smallPrimaryLink"
                  >
                    {t('loginText')}
                  </Text>
                </Box>
              </Box>
            ) : (
              <Flex alignItems="center" justifyContent="center" minHeight="480px" width="100%">
                <Loader />
              </Flex>
            )}
          </>
        )}
      </Flex>
    </>
  );
};

NotificationItems.propTypes = {
  item: PropTypes.shape({
    loginRequired: PropTypes.bool.isRequired,
    withInfo: PropTypes.bool.isRequired,
    infoText: PropTypes.string.isRequired,
    subnav: PropTypes.arrayOf(
      PropTypes.shape({
        url: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        text: PropTypes.string,
      }).isRequired
    ).isRequired,
    title: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  }).isRequired,
  loginLogout: PropTypes.shape({
    logout: PropTypes.string.isRequired,
    notRegistered: PropTypes.shape({
      button: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    }).isRequired,
    registered: PropTypes.shape({
      title: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  setOpenNc: PropTypes.func.isRequired,
};

export default NotificationItems;
