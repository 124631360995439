const ChecklistWhite = ({ ...props }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg xmlns="http://www.w3.org/2000/svg" width="8.979" height="6.947" viewBox="0 0 8.979 6.947" {...props}>
    <path
      d="m9.449 6.281-4.764 4.765-2.007-2.008-.4.4 2.209 2.202.2.19.2-.19 4.959-4.962z"
      transform="translate(-1.574 -5.574)"
      fill="#ffffff"
      stroke="#ffffff"
    />
  </svg>
);

export default ChecklistWhite;
