import { Form, Formik } from 'formik';
import useTranslation from 'next-translate/useTranslation';
import PropTypes from 'prop-types';
import { useMutation } from 'react-query';
import { Box, Flex } from 'rebass/styled-components';
import styled from 'styled-components';

import Button from '@/components/Button/Button';
import Modal from '@/components/Modal';
import Text from '@/components/Text';
import { toast } from '@/components/Toaster/Toaster';
import Toggle from '@/components/Toggle/Toggle';
import { useBreakpoints } from '@/hooks';
import useNotification from '@/hooks/useNotification';
import { userApi } from '@/services';

const dialogStyle = {
  'min-width': '320px',
  'max-width': '491px',
};

const additionalStyle = {
  'margin-top': '3px',
};

const DialogButton = styled(Flex)``;

DialogButton.defaultProps = {
  width: ['100%', '100%', '50%'],
  my: ['16px', '16px', '0'],
};

const NotificationCard = ({ showDialog, onCloseClick }) => {
  const { t } = useTranslation('common-notification');
  const einstellungen = t('profileinstellungen', {}, { returnObjects: true });
  const breakpoint = useBreakpoints();

  const isMobile = ['small', 'medium'].includes(breakpoint);
  const dialogStyleMobile = { ...dialogStyle, ...additionalStyle };
  const { data: response, refetch } = useNotification();
  const { mutateAsync } = useMutation(
    async (params) => {
      await userApi.put(`/notification/setting/channel/${Object.keys(params)}`, params[`${Object.keys(params)}`]);
    },
    {
      onSuccess: () => {
        toast({
          type: 'success',
          message: einstellungen.toast,
          dismissable: true,
          withIcon: true,
        });
        refetch();
      },
    }
  );

  const formikConfig = {
    initialValues: {},
    onSubmit: async (values) => {
      onCloseClick();
      await mutateAsync(values);
    },
  };

  return (
    <Modal style={isMobile ? dialogStyleMobile : dialogStyle} showDialog={showDialog} onCloseClick={onCloseClick}>
      <Formik {...formikConfig}>
        <Form>
          <Box>
            <Box textAlign="center" style={{ marginBottom: '40px' }}>
              <Text as="h2" variant="headline" color="primary" style={{ margin: '0 0 8px' }}>
                {einstellungen?.title}
              </Text>
              <Text variant="notificationText">{einstellungen.subtitle}</Text>
            </Box>
            {einstellungen?.notificationKategorien?.map((item) => (
              <Box key={item?.text} textAlign="left">
                <Text variant="bodyBold">{item?.headline}</Text>
                <Text variant="notificationText" style={{ margin: '12px 0' }}>
                  {item?.text}
                </Text>
                <Flex justifyContent="flex-end" style={{ marginBottom: '16px' }}>
                  <Text variant="notificationText" marginRight={['12px']}>
                    {item?.emailSwitch}
                  </Text>
                  <Toggle
                    isDisabled={item?.emailSwitchDisabled}
                    isToggled={response?.notificationsettingFlat[`${item?.channel}`].notify_me_by_mail}
                    name={`${item?.channel}.notify_me_by_mail`}
                  />
                  <Text variant="notificationText" marginRight={['12px']} marginLeft={['20px']}>
                    {item?.mitteilungSwitch}
                  </Text>
                  <Toggle
                    isDisabled={item?.mitteillungSwitchDisabled}
                    isToggled={response?.notificationsettingFlat[`${item?.channel}`].notify_me_by_message}
                    name={`${item?.channel}.notify_me_by_message`}
                  />
                </Flex>
              </Box>
            ))}

            <Flex
              flexWrap="wrap"
              justifyContent="space-between"
              flexDirection={['column', 'column', 'row']}
              marginTop={['50px', '50px', '50px']}
            >
              <DialogButton>
                <Button variant="secondary" width={['100%', '100%', 'auto']} onClick={onCloseClick}>
                  {einstellungen?.cancelButton}
                </Button>
              </DialogButton>
              <DialogButton justifyContent={['flex-start', 'flex-start', 'flex-end']}>
                <Button submit variant="primary" width={['100%', '100%', 'auto']}>
                  {einstellungen?.saveButton}
                </Button>
              </DialogButton>
            </Flex>
          </Box>
        </Form>
      </Formik>
    </Modal>
  );
};

NotificationCard.propTypes = {
  onCloseClick: PropTypes.func.isRequired,
  showDialog: PropTypes.bool.isRequired,
};

export default NotificationCard;
