import { useQuery } from 'react-query';

import { realEstateApi } from '@/services';

const useQueryTotalEstate = (
  city = '',
  options = {
    lat: undefined,
    lon: undefined,
    distance: 5,
    objectType: -1,
    offerType: 1,
    priceFrom: undefined,
    priceTo: undefined,
    roomsFrom: -1,
    roomsTo: -1,
    spaceFrom: undefined,
    spaceTo: undefined,
  }
) => {
  return useQuery(
    ['fetchGetTotalEstate', city, options],
    async () => {
      try {
        const { data } = await realEstateApi.get('/search/count', {
          params: {
            Town: city,
            Latitude: options.lat,
            Longitude: options.lon,
            Radius: options.distance,
            EstateSubType: options.objectType,
            OfferType: options.offerType,
            MinPrice: options.priceFrom,
            MaxPrice: options.priceTo,
            RoomsFrom: options.roomsFrom,
            RoomsTo: options.roomsTo,
            MinSpace: options.spaceFrom,
            MaxSpace: options.spaceTo,
            Sorting: 0,
            Page: 1,
          },
        });

        return data?.data.count;
      } catch (e) {
        throw new Error(e.message);
      }
    },
    { keepPreviousData: true, refetchOnWindowFocus: false }
  );
};

export default useQueryTotalEstate;
