const CrowIcon = ({ ...props }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg xmlns="http://www.w3.org/2000/svg" width="18.902" height="15" viewBox="0 0 18.902 15" {...props}>
    <path
      fill="#e2001a"
      d="M5.857 6A2.857 2.857 0 0 0 3 8.857v3.571a5.712 5.712 0 0 0 5 5.665V21h1.429v-2.857h1.429V21h1.429v-2.857h4.561l2.2 1.429H21.9L8.714 11V8.857h2.857c0-1.184-1.574-2.143-2.857-2.143h-.986A2.833 2.833 0 0 0 5.857 6zm0 1.429a1.407 1.407 0 0 1 .412.068.714.714 0 1 0 .949.949 1.407 1.407 0 0 1 .068.412v2.919l.65.421 6.949 4.517H8.714a4.291 4.291 0 0 1-4.286-4.286V8.857a1.43 1.43 0 0 1 1.429-1.428z"
      transform="translate(-3 -6)"
    />
  </svg>
);

export default CrowIcon;
