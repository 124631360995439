const Pen = ({ ...props }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" {...props}>
    <path
      d="M12.022 3.969a1.685 1.685 0 0 0-1.2.5l-6.5 6.5-.022.131-.45 2.262-.129.6.6-.129 2.262-.45.129-.026 6.5-6.5a1.7 1.7 0 0 0-1.2-2.892zm0 .784a.877.877 0 0 1 .617.3.784.784 0 0 1 0 1.234l-.3.283-1.217-1.226.283-.3a.877.877 0 0 1 .617-.291zm-1.478 1.17 1.221 1.221-4.974 4.974A2.8 2.8 0 0 0 5.57 10.9zm-5.5 5.643a1.947 1.947 0 0 1 1.08 1.08l-1.35.27z"
      transform="translate(-3.719 -3.969)"
      fill="#fff"
    />
  </svg>
);

export default Pen;
