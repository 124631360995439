/* eslint-disable jsx-a11y/click-events-have-key-events */
import Link from 'next/link';
import PropTypes from 'prop-types';
import { Fragment } from 'react';
import { Box, Flex, Image } from 'rebass/styled-components';
import styled from 'styled-components';

import { ArrowIcon } from '@/components/Icon';
import Text from '@/components/Text';
import { useLoginDialogContext } from '@/context/LoginRegisterDialog';
import useAuth from '@/hooks/useAuth';

const MyBox = styled(Box)`
  width: calc(100% * (1 / 4) - 10px - 1px);
  padding-top: 1rem;
  text-align: center;
`;

const SubNavItemWrapper = styled(Box)`
  cursor: pointer;
  border: solid 1px transparent;
  border-radius: 10px;
  padding: 1.25rem;
  background: #fff;
  > div > div {
    transition: all 0.2s ease;
    padding-right: 8px;
  }
  &:hover {
    background: #f7f7f7;
    border-color: #ebebeb;
    > div > div {
      padding-right: 13px;
    }
  }
  // width: calc((25% - 10px) - 1px);
`;

SubNavItemWrapper.defaultProps = {
  mx: ['5px', '5px', '5px'],
};

const Linkbox = ({ subitem }) => {
  return (
    <>
      <Flex alignItems="center">
        <Text variant="subheadSmall" mb={['0', '0', '0']}>
          {subitem.title}
        </Text>
        <ArrowIcon style={{ transform: 'rotate(90deg)' }} />
      </Flex>
      <Text variant="small">{subitem.text}</Text>
    </>
  );
};

Linkbox.propTypes = {
  subitem: PropTypes.shape({
    text: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
};

const variants = {
  visible: () => ({ display: 'block', visibility: 'visible', transition: { delay: 0 } }),
  hidden: () => ({ visibility: 'hidden', transitionEnd: { display: 'none' }, transition: { delay: 0 } }),
};

const SubNavItem = ({ item, doLoginDialog, setOpen }) => {
  const { authenticated } = useAuth();
  const { setUrl } = useLoginDialogContext();
  const handleClick = (url) => {
    return () => {
      doLoginDialog();
      // eslint-disable-next-line no-console
      setUrl(url);
    };
  };
  return (
    <>
      <Flex flexDirection="row" py={['1.25rem', '1.25rem', '1.25rem']} width="100%">
        <MyBox>
          <Image src={item.image} />
        </MyBox>
        {item.subnav.map((subitem, index) => {
          return (
            <Fragment key={subitem.title}>
              {item.type === 'tarifcheck' && !subitem.isEnabled ? null : (
                <>
                  {!subitem.loginRequired || authenticated ? (
                    <Link href={subitem.url}>
                      <a
                        data-testid={subitem.title}
                        title={subitem.title}
                        style={{ width: 'calc((25% - 10px) - 1px)' }}
                      >
                        <SubNavItemWrapper
                          data-testid={subitem.title}
                          variants={variants}
                          custom={index}
                          onClick={() => {
                            setOpen(false);
                          }}
                        >
                          <Linkbox subitem={subitem} />
                        </SubNavItemWrapper>
                      </a>
                    </Link>
                  ) : (
                    <Link href="/">
                      <a
                        data-testid={subitem.title}
                        title={subitem.title}
                        style={{ width: 'calc((25% - 10px) - 1px)' }}
                      >
                        <SubNavItemWrapper custom={index} variants={variants} onClick={handleClick(subitem.url)}>
                          <Linkbox subitem={subitem} />
                          {subitem.isEnabled}
                        </SubNavItemWrapper>
                      </a>
                    </Link>
                  )}
                </>
              )}
            </Fragment>
          );
        })}
      </Flex>
    </>
  );
};

SubNavItem.propTypes = {
  doLoginDialog: PropTypes.func.isRequired,
  item: PropTypes.shape({
    type: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    subnav: PropTypes.arrayOf(
      PropTypes.shape({
        url: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        text: PropTypes.string,
      }).isRequired
    ).isRequired,
  }).isRequired,
  setOpen: PropTypes.func.isRequired,
};

export default SubNavItem;
