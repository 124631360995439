import '@reach/dialog/styles.css';

import { AnimatePresence, motion } from 'framer-motion';
import P from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Box, Flex } from 'rebass/styled-components';
import styled from 'styled-components';

import { CloseIconModal } from '@/components/Icon';
import Text from '@/components/Text';

CloseIconModal.defaultProps = {
  cursor: 'pointer',
};
export const ModalBox = styled(motion.div)`
  z-index: 100;
  background: #fff;
  border-radius: 6px;
  max-width: 984px;
  margin: 3px auto;
  min-height: calc(100vh - 6px);
  @media (min-width: 768px) {
    width: 100%;
    max-width: ${(props) => {
      if (props.wide && !props.targetWideMd && !props.targetWideXs) {
        return '90vw';
      }
      if (props.wide && props.targetWideMd) {
        return '900px';
      }
      if (props.wide && props.targetWideXs) {
        return '700px';
      }
      return '560px';
    }};

    min-height: auto;
    margin: 10vh auto;
    min-width: 320px;
    overflow: hidden;
  }
  overflow: hidden;

  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ModalContent = styled(motion.div)``;

export const ModalContainer = styled(motion.div)`
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100%;
  background: rgba(61, 61, 61, 0.73);
  z-index: 999898999;
  overflow: auto;
`;

function useDelayUnmount(isMounted, delayTime) {
  const [shouldRender, setShouldRender] = useState(false);

  useEffect(() => {
    let timeoutId;
    if (isMounted && !shouldRender) {
      setShouldRender(true);
    } else if (!isMounted && shouldRender) {
      timeoutId = setTimeout(() => setShouldRender(false), delayTime);
    }
    return () => clearTimeout(timeoutId);
  }, [isMounted, delayTime, shouldRender]);
  return shouldRender;
}
const Modal = ({ children, onCloseClick, showDialog, ariaLabel, header, setAutoFocus, onClose, ...props }) => {
  const shouldRenderChild = useDelayUnmount(showDialog, setAutoFocus, 100);
  useEffect(() => {
    if (shouldRenderChild) {
      document.getElementsByTagName('html')[0].style.overflow = 'hidden';
    } else {
      document.getElementsByTagName('html')[0].style.overflow = '';
    }
    if (shouldRenderChild && !!setAutoFocus) {
      setTimeout(() => setAutoFocus(true), 100);
    }
  }, [shouldRenderChild, setAutoFocus]);
  const backdrop = {
    hidden: {
      opacity: 0,
      transition: { duration: 0.8 },
    },
    visible: {
      opacity: 1,
    },
  };

  const modal = {
    hidden: {
      y: '100vH',
      opacity: 0,
      transition: { duration: 0.6 },
    },
    visible: {
      y: '0px',
      opacity: 1,
      transition: { type: 'spring', damping: 25, stiffness: 350, duration: 0.3 },
    },
  };
  const close = () => {
    onCloseClick();
  };

  return (
    <AnimatePresence exitBeforeEnter initial={false} onExitComplete={() => null}>
      {shouldRenderChild && (
        <ModalContainer variants={backdrop} initial="hidden" animate="visible" exit="hidden">
          <ModalBox variants={modal} aria-label={ariaLabel} {...props}>
            <Box p={['20px', '20px', '30px']} width="100%">
              {onCloseClick ? (
                <>
                  {header ? (
                    <Flex sx={{ justifyContent: 'space-between' }}>
                      <Text color="primary" mt={[0, 0, 0]} variant="modalHeadline">
                        {header}
                      </Text>
                      <CloseIconModal onClick={close} />
                    </Flex>
                  ) : (
                    <Flex sx={{ justifyContent: 'flex-end' }}>
                      <CloseIconModal onClick={close} />
                    </Flex>
                  )}
                </>
              ) : null}
              {children}
            </Box>
          </ModalBox>
        </ModalContainer>
      )}
    </AnimatePresence>
  );
};

Modal.propTypes = {
  onCloseClick: P.func,
  showDialog: P.bool.isRequired,
  ariaLabel: P.string.isRequired,
}.isRequired;

Modal.defaultProps = {
  onCloseClick: undefined,
};

export default Modal;
