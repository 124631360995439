const BedIcon = ({ ...props }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg xmlns="http://www.w3.org/2000/svg" width="22.333" height="16.75" viewBox="0 0 22.333 16.75" {...props}>
    <path
      fill="#e2001a"
      d="M5.19 6A2.406 2.406 0 0 0 2.8 8.393V13.8a2.384 2.384 0 0 0-.8 1.77v7.18h3.988v-1.6h14.357v1.6h3.988v-7.179a2.384 2.384 0 0 0-.8-1.77V8.393A2.406 2.406 0 0 0 21.143 6zm0 1.6h15.953a.8.8 0 0 1 .8.8v4.786h-1.6v-.8a2.406 2.406 0 0 0-2.393-2.393h-3.19a2.38 2.38 0 0 0-1.6.623 2.38 2.38 0 0 0-1.6-.623H8.381a2.406 2.406 0 0 0-2.393 2.393v.8h-1.6V8.393A.8.8 0 0 1 5.19 7.6zm3.19 3.988h3.19a.8.8 0 0 1 .8.8v.8H7.583v-.8a.8.8 0 0 1 .798-.805zm6.381 0h3.19a.8.8 0 0 1 .8.8v.8h-4.787v-.8a.8.8 0 0 1 .798-.805zM4.393 14.774H21.94a.8.8 0 0 1 .8.8v5.583h-.8v-1.6H4.393v1.6H3.6v-5.586a.8.8 0 0 1 .793-.797z"
      transform="translate(-2 -6)"
    />
  </svg>
);

export default BedIcon;
