const NoImageIcon = ({ ...rest }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="39.829"
    height="31.294"
    viewBox="0 0 39.829 31.294"
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...rest}
  >
    <path
      fill="#d1d1d1"
      d="M2 5v31.294h39.829V5zm2.845 2.845h34.139v19.781l-7.512-7.557-1.022-1.022L24 25.492l-8.179-8.268L14.8 16.2l-9.955 9.959zm28.449 2.845a2.845 2.845 0 1 0 2.845 2.845 2.843 2.843 0 0 0-2.845-2.845zM14.8 20.247l13.069 13.2H4.845V30.2zm15.647 2.845l8.535 8.535v1.823h-7.066L26 27.493z"
      transform="translate(-2 -5)"
    />
  </svg>
);

export default NoImageIcon;
