const MailSuccessIcon = ({ ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="90.069"
    height="80.853"
    viewBox="0 0 90.069 80.853"
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
  >
    <g id="undraw_Mail_sent_re_0ofv">
      <path
        id="Pfad_489"
        d="M409.331 225.082a.157.157 0 0 1-.09-.028l-43.7-30.435a.793.793 0 0 0-.905 0l-43.386 30.433a.158.158 0 0 1-.182-.259l43.386-30.433a1.11 1.11 0 0 1 1.267 0l43.7 30.434a.158.158 0 0 1-.09.288z"
        fill="#3f3d56"
        transform="translate(-320.052 -194.163)"
      />
      <path
        id="Pfad_490"
        fill="#e6e6e6"
        d="M23.264 39l41.4-30.684 41.716 32.867L66.8 64.652l-21.49-4.9z"
        transform="translate(-19.588 -7.004)"
      />
      <path
        id="Pfad_491"
        d="M375.177 640.52h-20.454a.956.956 0 1 1 0-1.911h20.454a.956.956 0 0 1 0 1.911z"
        fill="#e2001a"
        transform="translate(-347.642 -568.379)"
      />
      <path
        id="Pfad_492"
        d="M362.062 614.52h-7.338a.956.956 0 1 1 0-1.911h7.338a.956.956 0 1 1 0 1.911z"
        fill="#e2001a"
        transform="translate(-347.642 -546.488)"
      />
      <path
        id="Pfad_493"
        fill="#fff"
        d="M465.488 265.291a1.2 1.2 0 0 1-.453-.089L439.5 254.607v-33.164a1.107 1.107 0 0 1 1.106-1.106h48.985a1.107 1.107 0 0 1 1.106 1.106v33.187l-.048.021-24.694 10.549a1.2 1.2 0 0 1-.467.091z"
        transform="translate(-419.827 -216.201)"
      />
      <path
        id="Pfad_494"
        d="M465.067 264.948a1.275 1.275 0 0 1-.483-.095L439 254.239v-33.217a1.186 1.186 0 0 1 1.185-1.185h48.985a1.186 1.186 0 0 1 1.185 1.185v33.239l-24.791 10.587a1.281 1.281 0 0 1-.497.1zm-25.75-10.921l25.383 10.535a.967.967 0 0 0 .738 0l24.6-10.5v-33.03a.87.87 0 0 0-.869-.869h-48.984a.87.87 0 0 0-.869.869z"
        fill="#3f3d56"
        transform="translate(-419.406 -215.78)"
      />
      <path
        id="Pfad_495"
        d="M403.963 387.837h-.032l-18.14 7.746-24.82 10.6a.8.8 0 0 1-.613 0l-25.607-10.623-18.586-7.711-.028-.013h-.032a1.107 1.107 0 0 0-1.105 1.108v48.037a1.107 1.107 0 0 0 1.106 1.106h87.857a1.107 1.107 0 0 0 1.106-1.106v-48.037a1.107 1.107 0 0 0-1.106-1.107zm.79 49.143a.79.79 0 0 1-.79.79h-87.857a.79.79 0 0 1-.79-.79v-48.036a.792.792 0 0 1 .76-.79l18.676 7.749 25.485 10.574a1.125 1.125 0 0 0 .86 0l24.695-10.546 18.2-7.773a.793.793 0 0 1 .758.79z"
        fill="#3f3d56"
        transform="translate(-315 -357.234)"
      />
      <path
        id="Pfad_496"
        d="M520.677 262.326a2.546 2.546 0 0 1-1.532-.509l-.027-.021-5.769-4.417a2.563 2.563 0 0 1 3.118-4.069l3.737 2.865 8.831-11.517a2.563 2.563 0 0 1 3.594-.474l-.055.075.056-.073a2.566 2.566 0 0 1 .474 3.594l-10.387 13.546a2.564 2.564 0 0 1-2.04 1z"
        fill="#e2001a"
        transform="translate(-477.786 -228.086)"
      />
    </g>
  </svg>
);

export default MailSuccessIcon;
