const HomeIcon = ({ ...props }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg xmlns="http://www.w3.org/2000/svg" width="20.819" height="19.3" viewBox="0 0 20.819 19.3" {...props}>
    <path
      fill="#e2001a"
      stroke="#e2001a"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="0.3px"
      d="M12.541 2.594L12 3.108 2.281 12.83l1.075 1.07.958-.958v8.647h6.731v-7.474h2.991v7.478h6.731v-8.646l.958.958L22.8 12.83l-9.722-9.722zm0 2.127l6.731 6.731V20.1h-3.74v-7.48H9.549v7.48H5.81v-8.649z"
      transform="translate(-2.131 -2.444)"
    />
  </svg>
);

export default HomeIcon;
