const One = ({ ...props }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg xmlns="http://www.w3.org/2000/svg" width="3.756" height="7.92" viewBox="0 0 3.756 7.92" {...props}>
    <path
      data-name="1"
      d="M2.364 0v-5.016q0-.276.006-.57t.018-.606a4.691 4.691 0 0 1-.912.648 3.676 3.676 0 0 1-1.032.384L.228-6.552a2.182 2.182 0 0 0 .618-.162 6.121 6.121 0 0 0 .7-.336 5.653 5.653 0 0 0 .648-.42 1.991 1.991 0 0 0 .45-.45h1.34V0z"
      transform="translate(-.228 7.92)"
      fill="#ffffff"
    />
  </svg>
);

export default One;
