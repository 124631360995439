import { useKeycloak } from '@react-keycloak/ssr';
import Cookies from 'js-cookie';
import { useRouter } from 'next/router';

import { isSSR } from '@/utils';

const useAuth = () => {
  const { keycloak, initialized } = useKeycloak();
  const { locale } = useRouter();

  const register = (params = {}) => {
    keycloak?.register?.({
      locale,
      ...params,
    });
    Cookies?.set('isAuthenticated', true);
  };

  const registerWithRedirect = (params = {}) => {
    keycloak?.register?.({
      redirectUri: `${document.location.origin}${params.redirectPath}`,
      ...params,
    });
    Cookies?.set('isAuthenticated', true);
  };
  const login = (params = {}) => {
    keycloak?.login?.({
      locale,
      ...params,
    });
    Cookies?.set('isAuthenticated', true);
  };

  const loginWithRedirect = (params = {}) => {
    keycloak?.login?.({
      redirectUri: `${document.location.origin}${params.redirectPath}`,
      ...params,
    });
    Cookies?.set('isAuthenticated', true);
  };

  const logout = (params = {}) => {
    keycloak?.logout?.({
      redirectUri: `${document.location.origin}/`,
      ...params,
    });
    Cookies?.remove('isAuthenticated');
  };

  return {
    keycloak,
    initialized,
    login,
    loginWithRedirect,
    logout,
    register,
    registerWithRedirect,
    isAuthenticated: Cookies?.get('isAuthenticated') ?? false,
    authenticated: (!isSSR && keycloak?.authenticated) ?? false,
  };
};

useAuth.displayName = 'useAuth';

export default useAuth;
