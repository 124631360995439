import PropTypes from 'prop-types';
import { createContext, useContext, useState } from 'react';

import LoginRegisterDialog from '@/widgets/LoginRegisterDialog/LoginRegisterDialog';

const LoginDialogContext = createContext(undefined);
export const useLoginDialogContext = () => useContext(LoginDialogContext);

export function LoginRegisterDialogProvider({ children }) {
  const [showLoginDialog, setShowLoginDialog] = useState(false);
  const [url, setUrl] = useState('');

  const closeLoginDialog = () => {
    setShowLoginDialog(false);
  };

  return (
    <LoginDialogContext.Provider value={{ setShowLoginDialog, setUrl }}>
      <LoginRegisterDialog showDialog={showLoginDialog} onClose={closeLoginDialog} url={url} />
      {children}
    </LoginDialogContext.Provider>
  );
}

LoginRegisterDialogProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default LoginDialogContext;
