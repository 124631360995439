import { useCallback, useEffect, useRef } from 'react';

const useClickOutside = (callback) => {
  const targetRef = useRef();
  const handleClickOutside = useCallback(
    (event) => {
      if (targetRef.current && !targetRef.current.contains(event.target)) {
        if (typeof callback === 'function') callback();
        else throw new Error(`Callback must be initialized`);
      }
    },
    [callback]
  );

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);

    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [handleClickOutside]);

  return targetRef;
};

export default useClickOutside;
