import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';

const CheckMarkIcon = ({ width = '17.724px', height = '13px', color, ...props }) => {
  const theme = useContext(ThemeContext);

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <svg width={width} height={height} viewBox="0 0 17.724 13" {...props}>
      <path
        d="M16.795 0L5.632 11.163l-4.7-4.7-.929.929 5.168 5.168.464.444.464-.444L17.719.929z"
        fill={color ?? theme.colors.primary}
      />
    </svg>
  );
};

CheckMarkIcon.propTypes = {
  color: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
};

CheckMarkIcon.defaultProps = {
  color: undefined,
  height: undefined,
  width: undefined,
};

export default CheckMarkIcon;
