import PropTypes from 'prop-types';

const contentSideProps = PropTypes.oneOfType([
  PropTypes.shape({
    type: PropTypes.oneOf(['heroCta']).isRequired,
    title: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    button: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  }).isRequired,
  PropTypes.shape({
    type: PropTypes.oneOf(['image']).isRequired,
    src: PropTypes.string.isRequired,
    altText: PropTypes.string.isRequired,
  }).isRequired,
  PropTypes.shape({
    type: PropTypes.oneOf(['checkList']).isRequired,
    title: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  }).isRequired,
]).isRequired;

export default PropTypes.arrayOf(
  PropTypes.oneOfType([
    PropTypes.shape({
      type: PropTypes.oneOf(['SideBySide']).isRequired,
      bg: PropTypes.string,
      sides: PropTypes.string,
      content: PropTypes.shape({
        left: contentSideProps,
        right: contentSideProps,
      }).isRequired,
    }).isRequired,
    PropTypes.shape({
      type: PropTypes.oneOf(['ColumnThree']).isRequired,
      bg: PropTypes.string,
      content: PropTypes.shape({
        title: PropTypes.string.isRequired,
        columns: PropTypes.arrayOf(
          PropTypes.shape({
            icon: PropTypes.string.isRequired,
            title: PropTypes.string.isRequired,
            text: PropTypes.string.isRequired,
            button: PropTypes.string.isRequired,
            url: PropTypes.string.isRequired,
          }).isRequired
        ).isRequired,
      }).isRequired,
    }).isRequired,
  ]).isRequired
).isRequired;
