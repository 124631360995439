const DogIcon = ({ ...props }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg xmlns="http://www.w3.org/2000/svg" width="15.6" height="15" viewBox="0 0 15.6 15" {...props}>
    <path
      fill="#e2001a"
      d="M13.5 3l-.15.394L11.7 7.8H7.088a2.778 2.778 0 0 0-1.5.431L4.144 6.769l-.863.863 1.463 1.443a2.778 2.778 0 0 0-.431 1.5 2.81 2.81 0 0 0 .15.9l.431 1.256-.563 2.119-.019.075V18h1.2v-2.925l.581-2.194.038-.187-.056-.169-.488-1.444a1.606 1.606 0 0 1-.075-.506A1.561 1.561 0 0 1 7.088 9h4.819l2.006 1.969V9.281l-1.088-1.068 1.238-3.319.15.225.169.281h1.125l1.837 1.387-.506 1.013h-2.325v3.525l-.562 1.688-.038.094v1.969l.6 2.4V18h1.2v-.675l-.6-2.4v-1.65l.563-1.687.038-.094V9h1.875l1.294-2.587-.413-.3L15.919 4.2h-.863l-.581-.919L14.306 3zm-6.056 9l-.731 2.925V18h1.2v-2.925l.468-1.875h.563a3.646 3.646 0 0 0 1.969.6h.6v1.275l.6 2.4V18h1.2v-.675l-.6-2.4V12.6h-1.8a3.549 3.549 0 0 1-1.462-.506L9.3 12z"
      transform="translate(-3.281 -3)"
    />
  </svg>
);

export default DogIcon;
