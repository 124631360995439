import useTranslation from 'next-translate/useTranslation';
import Link from 'next/link';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { Box, Flex } from 'rebass/styled-components';
import styled from 'styled-components';

import Divider from '@/components/Divider';
import Text from '@/components/Text';
import { useLoginDialogContext } from '@/context/LoginRegisterDialog';
import useAuth from '@/hooks/useAuth';

const InfoBox = styled(Box)`
  position: absolute;
  top: -0.1rem;
  left: 100%;
  background: #8fd3bc;
  border-radius: 6px;
  color: #fff;
`;
const MyFlex = styled(Flex)`
  @media (max-width: 976px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-items: center;
  }
  @media (max-width: 528px) {
    display: flex;
    flex-direction: row;
  }
`;
MyFlex.defaultProps = {
  mx: ['-1rem', '-1rem', '-2rem'],
};

const MyBox = styled(Box)`
  width: 25%;
  min-width: 240px;
  padding-right: 2rem;
  padding-left: 2rem;
  @media (max-width: 976px) {
    width: 50%;
    text-align: center;
  }
  @media (max-width: 528px) {
    width: 100%;
    text-align: center;
  }
`;

MyBox.defaultProps = {
  mt: ['4rem', '4rem', '4rem'],
};

const WithoutMenu = styled(Box)`
  height: 3rem;
  @media (min-width: 1500px) {
    height: 90px;
  }
`;

const LinkItem = styled(Box)`
  position: relative;
  display: inline-block;
`;

const Info = ({ text }) => {
  return (
    <InfoBox>
      <Text variant="navInfoText">{text}</Text>
    </InfoBox>
  );
};

Info.propTypes = {
  text: PropTypes.string.isRequired,
};

const CustomFooter = () => {
  const { authenticated } = useAuth();
  const { setShowLoginDialog, setUrl } = useLoginDialogContext();

  const doLoginDialog = () => {
    setShowLoginDialog(true);
  };
  const { t } = useTranslation('common-footer');
  const customMenu = t('menu', {}, { returnObjects: true });
  const handleLinkClick = (url) => {
    return () => {
      doLoginDialog();
      setUrl(url);
    };
  };
  return (
    <>
      {Array.isArray(customMenu) ? (
        <>
          <MyFlex flexWrap="wrap">
            {customMenu?.map((item) => (
              // eslint-disable-next-line react/no-array-index-key
              <MyBox key={item.title}>
                <Box>
                  <LinkItem>
                    <Text variant="navFooterItem">{item.title}</Text>
                    {item.badge ? <Info text={item.badge} /> : null}
                  </LinkItem>
                </Box>
                {item.navitem?.map((navitem) => (
                  <Fragment key={navitem.title}>
                    {!navitem.portalLoginRequired || authenticated ? (
                      <Link href={navitem.url ?? '/'}>
                        <a data-test-id={navitem.title} title={navitem.title}>
                          <Box>
                            <LinkItem>
                              <Text variant="navFooterLink" mt={['1.25rem', '1.25rem', '1.25rem']} mb={['0', '0', '0']}>
                                {navitem.title}
                                {navitem.badge ? <Info text={navitem.badge} /> : null}
                              </Text>
                            </LinkItem>
                          </Box>
                        </a>
                      </Link>
                    ) : (
                      <Box>
                        <LinkItem key={navitem.title} onClick={handleLinkClick(navitem.url)}>
                          <Text variant="navFooterLink" mt={['1.25rem', '1.25rem', '1.25rem']} mb={['0', '0', '0']}>
                            {navitem.title}
                          </Text>
                          {item.badge ? <Info text={navitem.badge} /> : null}
                        </LinkItem>
                      </Box>
                    )}
                  </Fragment>
                ))}
              </MyBox>
            ))}
          </MyFlex>
          <Divider width={['100%', '100%', '100%']} mt={['4rem', '4rem', '4rem']} mb={['2rem', '2rem', '2rem']} />
        </>
      ) : (
        <WithoutMenu />
      )}
    </>
  );
};

export default CustomFooter;
