const ZertifikatsRechnerIcon = ({ ...rest }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="39.736"
    height="33.139"
    viewBox="0 0 39.736 33.139"
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...rest}
  >
    <g id="icon-3" transform="translate(.12 -9.43)">
      <path
        id="Pfad_644"
        fill="#df242b"
        stroke="#e2001a"
        strokeWidth="0.5px"
        d="M36.164 11.06a1.825 1.825 0 0 1 1.821 1.84v26.217a1.825 1.825 0 0 1-1.822 1.822H3.328a1.825 1.825 0 0 1-1.818-1.822V12.9a1.825 1.825 0 0 1 1.818-1.84h32.836m0-1.38H3.328A3.2 3.2 0 0 0 .13 12.9v26.217a3.2 3.2 0 0 0 3.2 3.2h32.834a3.2 3.2 0 0 0 3.2-3.2V12.9a3.2 3.2 0 0 0-3.2-3.2z"
      />
      <path
        id="Linie_105"
        d="M13.414 0L0 0"
        fill="none"
        stroke="#df242b"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2px"
        transform="translate(4.843 27.21)"
      />
      <path
        id="Linie_106"
        d="M13.414 0L0 0"
        fill="none"
        stroke="#df242b"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2px"
        transform="translate(4.843 31.63)"
      />
      <path
        id="Linie_107"
        d="M9.812 0L0 0"
        fill="none"
        stroke="#df242b"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2px"
        transform="translate(4.843 36.046)"
      />
      <path
        id="Linie_108"
        d="M13.414 0L0 0"
        fill="none"
        stroke="#df242b"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2px"
        transform="translate(4.843 15.935)"
      />
      <path
        id="Linie_109"
        d="M13.414 0L0 0"
        fill="none"
        stroke="#df242b"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2px"
        transform="translate(4.843 22.77)"
      />
      <path
        id="Linie_110"
        d="M13.414 0L0 0"
        fill="none"
        stroke="#df242b"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2px"
        transform="translate(4.843 27.19)"
      />
      <path
        id="Pfad_645"
        d="M73.382 28.37l1.273.735h1.47l.735 1.273 1.273.735v1.47l.735 1.273-.735 1.273V36.6l-1.273.735-.735 1.273h-1.47l-1.273.735-1.273-.735h-1.47l-.739-1.274-1.27-.735v-1.47l-.73-1.273.735-1.273v-1.47l1.27-.735.735-1.273h1.47z"
        fill="none"
        stroke="#df242b"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2px"
        transform="translate(-44.388 -12.242)"
      />
      <path
        id="Pfad_646"
        d="M81.239 57.9v9.74l-2.833-1.694-2.846 1.694V57.9"
        fill="none"
        stroke="#df242b"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2px"
        transform="translate(-49.405 -31.583)"
      />
    </g>
  </svg>
);

export default ZertifikatsRechnerIcon;
