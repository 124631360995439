/* eslint-disable react/no-danger */
import useTranslation from 'next-translate/useTranslation';
import NextHead from 'next/head';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import * as React from 'react';

import { useAccountData, useContent, useCookieConsent } from '@/hooks';
import useAuth from '@/hooks/useAuth';
import CookieConsentDialog from '@/widgets/CookieConsent/CookieConsentDialog';

const Head = ({ cookies }) => {
  const { googleVerificationCode } = useContent(
    (c) => c?.tracking ?? { _id: '', snippet: [], googleVerificationCode: null }
  );
  const { push } = useRouter();
  const [isUpdate, setIsUpdate] = React.useState(false);
  const [addFragment, setAddFragment] = React.useState(true);
  const { t } = useTranslation('common-cookie-consent');
  const toggle = t('toggle', {}, { returnObjects: true });

  const [, update] = useCookieConsent('default_de_master');

  // eslint-disable-next-line no-underscore-dangle
  const cookiesList = cookies.default_de_master ? JSON?.parse(cookies.default_de_master) : null;
  const GOOGLE_VERIFICATION_CODE = googleVerificationCode;
  const { initialized, authenticated } = useAuth();
  const { data: user } = useAccountData({ noAutoLogin: true });

  React.useEffect(() => {
    if (initialized || authenticated) {
      if (cookiesList) setIsUpdate(new Date(t('updated_at')) > new Date(cookiesList[0].creationDate));
      const userId = user.id_kc ? user.id_kc : '';
      if (cookiesList?.includes('analysisCookie') && addFragment && authenticated === (userId !== '')) {
        setAddFragment(false);
        toggle[1].infoText.forEach((item) => {
          document
            .getElementsByTagName('head')[0]
            .appendChild(document.createRange().createContextualFragment(item.code.replace('USER_ID_HERE', userId)));
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, cookies, initialized, authenticated, cookiesList, toggle]);

  React.useEffect(() => {
    if (isUpdate) {
      push('/api/remove-cookies/default_de_master');
      if (!cookiesList) {
        setIsUpdate(false);
        setAddFragment(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdate]);

  return (
    <>
      <NextHead>
        <meta key="content-type" httpEquiv="content-type" content="text/html; charset=utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=5" />
        {GOOGLE_VERIFICATION_CODE !== null && GOOGLE_VERIFICATION_CODE !== '' ? (
          <meta name="google-site-verification" content={GOOGLE_VERIFICATION_CODE} />
        ) : null}
      </NextHead>
      <CookieConsentDialog hasCookies={cookiesList} onChange={update} />
    </>
  );
};

Head.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  cookies: PropTypes.object.isRequired,
};

export default Head;
