import { motion } from 'framer-motion';
import PropTypes from 'prop-types';
import React from 'react';
import { Box, Flex } from 'rebass/styled-components';
import styled from 'styled-components';

import DefaultLoader from '@/components/Loader';

const LoaderWrapper = styled(Flex)`
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  pointer-events: none;
  z-index: 2;
`;

const variants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1, transition: { delay: 0 } },
};

const LoadingOverlay = ({ status, isFetching, loadOnMount, Idle, Loader, Error, children, ...props }) => {
  const isLoading = (loadOnMount && status === 'idle') || status === 'loading' || isFetching;

  return (
    <Box sx={{ position: 'relative' }} {...props}>
      {(!loadOnMount && status === 'idle' && !!Idle) || (status === 'error' && !!Error) ? null : (
        <Box
          as={motion.div}
          initial={loadOnMount ? 'hidden' : 'visible'}
          animate={isLoading ? 'hidden' : 'visible'}
          variants={variants}
        >
          {children}
        </Box>
      )}

      <LoaderWrapper
        as={motion.div}
        initial={loadOnMount ? 'visible' : 'hidden'}
        animate={isLoading ? 'visible' : 'hidden'}
        variants={variants}
      >
        {Loader}
      </LoaderWrapper>

      {status === 'error' ? Error : null}
      {status === 'idle' ? Idle : null}
    </Box>
  );
};

LoadingOverlay.propTypes = {
  Error: PropTypes.node,
  Idle: PropTypes.node,
  Loader: PropTypes.node,
  children: PropTypes.node,
  status: PropTypes.oneOf(['idle', 'loading', 'error', 'success']).isRequired,
  isFetching: PropTypes.bool,
  loadOnMount: PropTypes.bool,
};

LoadingOverlay.defaultProps = {
  children: null,
  Error: null,
  Idle: null,
  Loader: <DefaultLoader />,
  isFetching: false,
  loadOnMount: false,
};

export default LoadingOverlay;
