import { useQuery } from 'react-query';

import { userApi } from '@/services';

import useAuth from './useAuth';

const useAccountData = ({ noAutoLogin = false } = {}) => {
  const { initialized, authenticated, login } = useAuth();

  if (!noAutoLogin && initialized && !authenticated) login();

  const query = useQuery(
    'account',
    async () => {
      return (await userApi({ url: '/account' })).data;
    },
    {
      enabled: initialized && authenticated,
    }
  );

  const { data: { person = {}, ...rest } = {} } = query;
  return {
    ...query,
    data: {
      ...person,
      ...rest,
    },
  };
};

export default useAccountData;
