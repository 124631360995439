import { useQuery, useQueryClient } from 'react-query';

import { userApi } from '@/services';

import useAuth from '../useAuth';

export const useQuerySingleUserRequest = (id) => {
  const { initialized, authenticated, login } = useAuth();

  if (initialized && !authenticated) login();

  return useQuery(
    ['request', id],
    async () => {
      const { data } = await userApi(`/proceedings/plan/${id}`);
      return data.data.find((item) => item.proceedingid === id);
    },
    {
      enabled: initialized && !!id,
    }
  );
};

export const useQuerySelfDisclosure = (id) => {
  const { initialized, authenticated, login } = useAuth();

  if (initialized && !authenticated) login();

  return useQuery(
    ['selfdisclosure', id],
    async () => {
      const { data } = await userApi(`/proceedings/self-reporting/${id}`);
      console.log('datainitial', data);
      const ver = data.data[0]?.kundenangaben?.verbindlichkeiten.map((v, index) => {
        return {
          /* [`glaeubiger${index}`]: v.glaeubiger,
          [`laufzeitende${index}`]: v.laufzeitende,
          [`rate${index}`]: v.rate,
          [`restschuld${index}`]: v.restschuld,
          id: index,
          type: v.type,
          [`type${index}`]: v.type,
          */
          ...v,
          id: index,
        };
      });
      return {
        ...data.data[0],
        kundenangaben: {
          ...data.data[0].kundenangaben,
          verbindlichkeiten: ver,
        },
      };
    },
    {
      enabled: initialized && !!id,
    }
  );
};

const useQueryUserRequests = () => {
  const { initialized, authenticated, login } = useAuth();
  const queryClient = useQueryClient();

  if (initialized && !authenticated) login();

  return useQuery(
    ['requests'],
    async () => {
      const { data } = await userApi('/proceedings/plan');

      return data.data;
    },
    {
      enabled: initialized,
      onSuccess: (data) => {
        data?.forEach?.((item) => {
          queryClient.setQueryData(['request', item.proceedingid], item);
        });
      },
    }
  );
};

export default useQueryUserRequests;
