/* eslint-disable react/no-array-index-key */
import useTranslation from 'next-translate/useTranslation';
import PropTypes from 'prop-types';
import { Box, Flex } from 'rebass/styled-components';
import styled from 'styled-components';

import Button from '@/components/Button/Button';
import CookieIcon from '@/components/Icon/CookieIcon';
import Markdown from '@/components/Markdown/Markdown';
import Text from '@/components/Text';

const Overview = ({ setPage, AllowAllButton }) => {
  const { t } = useTranslation('common-cookie-consent');

  const handleClick = () => {
    setPage('settings');
  };

  const MarkdownHoverText = styled(Markdown)`
    > p > a:hover {
      color: #a70000;
    }
  `;

  return (
    <>
      <Flex>
        <CookieIcon />
        <Box pl={['1rem', '1rem', '2rem']}>
          <Text variant="capsTitle" marginBottom={[0, 0, 0]} fontWeight={['600', '600', '600']}>
            {t('overview.subtitle')}
          </Text>
          <Text variant="cardHedline">{t('overview.title')}</Text>
        </Box>
      </Flex>
      <MarkdownHoverText variant="small" mb={['1.25rem', '1.25rem', '1.25rem']}>
        {t('overview.text')}
      </MarkdownHoverText>
      <Flex justifyContent="space-between" flexWrap="wrap">
        <Button
          variant="secondary"
          onClick={handleClick}
          mb={['1.25rem', '1.25rem', '1.25rem']}
          width={['100%', '100%', 'auto']}
        >
          {t('overview.buttonTextSelect')}
        </Button>
        {AllowAllButton}
      </Flex>
    </>
  );
};

Overview.propTypes = {
  setPage: PropTypes.func.isRequired,
  AllowAllButton: PropTypes.element.isRequired,
};

export default Overview;
