const AvatarIcon = ({ ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="36"
    height="35.999"
    viewBox="0 0 36 35.999"
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
  >
    <g id="avatara" transform="translate(-817.978 3697.717)">
      <path
        id="Pfad_5097"
        data-name="Pfad 5097"
        d="M306.988 272.233a17.908 17.908 0 0 1-3.464 10.615c-.257.352-.526.7-.809 1.027a18.056 18.056 0 0 1-2.666 2.558c-.1.076-.2.152-.3.226q-.548.41-1.127.778c-.118.074-.235.147-.355.218q-.4.246-.823.468c-.137.073-.275.145-.413.214a18.011 18.011 0 0 1-18.019-1.122 17.317 17.317 0 0 1-1.839-1.404 18.021 18.021 0 0 1-3.025-3.389q-.252-.367-.487-.747a18 18 0 1 1 33.324-9.443z"
        transform="translate(546.99 -3951.949)"
        fill="#3f3d56"
        opacity=".1"
      />
      <circle
        id="Ellipse_1169"
        data-name="Ellipse 1169"
        fill="#b7958a"
        cx="4.904"
        cy="4.904"
        r="4.904"
        transform="translate(830.398 -3690.412)"
      />
      <path
        id="Pfad_5098"
        data-name="Pfad 5098"
        fill="#b7958a"
        d="M366.913 359.195s1.635 3.678-.613 5.108 3.678 7.355 3.678 7.355l6.538-6.947s-3.473-2.248-2.86-5.925z"
        transform="translate(465.017 -4042.354)"
      />
      <path
        id="Pfad_5099"
        data-name="Pfad 5099"
        d="M474.038 492.17c-.1.076-.2.152-.3.226q-.548.41-1.127.778c-.118.074-.236.147-.355.218l-.05-.272.471-.245z"
        transform="translate(373 -4157.687)"
        fill="#a0616a"
      />
      <path
        id="Pfad_5100"
        data-name="Pfad 5100"
        fill="#d1d1d1"
        d="M339.11 409.77c.119-.07.237-.143.355-.218v-.054l.062-.245 2.594-10.174-7.864-3.619-4.6 2.8-4.12-2.54s-2.42.088-3.033.7a23.673 23.673 0 0 1-3.838 2.044c-.279.127-.452.2-.452.2s.154 4.636.463 9.81a18.131 18.131 0 0 0 1.171.85 18.012 18.012 0 0 0 18.02 1.122z"
        transform="translate(506.15 -4074.064)"
      />
      <path
        id="Pfad_5101"
        data-name="Pfad 5101"
        fill="#d1d1d1"
        d="M296.111 417.67v10.864a17.317 17.317 0 0 1-1.839-1.404 18.022 18.022 0 0 1-3.025-3.389q-.252-.367-.487-.747.2-.654.447-1.444c1.022-3.268 3.27-3.881 3.27-3.881z"
        transform="translate(529.894 -4093.268)"
      />
      <path
        id="Pfad_5102"
        data-name="Pfad 5102"
        fill="#d1d1d1"
        d="M464.686 431.586c.119-.07.237-.143.355-.218q.579-.367 1.127-.778c.1-.074.2-.15.3-.226a18.059 18.059 0 0 0 2.666-2.558c.283-.332.552-.675.809-1.027-.832-2.6-2.238-5.884-2.238-5.884l-2.861-.2s-1.161 9.295-1.39 11.578z"
        transform="translate(380.575 -4095.88)"
      />
      <path
        id="Pfad_5103"
        data-name="Pfad 5103"
        d="M360.2 294.494a9.28 9.28 0 0 1-.767.587 7.291 7.291 0 0 0-.925.919l-.316.354a1.625 1.625 0 0 0-.543 1.049c0 .15.05.3.068.444.072.59-.289 1.239.008 1.754.086.149.219.265.313.409a.956.956 0 0 1 .151.622.764.764 0 0 0 .681-.576 5.805 5.805 0 0 0 .135-.947 1.015 1.015 0 0 1 .472-.79c.612-.3 1.315.48 1.968.285.456-.136.7-.7 1.163-.8a1.657 1.657 0 0 1 .965.271c.31.141.728.206.948-.055.145-.172.161-.449.353-.566.231-.141.52.055.695.261s.368.458.638.451c.358-.01.633-.487.973-.374.384.128.207.742.44 1.073.2.28.609.263.948.217a.34.34 0 0 0 .354-.453l-.142-1.877a.907.907 0 0 0-.083-.375c-.1-.179-.3-.269-.441-.418-.292-.314-.278-.865-.641-1.094-.188-.119-.435-.115-.622-.236-.24-.155-.317-.465-.485-.7a1.482 1.482 0 0 0-1.066-.525c-.406-.042-.816.009-1.224 0a12.711 12.711 0 0 0-2.493-.124c-.725.12-1.032.739-1.525 1.214z"
        transform="translate(472.094 -3985.678)"
        fill="#41382e"
      />
    </g>
  </svg>
);

export default AvatarIcon;
