import * as React from 'react';

import Text from '@/components/Text';

const ThreePointMenu = () => {
  return (
    <Text
      sx={{
        fontSize: '1.75rem',
        textAlign: 'center',
        marginTop: '-18px',
        color: '#333333',
        fontFamily: 'Montserrat',
      }}
    >
      ...
    </Text>
  );
};

export default ThreePointMenu;
