const MinusIcon = ({ ...rest }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15"
    height="1.364"
    viewBox="0 0 15 1.364"
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...rest}
  >
    <path fill="#e2001a" d="M5 15v1.364h15V15z" transform="translate(-5 -15)" />
  </svg>
);

export default MinusIcon;
