import PropTypes from 'prop-types';
import { cloneElement, useContext } from 'react';
import { Box, Flex } from 'rebass/styled-components';
import { ThemeContext } from 'styled-components';

import FeedbackClose from '@/components/Icon/feedback/FeedbackClose';
import FeedbackError from '@/components/Icon/feedback/FeedbackError';
import FeedbackInfo from '@/components/Icon/feedback/FeedbackInfo';
import FeedbackSuccess from '@/components/Icon/feedback/FeedbackSuccess';
import FeedbackWarning from '@/components/Icon/feedback/FeedbackWarning';
import Text from '@/components/Text';

const feedbackIconMap = {
  success: <FeedbackSuccess />,
  error: <FeedbackError />,
  warning: <FeedbackWarning />,
  info: <FeedbackInfo />,
};

const Feedback = ({ id, type, message, description, withIcon, action, onDismiss }) => {
  const handleDismiss = () => {
    onDismiss?.(id);
  };

  const theme = useContext(ThemeContext);

  return (
    <Flex
      sx={{
        width: '100%',
        bg: `feedback.bg.${type}`,
        borderRadius: '4px',
        border: `solid 1px`,
        borderColor: `feedback.border.${type}`,
        flexDirection: 'row',
        // justifyContent: 'space-between',
        p: '15px',
      }}
    >
      {withIcon ? (
        <Box
          as="button"
          type="button"
          sx={{
            all: 'unset',
            width: '20px',
            height: '20px',
            mr: '15px',
            flexShrink: 0,
          }}
        >
          {cloneElement(feedbackIconMap[type], {
            color: theme.colors.feedback.message[type],
          })}
        </Box>
      ) : null}
      <Flex sx={{ flexDirection: 'column' }}>
        <Text
          sx={{
            margin: ['0px', '0px', '0px'],
            fontSize: ['0.875rem', '0.875rem', '0.875rem'],
            fontWeight: onDismiss ? ['500', '500', '500'] : ['600', '600', '600'],
            color: `feedback.message.${type}`,
          }}
        >
          {message}
        </Text>
        {description ? (
          <Text
            sx={{
              margin: ['0px', '0px', '0px'],
              fontSize: ['0.875rem', '0.875rem', '0.875rem'],
              fontWeight: onDismiss ? ['500', '500', '500'] : ['600', '600', '600'],
              color: `feedback.description.${type}`,
              mt: '8px !important',
            }}
          >
            {description}
          </Text>
        ) : null}
      </Flex>
      {!action && onDismiss ? (
        <Box
          as="button"
          type="button"
          sx={{
            all: 'unset',
            cursor: 'pointer',
            width: '10px',
            height: '10px',
            ml: 'auto',
            flexShrink: 0,
          }}
          onClick={handleDismiss}
        >
          <FeedbackClose color={theme.colors.feedback.message[type]} />
        </Box>
      ) : null}
      {action ? (
        <Box
          as="button"
          type="button"
          sx={{
            all: 'unset',
            cursor: 'pointer',
            ml: 'auto',
            flexShrink: 0,
            alignSelf: 'flex-start',
          }}
          onClick={() => {
            action?.callback?.();
            handleDismiss();
          }}
        >
          <Text
            sx={{
              margin: ['0px', '0px', '0px'],
              fontSize: ['0.875rem', '0.875rem', '0.875rem'],
              fontWeight: ['600', '600', '600'],
              textTransform: 'uppercase',
              color: `feedback.message.${type}`,
            }}
          >
            {action?.title}
          </Text>
        </Box>
      ) : null}
    </Flex>
  );
};

Feedback.propTypes = {
  id: PropTypes.string,
  type: PropTypes.oneOf(['success', 'error', 'warning', 'info']).isRequired,
  message: PropTypes.string,
  description: PropTypes.string,
  withIcon: PropTypes.bool,
  action: PropTypes.shape({ callback: PropTypes.func.isRequired, title: PropTypes.string.isRequired }),
  onDismiss: PropTypes.func,
};

Feedback.defaultProps = {
  id: undefined,
  description: undefined,
  message: undefined,
  withIcon: false,
  action: undefined,
  onDismiss: undefined,
};

/** @component */
export default Feedback;
