import base from './base';
import buttons from './buttons';
import colors from './colors';
import mediaQueries from './mediaQueries';
import sizes from './sizes';
import space from './spaces';
import text from './text';
import textStyles from './textStyles';
import transitions from './transitions';
import zIndexes from './zIndexes';

export const BASIC_THEME = {
  name: 'Basic',
  ...base,
  buttons,
  colors,
  sizes,
  space,
  text,
  textStyles,
  transitions,
  mediaQueries,
  zIndexes,
};

export default BASIC_THEME;
