import { Box } from 'rebass/styled-components';

const Divider = (props) => (
  <Box
    {...props}
    as="hr"
    sx={{
      bg: 'border',
      border: 0,
      height: '1px',
      my: 6,
      width: '100%',
      position: 'relative',
    }}
  />
);

/** @component */
export default Divider;
