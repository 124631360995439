export default {
  primary: '#e2001a',
  primaryHover: '#c5231f',
  primaryDisabled: '#a09e9e',
  hoverRed: '#a70000',

  finmas: '#e30018',
  darkGrey: '#333333',
  backgroundGrey: '#f7f7f7',
  darkBackgroundGrey: '#f2f2f2',
  brownGrey: '#8c8c8c',

  border: '#ebebeb',
  lightBorder: '#e1e1e1',
  darkBorder: '#c2c2c2',
  hoverBorder: '#707070',

  buttonSecondaryHover: '#fafafa',
  disabled: '#d1d1d1',
  white: '#ffffff',
  black: '#000',
  gray: '#808080',
  green: '#589322',
  buttongreen: '#699595',
  red: '#e2001a',
  darkGreen: '#00593f',
  darkYellow: '#856404',
  yellow: '#ffdd00',
  progressCircle: {
    green: '#9BBC57',
    orange: '#EC7C30',
    red: '#BF5431',
    disabled: '#C2C2C2',
    track: '#C2C2C2',
    trackDisabled: '#E3E3E3',
  },

  stepper: {
    text: '#8c8c8c',
    activeBackground: 'rgba(255, 0, 0, 0.08)',
  },

  greenBackground: 'rgba(105, 168, 47, 0.15)',
  primaryBackground: 'rgba(226, 0, 26, 0.05)',
  warningBackground: '#f8f3d6',
  warningBorder: '#ede6ca',

  shadow: 'rgba(0, 0, 0, 0.16)',
  transparent: 'rgba(0, 0, 0, 0)',

  blacks: [
    'rgba(0,0,0,.0125)',
    'rgba(0,0,0,.025)',
    'rgba(0,0,0,.05)',
    'rgba(0,0,0,.1)',
    'rgba(0,0,0,.2)',
    'rgba(0,0,0,.3)',
    'rgba(0,0,0,.4)',
    'rgba(0,0,0,.5)',
    'rgba(0,0,0,.6)',
    'rgba(0,0,0,.7)',
    'rgba(0,0,0,.8)',
    'rgba(0,0,0,.9)',
  ],
  whites: [
    'rgba(255,255,255,.0125)',
    'rgba(255,255,255,.025)',
    'rgba(255,255,255,.05)',
    'rgba(255,255,255,.1)',
    'rgba(255,255,255,.2)',
    'rgba(255,255,255,.3)',
    'rgba(255,255,255,.4)',
    'rgba(255,255,255,.5)',
    'rgba(255,255,255,.6)',
    'rgba(255,255,255,.7)',
    'rgba(255,255,255,.8)',
    'rgba(255,255,255,.9)',
  ],

  successGreen: '#5c8834',
  lightGreen: '#F4F6F0',
  lightRed: 'rgba(195, 63, 56, 0.15)',

  processStatus: {
    orange: '#ea921f',
    green: '#69a82f',
    red: '#e2001a',
  },

  dropzone: {
    green: '#def2d5',
    red: '#f6e4e6',
    white: 'white',
  },

  feedback: {
    bg: {
      success: '#def2d5',
      error: '#f6e4e6',
      warning: '#f8f3d6',
      info: '#333333',
    },
    border: {
      success: '#d6e6cf',
      error: '#f5dadd',
      warning: '#ede6ca',
      info: '#383838',
    },
    message: {
      success: '#155724',
      error: '#a70000',
      warning: '#856404',
      info: '#ffffff',
    },
    description: {
      success: '#333333',
      error: '#333333',
      warning: '#333333',
      info: '#ffffff',
    },
  },
};
