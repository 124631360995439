const BriefcaseMedicalIcon = ({ ...props }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg xmlns="http://www.w3.org/2000/svg" width="16.57" height="13.383" viewBox="0 0 16.57 13.383" {...props}>
    <path
      fill="#e04f50"
      d="M10.01 5a1.284 1.284 0 0 0-1.274 1.275v.637H4.912A1.922 1.922 0 0 0 3 8.824v9.56h16.57v-9.56a1.922 1.922 0 0 0-1.912-1.912h-3.824v-.637A1.284 1.284 0 0 0 12.56 5zm0 1.275h2.55v.637h-2.55zm-5.1 1.912h12.748a.626.626 0 0 1 .637.637v8.285H4.275V8.824a.626.626 0 0 1 .637-.637zm5.738 1.913v1.91H8.736v1.275h1.912V15.2h1.275v-1.915h1.912V12.01h-1.913V10.1z"
      transform="translate(-3 -5)"
    />
  </svg>
);

export default BriefcaseMedicalIcon;
