const MobileIconPrimary = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="10" height="15" viewBox="0 0 10 15">
    <path
      fill="#e2001a"
      d="M9.875 4A1.885 1.885 0 0 0 8 5.875v11.25A1.885 1.885 0 0 0 9.875 19h6.25A1.885 1.885 0 0 0 18 17.125V5.875A1.885 1.885 0 0 0 16.125 4zm0 1.25h6.25a.623.623 0 0 1 .625.625v11.25a.623.623 0 0 1-.625.625h-6.25a.623.623 0 0 1-.625-.625V5.875a.623.623 0 0 1 .625-.625zM13 15.875a.625.625 0 1 0 .625.625.626.626 0 0 0-.625-.625z"
      transform="translate(-8 -4)"
    />
  </svg>
);

export default MobileIconPrimary;
