const ArrowLightboxIcon = ({ ...props }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg xmlns="http://www.w3.org/2000/svg" width="16.6" height="15.235" viewBox="0 0 16.6 15.235" {...props}>
    <defs>
      <style>{'.cls-1{fill:none;stroke:#f8bf00;stroke-linecap:square;stroke-linejoin:round;stroke-width:1.5px}'}</style>
    </defs>
    <g id="bakery" transform="translate(-11872.22 12400.092)">
      <path
        id="Pfad_2450"
        d="M11880.294-12397.048c.244-1.043.833-1.725 3.029-1.414 0-.007 2.879-1.6 4.111-.481s1.845 3.766-10.186 12.4c-.02.012.077-.041 0 0-4.719 3.087-4.968-2.286-3.112-4.116.153-.036-.205-3.274 2.846-3.3.799-.111-.317-2.882 3.312-3.089z"
        className="cls-1"
        transform="translate(-.001 .001)"
      />
      <ellipse
        id="Ellipse_508"
        cx="1.809"
        cy=".791"
        className="cls-1"
        rx="1.809"
        ry=".791"
        transform="rotate(10.02 76613.413 61528.526)"
      />
      <ellipse
        id="Ellipse_509"
        cx="2.261"
        cy=".791"
        className="cls-1"
        rx="2.261"
        ry=".791"
        transform="rotate(10.02 76631.717 61544.646)"
      />
      <ellipse
        id="Ellipse_510"
        cx="2.035"
        cy=".565"
        className="cls-1"
        rx="2.035"
        ry=".565"
        transform="rotate(13 60346.458 45937.59)"
      />
    </g>
  </svg>
);

export default ArrowLightboxIcon;
