import React from 'react';

import Section from '@/components/Section';

import AppInfo from './AppInfo';
import CustomFooter from './CustomFooter';
import StandardFooter from './StandardFooter';

const LayoutFooter = () => (
  <Section padded bg="white" sx={{ zIndex: 1, borderTop: '1px solid #ebebeb' }}>
    <CustomFooter />
    <AppInfo />
    <StandardFooter />
  </Section>
);

export default LayoutFooter;
