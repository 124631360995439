const PdfSmall = ({ ...props }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg xmlns="http://www.w3.org/2000/svg" id="PDF-small" width="30" height="34.583" viewBox="0 0 30 34.583" {...props}>
    <g id="file">
      <g id="Pfad_648" fill="#f8f8f8">
        <path
          d="M24.359 34.083H1.804c-.719 0-1.304-.578-1.304-1.288V1.789C.5 1.079 1.085.5 1.804.5h15.402c2.752 2.764 7.575 7.608 8.457 8.497v23.798c0 .71-.585 1.288-1.304 1.288z"
          className="cls-5"
        />
        <path
          d="M1.804 1C1.361 1 1 1.354 1 1.789v31.006c0 .435.36.788.804.788H24.36c.443 0 .804-.353.804-.788V9.203C24.014 8.047 19.64 3.653 16.998 1H1.804m0-1h15.61s8.718 8.755 8.75 8.791v24.004c0 .988-.809 1.788-1.805 1.788H1.804c-.996 0-1.804-.8-1.804-1.788V1.789C0 .8.808 0 1.804 0z"
          className="cls-6"
        />
      </g>
      <g id="Differenzmenge_5" fill="#d5d5d5">
        <path
          d="M7.878 8.483H2c-.827 0-1.5-.673-1.5-1.5V1.104l7.378 7.379z"
          className="cls-5"
          transform="translate(17.367 .102)"
        />
        <path
          d="M6.671 7.983L1 2.312v4.671c0 .551.449 1 1 1H6.67m2.013 1H2c-1.103 0-2-.897-2-2V0h.103l8.581 8.582v.4z"
          className="cls-6"
          transform="translate(17.367 .102)"
        />
      </g>
    </g>
    <g id="type" transform="translate(6 17)">
      <rect id="Rechteck_481" width="24" height="11" fill="#db5363" rx="2" />
      <path
        id="Pfad_1229"
        fill="#fff"
        d="M3.261-9.8a2.385 2.385 0 0 1 1.119.248 1.747 1.747 0 0 1 .731.7 2.169 2.169 0 0 1 .256 1.08 2.174 2.174 0 0 1-.256 1.076 1.716 1.716 0 0 1-.731.7 2.417 2.417 0 0 1-1.119.244h-.977V-4.2H1.162v-5.6zm-.061 3a1.071 1.071 0 0 0 .769-.252.944.944 0 0 0 .263-.716.955.955 0 0 0-.263-.724 1.071 1.071 0 0 0-.769-.252h-.916V-6.8zm2.966-3h2.2a2.754 2.754 0 0 1 1.4.348 2.409 2.409 0 0 1 .942.98A3.09 3.09 0 0 1 11.043-7a3.09 3.09 0 0 1-.336 1.472 2.409 2.409 0 0 1-.942.98 2.754 2.754 0 0 1-1.4.348h-2.2zm2.147 4.536a1.5 1.5 0 0 0 1.161-.468A1.794 1.794 0 0 0 9.906-7a1.794 1.794 0 0 0-.433-1.268 1.5 1.5 0 0 0-1.161-.468H7.287v3.472zm4.7-3.5v1.48h2.245v1.04h-2.247V-4.2h-1.123v-5.6h3.665v1.04z"
        transform="translate(3.838 12.8)"
      />
    </g>
  </svg>
);

export default PdfSmall;
