import Link from 'next/link';
import PropTypes from 'prop-types';
import { Fragment } from 'react';
import { Box, Flex } from 'rebass/styled-components';
import styled from 'styled-components';

import Button from '@/components/Button/Button';
import AvatarIcon from '@/components/Icon/AvatarIcon';
import Loader from '@/components/Loader';
import Text from '@/components/Text';
import { useLoginDialogContext } from '@/context/LoginRegisterDialog';
import useAuth from '@/hooks/useAuth';
import { isSSR } from '@/utils';

const Navcontainer = styled(Box)`
  border-bottom: solid 1px #ebebeb;
  padding: 1.25rem 1.875rem;
`;

const InfoBox = styled(Box)`
  position: absolute;
  right: 0;
  top: 0.1rem;
  background: #8fd3bc;
  border-radius: 6px;
  color: #fff;
`;
const MyBox = styled(Box)`
  padding: 10px;
`;
const SubNavItemWrapper = styled(Box)`
  padding: 10px 20px;

  &:hover {
    background: #f7f7f7;
    border-color: #ebebeb;
    border-radius: 8px;
  }
`;
const SubItems = styled(Text)`
  padding: 0;
  margin: 0;
`;
const LoginButton = styled(Button)`
  font-size: 12px;
`;
const Info = ({ text }) => {
  return (
    <InfoBox>
      <Text variant="navInfoText">{text}</Text>
    </InfoBox>
  );
};

Info.propTypes = {
  text: PropTypes.string.isRequired,
};

const HomeNavItem = ({ doLoginDialog, item, loginLogout, setOpen, additionLinkList }) => {
  const { authenticated, login, logout, initialized } = useAuth();
  const { setUrl } = useLoginDialogContext();

  const handleClick = () => {
    if (initialized) {
      if (!authenticated) {
        login();
      }
    }
  };

  const handleLogoutClick = () => {
    if (initialized) {
      if (authenticated) {
        logout();
      }
    }
  };
  const handleLinkClick = (url) => {
    return () => {
      doLoginDialog();
      setUrl(url);
    };
  };
  return (
    <Flex flexDirection="column">
      <Navcontainer>
        {!isSSR && authenticated ? (
          <Flex justifyContent="space-between">
            <Text
              variant="navItem"
              my={['0.75rem', '0.75rem', '0.75rem']}
              sx={{ lineHeight: ['0.875rem', '0.875rem', '0.875rem'] }}
            >
              {loginLogout.registered.title}
            </Text>
            <AvatarIcon />
          </Flex>
        ) : (
          <>
            {!isSSR && initialized ? (
              <Box>
                <Text
                  variant="navItem"
                  mt={['0.75rem', '0.75rem', '0.75rem']}
                  mb={['1.25rem', '1.25rem', '1.25rem']}
                  sx={{ lineHeight: ['0.875rem', '0.875rem', '0.875rem'] }}
                >
                  {loginLogout.notRegistered.title}
                </Text>
                <LoginButton onClick={handleClick} width="100%">
                  {loginLogout.notRegistered.button}
                </LoginButton>
              </Box>
            ) : (
              <Loader />
            )}
          </>
        )}
      </Navcontainer>
      {item.subnav ? (
        <MyBox sx={{ borderBottom: 'solid 1px #ebebeb' }}>
          <SubNavItemWrapper>
            {!item.loginRequired || authenticated ? (
              <Link href={item.url}>
                <a title={item.title}>
                  <Box
                    sx={{ position: 'relative' }}
                    onClick={() => {
                      setOpen(false);
                    }}
                  >
                    {item.withInfo ? <Info text={item.infoText} /> : null}
                    <Text variant="navBold">{item.title}</Text>
                  </Box>
                </a>
              </Link>
            ) : (
              <Box onClick={handleLinkClick(item.url)} sx={{ position: 'relative' }}>
                {item.withInfo ? <Info text={item.infoText} /> : null}
                <Text variant="navBold">{item.title}</Text>
              </Box>
            )}
          </SubNavItemWrapper>
          {item.subnav.map((subitem) => {
            return (
              <SubNavItemWrapper key={subitem.title}>
                {!subitem.loginRequired || authenticated ? (
                  <Link href={subitem.url}>
                    <a title={subitem.title}>
                      <Box
                        onClick={() => {
                          setOpen(false);
                        }}
                      >
                        <SubItems variant="navSub">{subitem.title}</SubItems>
                      </Box>
                    </a>
                  </Link>
                ) : (
                  <Box key={subitem.title} onClick={handleLinkClick(subitem.url)}>
                    <SubItems variant="navSub">{subitem.title}</SubItems>
                  </Box>
                )}
              </SubNavItemWrapper>
            );
          })}
        </MyBox>
      ) : null}

      {additionLinkList.map((subitem) => {
        return (
          <Fragment key={subitem.url}>
            {!subitem.loginRequired || authenticated ? (
              <Link href={subitem.url} passHref>
                <a href={subitem.title}>
                  {!isSSR && authenticated ? (
                    <MyBox sx={{ borderBottom: 'solid 1px #ebebeb' }}>
                      <SubNavItemWrapper
                        sx={{ cursor: 'pointer', position: 'relative' }}
                        onClick={() => {
                          setOpen(false);
                        }}
                      >
                        {subitem.withInfo ? <Info text={subitem.infoText} /> : null}
                        <Text variant="navBold">{subitem.title}</Text>
                      </SubNavItemWrapper>
                    </MyBox>
                  ) : (
                    <MyBox>
                      <SubNavItemWrapper
                        sx={{ cursor: 'pointer', position: 'relative' }}
                        onClick={() => {
                          setOpen(false);
                        }}
                      >
                        {subitem.withInfo ? <Info text={subitem.infoText} /> : null}
                        <Text variant="navBold">{subitem.title}</Text>
                      </SubNavItemWrapper>
                    </MyBox>
                  )}
                </a>
              </Link>
            ) : (
              <MyBox sx={{ borderBottom: 'solid 1px #ebebeb', cursor: 'pointer', position: 'relative' }}>
                <SubNavItemWrapper key={subitem.title} onClick={handleLinkClick(subitem.url)}>
                  {subitem.withInfo ? <Info text={subitem.infoText} /> : null}
                  <Text variant="navBold">{subitem.title}</Text>
                </SubNavItemWrapper>
              </MyBox>
            )}
          </Fragment>
        );
      })}

      {!isSSR && authenticated ? (
        <MyBox onClick={handleLogoutClick}>
          <SubNavItemWrapper>
            <Text variant="navBold">{loginLogout.logout}</Text>
          </SubNavItemWrapper>
        </MyBox>
      ) : null}
      <Box mb={['3', '3', '3']} />
    </Flex>
  );
};

HomeNavItem.propTypes = {
  additionLinkList: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
  doLoginDialog: PropTypes.func.isRequired,
  item: PropTypes.shape({
    loginRequired: PropTypes.bool.isRequired,
    withInfo: PropTypes.bool.isRequired,
    infoText: PropTypes.string.isRequired,
    subnav: PropTypes.arrayOf(
      PropTypes.shape({
        url: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        text: PropTypes.string,
      }).isRequired
    ).isRequired,
    title: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  }).isRequired,
  loginLogout: PropTypes.shape({
    logout: PropTypes.string.isRequired,
    notRegistered: PropTypes.shape({
      button: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    }).isRequired,
    registered: PropTypes.shape({
      title: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  setOpen: PropTypes.func.isRequired,
};

export default HomeNavItem;
