import PropTypes from 'prop-types';
import React from 'react';
import { Box, Flex, Text as RText } from 'rebass/styled-components';
import styled from 'styled-components';

import Button from '@/components/Button/Button';
import { CheckMarkIcon } from '@/components/Icon';
import IconWrapper from '@/components/IconWrapper/IconWrapper';
import Modal from '@/components/Modal';
import Text from '@/components/Text';
import { useBreakpoints } from '@/hooks';
import useAuth from '@/hooks/useAuth';
import navigation from '@/mockData/nav';

const TextBox = styled(Box)``;
TextBox.defaultProps = {
  px: ['25px', '25px', '25px'],
};

const mobileStyle = {
  'margin-top': '3px',
};

const ChecklistItem = ({ children, ...props }) => (
  <Flex {...props} mb="0">
    <Box mt="5" mr="20px">
      <CheckMarkIcon />
    </Box>
    <Text variant="body" fontSize={['14px', '14px', '14px']} lineHeight={['24px', '24px', '26px']} textAlign="left">
      {children}
    </Text>
  </Flex>
);
ChecklistItem.propTypes = {
  children: PropTypes.node.isRequired,
};
ChecklistItem.defaultProps = {
  // eslint-disable-next-line react/default-props-match-prop-types
  mb: '6',
};

const LoginRegisterDialog = ({ showDialog, onClose, url, redirectPath }) => {
  const content = navigation.loginRegisterDialog;
  const { login, loginWithRedirect, register, registerWithRedirect } = useAuth();
  const breakpoint = useBreakpoints();
  const isMobile = ['small', 'medium'].includes(breakpoint);
  const closeDialog = () => {
    if (onClose) {
      onClose();
    }
  };
  /* 
  const closeDialogRegister = () => {
    console.log('closeDialogRegister old');
   if (isDirektRegister) {
      register({ redirectUri: `${document.location.origin}/${redirectUrl}` });
    } else {
      router.push({
        pathname: '/auth',
        query: {
          page: 'ConsultationQuery',
        },
      });
    }
    if (!isBaufi) {
      if (onClose) {
        onClose();
      }
    }
    
  };
*/

  const handleloginWithRedirect = () => {
    loginWithRedirect({ redirectPath });
  };

  const handleregisterWithRedirect = () => {
    registerWithRedirect({ redirectPath });
  };

  return (
    <Modal
      style={isMobile ? mobileStyle : null}
      ariaLabel={content.title}
      onCloseClick={closeDialog}
      showDialog={showDialog}
    >
      <Box mt={['-40px', '-40px', '-50px']}>
        <TextBox
          sx={{
            margin: ['-20px', '-20px', '-30px'],
            mb: ['30px', '30px', '30px'],
            pb: '1px',
            pt: '40px',
            backgroundImage: 'url("/assets/register-modal-image.svg")',
            backgroundPosition: 'bottom center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
          }}
        >
          <Text
            variant="headline"
            textAlign="center"
            mt={['0', '0', '0']}
            mb={['20px', '20px', '20px']}
            fontSize={['1.5rem', '1.5rem', '1.5rem']}
            fontWeight={['800']}
          >
            {content.title}
          </Text>
          <RText variant="body" textAlign="center" mb={['150px', '150px', '150px']}>
            {content.subtitle}
          </RText>
        </TextBox>
        <TextBox px="1em">
          <Box>
            {content.list.map((item) => (
              <ChecklistItem key={item}>{item}</ChecklistItem>
            ))}
          </Box>
          <Box width={['100%', '100%', '100%']} mt="7">
            {!redirectPath ? (
              <Button
                width="100%"
                onClick={() =>
                  register(
                    url
                      ? {
                          redirectUri: `${document.location.origin}${url}`,
                        }
                      : {}
                  )
                }
                variant="primary"
              >
                <IconWrapper iconColor="white">{content.registerButton}</IconWrapper>
              </Button>
            ) : (
              <Button width="100%" onClick={() => handleregisterWithRedirect()} variant="primary">
                <IconWrapper iconColor="white">{content.registerButton}</IconWrapper>
              </Button>
            )}
            <Box mt={['20px']}>
              {!redirectPath ? (
                <Button
                  width="100%"
                  onClick={() =>
                    login(
                      url
                        ? {
                            redirectUri: `${document.location.origin}${url}`,
                          }
                        : {}
                    )
                  }
                  variant="secondary"
                >
                  <IconWrapper iconColor="black">{content.loginButton}</IconWrapper>
                </Button>
              ) : (
                <Button width="100%" onClick={() => handleloginWithRedirect()} variant="secondary">
                  <IconWrapper iconColor="black">{content.loginButton}</IconWrapper>
                </Button>
              )}
            </Box>
          </Box>
        </TextBox>
      </Box>
    </Modal>
  );
};

LoginRegisterDialog.propTypes = {
  onClose: PropTypes.func,
  redirectPath: PropTypes.string,
  showDialog: PropTypes.bool,
  url: PropTypes.string,
};

LoginRegisterDialog.defaultProps = {
  onClose: undefined,
  redirectPath: undefined,
  showDialog: false,
  url: undefined,
};

export default LoginRegisterDialog;
