import { useWindowWidth } from '@react-hook/window-size';
import useTranslation from 'next-translate/useTranslation';
import React, { useLayoutEffect, useState } from 'react';
import { Box, Flex, Image } from 'rebass/styled-components';

import Divider from '@/components/Divider';
import Section from '@/components/Section';
import Text from '@/components/Text';
import useAuth from '@/hooks/useAuth';

const AppInfo = () => {
  const { authenticated, login: klogin, initialized } = useAuth();
  const handleClick = () => {
    if (initialized) {
      if (!authenticated) {
        klogin?.();
      }
    }
  };
  const { t } = useTranslation('common-footer');
  const plattformInfo = t('plattformInfo', {}, { returnObjects: true });

  const [fullScreen, setFullScreen] = useState(true);
  const [direction, setDirection] = useState('row-reverse');
  const [imageWidth, setImageWidth] = useState('390px');
  const [orientation, setOrientation] = useState('left');
  const [boxWidth, setBoxWidth] = useState('80%');
  const [paddingTitle, setPaddingTitle] = useState('0');

  const width = useWindowWidth();
  useLayoutEffect(() => {
    if (width < 1500) {
      setFullScreen(false);
    } else {
      setFullScreen(true);
    }

    if (width < 900) {
      setDirection('column');
      setImageWidth('180px');
      setOrientation('center');
      setBoxWidth('100%');
      setPaddingTitle('2rem');
    } else {
      setDirection('row-reverse');
      setImageWidth('390px');
      setOrientation('left');
      setBoxWidth('80%');
      setPaddingTitle('0');
    }
  }, [width]);

  return (
    <>
      <Section>
        <Flex flexDirection={direction} alignItems="center">
          {fullScreen ? (
            <Box
              height={['370px']}
              width={['286px']}
              sx={{
                position: 'absolute',
                right: '0',
                marginTop: '10px',
                zIndex: '1',
                textAlign: 'right',
                overflow: 'hidden',
              }}
            >
              <Image src={plattformInfo?.bgimage?.url} alt={plattformInfo?.bgimage?.alternativeText} height="390px" />
            </Box>
          ) : (
            <Box>
              <Image
                width={imageWidth}
                src={plattformInfo?.bgSmallImage?.url}
                alt={plattformInfo?.bgSmallImage?.alternativeText}
              />
            </Box>
          )}
          <Flex flexWrap="wrap">
            <Box width={boxWidth} textAlign={orientation}>
              <Text variant="navFooterItem" pt={paddingTitle}>
                {plattformInfo?.title}
              </Text>
              <Text
                as="p"
                variant="navFooterText"
                mt={['1.3rem', '1.3rem', '1.3rem']}
                mb={['0.7rem', '0.7rem', '0.7rem']}
              >
                {plattformInfo?.text}
              </Text>
              {!authenticated ? (
                <Text variant="navFooterLinkDefault" color="primary" onClick={handleClick}>
                  {plattformInfo?.linkText}
                </Text>
              ) : (
                <Box height="2.25rem" />
              )}
            </Box>
          </Flex>
        </Flex>
      </Section>
      <Divider width={['100%', '100%', '100%']} my={['2rem', '2rem', '2rem']} />
    </>
  );
};

export default AppInfo;
