/* eslint-disable prefer-destructuring */
//              0  1  2  3  4  5  6   7   8
const spaces = [0, 2, 3, 4, 6, 8, 16, 32, 64];

spaces.contentPadding = spaces[5];
spaces.normalGridGap = spaces[7];
spaces.inputFieldVerticalPadding = 23;
spaces.checkboxLabelMargin = 20;

export default spaces;
