const Edit = ({ ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="13.993"
    height="14"
    viewBox="0 0 13.993 14"
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
  >
    <path
      d="M16.266 4.031a1.721 1.721 0 0 0-1.223.511L9.257 10.31l-.128.128-.037.183-.4 2.044-.183.858.858-.183 2.044-.4.183-.037.128-.128 5.768-5.786a1.734 1.734 0 0 0-1.223-2.957zm0 1.132a.581.581 0 0 1 .4.2.511.511 0 0 1 0 .8l-5.658 5.658-1 .2.2-1 5.658-5.658a.581.581 0 0 1 .4-.2zM4 6.349v11.682h11.682v-7.7L14.513 11.5v5.366H5.168V7.517h5.366L11.7 6.349z"
      transform="translate(-4 -4.031)"
      fill="#e2001a"
    />
  </svg>
);

export default Edit;
