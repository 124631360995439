import { useRouter } from 'next/router';
import { useMutation } from 'react-query';

import { toast } from '@/components/Toaster/Toaster';
import { useContent } from '@/hooks/index';
import { transferApi } from '@/services';

const useRegister = () => {
  const { main } = useContent((c) => c);
  const router = useRouter();

  return useMutation(
    'useRegister',
    async ({ ...restValues }) => {
      const { data } = await transferApi.post('/register', null, {
        params: {
          ...restValues,
        },
      });

      if (!data.success) {
        throw new Error('Error');
      }
      return data;
    },
    {
      onSuccess: () => {
        toast({
          type: 'success',
          message: main?.alert?.message ?? 'Die Bestätigungsmail wurde erfolgreich zugeschickt',
          dismissable: true,
          withIcon: true,
        });
        router.push({
          pathname: '/auth',
          query: {
            page: 'Confirmation',
          },
        });
      },
    }
  );
};

export default useRegister;
