const localeDEDay = (day = '') => {
  switch (day) {
    case 'Mon':
      return 'M';
    case 'Tue':
      return 'D';
    case 'Wed':
      return 'M';
    case 'Thu':
      return 'D';
    case 'Fri':
      return 'F';
    case 'Sat':
      return 'S';
    case 'Sun':
      return 'S';
    default:
      return null;
  }
};

export default localeDEDay;
