const MapIconSecondary = ({ ...props }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg xmlns="http://www.w3.org/2000/svg" width="19.772" height="19" viewBox="0 0 19.772 19" {...props}>
    <path
      fill="#e2001a"
      d="M23.772 4.469l-1.159.515-5.457 2.342-6.282-2.368-.309-.1-.309.129-5.767 2.468L4 7.661v15.808l1.159-.515 5.458-2.343L16.9 22.98l.309.1.309-.129 5.767-2.472.489-.206zM11.415 6.915l4.943 1.854v12.254l-4.943-1.854zm-1.648.051V19.2l-4.119 1.771V8.742zm12.358 0V19.2l-4.119 1.776V8.742z"
      transform="translate(-4 -4.469)"
    />
  </svg>
);

export default MapIconSecondary;
