import { useQuery } from 'react-query';

import { userApi } from '@/services';

import useAuth from './useAuth';

const useListDocuments = (id) => {
  const { authenticated } = useAuth();
  return useQuery(
    ['documents', id],
    async () => {
      return (await userApi({ url: '/documents/actions/listmeta', params: { proceedingId: id } })).data;
    },
    {
      enabled: authenticated,
    }
  );
};

export default useListDocuments;
