import * as Popover from '@radix-ui/react-popover';
import useScrollPosition from '@react-hook/window-scroll';
import { AnimatePresence, motion } from 'framer-motion';
import PropTypes from 'prop-types';
import React, { forwardRef, useEffect, useRef, useState } from 'react';
import { Box, Flex } from 'rebass/styled-components';
import styled from 'styled-components';
import { color } from 'styled-system';

import Text from '@/components/Text';
import { useLoginDialogContext } from '@/context/LoginRegisterDialog';
import { useClickOutside, useQueryNotificationRequests } from '@/hooks';
import Mainmenu from '@/widgets/Navigation/Mainmenu';

import HomeNavItem from './HomeNavItem';
import NotificationItems from './NotificationItems';
import SubNavItem from './SubNavItem';

const list = {
  visible: {
    display: 'block',
    visibility: 'visible',
    transition: {
      delay: '0.5',
    },
  },
  hidden: {
    display: 'none',
    visibility: 'hidden',
    transitionEnd: { display: 'none' },
    transition: {
      delay: '0.7',
    },
  },
};

const MotionReveal = forwardRef(({ isOpen, children, ...props }, ref) => {
  return (
    <motion.div
      ref={ref}
      variants={list}
      initial="hidden"
      animate={isOpen ? 'visible' : 'hidden'}
      style={{ zIndex: 2001 }}
      {...props}
    >
      {children}
    </motion.div>
  );
});

MotionReveal.propTypes = {
  children: PropTypes.node.isRequired,
  isOpen: PropTypes.bool.isRequired,
};

const StyledOverlay = styled(motion.div)`
  background-color: rgba(0, 0, 0, 0.35);
  position: fixed;
  top: 64px;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
`;

const TriggerHome = styled(Popover.Trigger)`
  width: 0;
  height: 99%;
  border: none;
  background: transparent;
`;

const UnstyledPopoverContent = styled(Popover.Content)`
  position: absolute;
  top: 0px;
  outline: none;
  margin: 0;
`;

const ContentWrapHome = styled(MotionReveal)`
  background: #fff;
  border: 1px solid #ebebeb;
  box-shadow: 1px 1px 30px 0px rgba(0, 0, 0, 0.1);
  outline: none;
  margin: 0;
  width: 285px;
  border-radius: 10px;
  z-index: 2000;
`;

const ContentWrapNotification = styled(Flex)`
  background: #fff;
  border: 1px solid #ebebeb;
  box-shadow: 1px 1px 30px 0px rgba(0, 0, 0, 0.1);
  outline: none;
  margin: 0;
  width: 385px;
  border-radius: 10px;
  z-index: 2000;
  position: fixed;
  top: 65px;
  min-width: 385px;
  right: 15%;
  @media (max-width: 1400px) {
    right: 10%;
  }
  @media (max-width: 1200px) {
    right: 0;
  }
`;

const ContentWrap = styled(MotionReveal)`
  width: 100vw;
  background: #fff;
  outline: none;
  border-top: 1px solid #ebebeb;
  box-shadow: 0 4px 4px 0 rgb(0 0 0 / 3%);
  right: 0;
  margin: 0;
  min-height: 175px;
`;

const StyledContent = styled(Flex)`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
`;

const Trigger = styled(Popover.Trigger)`
  position: absolute;
  right: 0.1px;
  padding: 0;
  top: 0;
  width: 0;
  height: 99%;
  border: none;
  background: transparent;
`;

const NavActiv = styled(Box)`
  ${({ show }) => (show ? 'opacity: 1; width: 100% !important;' : 'width: 0; opacity: 0;')}
  transition: ${(props) =>
    // eslint-disable-next-line no-nested-ternary
    props.isOpen ? '0.5s ease-in-out;' : props.activePage ? '0.2s ease-in-out;' : '0.7s ease-in-out;'}
  transform-origin: left;
  height: 3px;
  width: 0%;
  z-index: 1;
  position: relative;
  bottom: 0px;
  margin-right: 20px;
  ${color}
`;
NavActiv.defaultProps = {
  bg: 'primary',
};

const NavWrapper = styled(Box)`
  display: flex;
  width: 100%;
  @media (max-width: 1024px) {
    display: none;
  }
`;

const InfoBox = styled(Box)`
  position: absolute;
  top: 0.5rem;
  right: -30px;
  background: #8fd3bc;
  border-radius: 6px;
  color: #fff;
`;

const BellBox = styled(Flex)`
  width: 40px;
  height: 40px;
  &:hover {
    background: #fafafa;
  }
`;

const Info = ({ text }) => {
  return (
    <InfoBox>
      <Text variant="navInfoText">{text}</Text>
    </InfoBox>
  );
};

Info.propTypes = {
  text: PropTypes.string.isRequired,
};

const NavItem = ({ item, activePage, openNc, setOpenNc, testid }) => {
  const closeNc = () => {
    setOpenNc(false);
  };

  const notificationRef = useClickOutside(closeNc);

  const [isOpen, setOpen] = useState(false);
  const [check, setCheck] = useState(false);
  const scrollY = useScrollPosition(30);
  const time = useRef();

  useEffect(() => {
    if (isOpen) {
      setOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollY]);

  const onOpenChange = () => {};

  const onClick = () => {
    setOpenNc(!openNc);
  };

  const onHover = () => {
    setOpen(true);
    onOpenChange();
  };

  const { setShowLoginDialog } = useLoginDialogContext();
  const doLoginDialog = () => {
    setOpen(false);
    setTimeout(() => {
      setShowLoginDialog(true);
    }, 1000);
  };

  useEffect(() => {
    if (!check) {
      setOpen(false);
    }
    if (check) {
      onHover();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [check]);

  useEffect(() => {
    if (isOpen) {
      time.current = setTimeout(() => {
        setOpenNc(false);
      }, 500);
    } else clearTimeout(time.current);
  }, [isOpen, setOpenNc]);

  return (
    <>
      {item?.type === 'tarifcheck' && !item?.enabled ? null : (
        <>
          {item?.type !== 'notification' ? (
            <Flex
              mx="15px"
              mr={item?.withInfo && item?.type !== 'myPlace' ? '30px' : '15px'}
              onMouseLeave={() => {
                setCheck(false);
              }}
              onMouseEnter={() => {
                setCheck(true);
              }}
              data-testid={testid}
              marginLeft={item?.title === 'Immobilie' ? 'auto' : '15px'}
            >
              <NavLink onHover={onHover} activePage={activePage} isOpen={isOpen} item={item} />
              <Popover.Root open={isOpen} onOpenChange={onOpenChange}>
                <Trigger />
                {item?.type === 'nav' ? (
                  <UnstyledPopoverContent forceMount>
                    <ContentWrap isOpen={isOpen}>
                      <StyledContent>
                        <SubNavItem item={item} doLoginDialog={doLoginDialog} setOpen={setOpen} />
                      </StyledContent>
                    </ContentWrap>
                  </UnstyledPopoverContent>
                ) : null}
                {item?.type === 'myPlace' ? (
                  <>
                    <TriggerHome />
                    <UnstyledPopoverContent forceMount>
                      <ContentWrapHome isOpen={isOpen}>
                        <Box>
                          <HomeNavItem
                            item={item}
                            additionLinkList={Mainmenu().additionLinkList}
                            loginLogout={Mainmenu().loginLogout}
                            doLoginDialog={doLoginDialog}
                            setOpen={setOpen}
                          />
                        </Box>
                      </ContentWrapHome>
                    </UnstyledPopoverContent>
                  </>
                ) : null}
              </Popover.Root>
            </Flex>
          ) : (
            <>
              {item?.type === 'notification' ? (
                <Flex ref={notificationRef} data-testid={testid} marginLeft="auto">
                  <NavLinkNotification onClick={onClick} item={item} />
                  {openNc ? (
                    <ContentWrapNotification open={openNc} onOpenChange={onOpenChange}>
                      <NotificationItems item={item} setOpenNc={setOpenNc} />
                    </ContentWrapNotification>
                  ) : null}
                </Flex>
              ) : null}
            </>
          )}
        </>
      )}
      <Overlay isOpen={isOpen} />
    </>
  );
};

NavItem.propTypes = {
  activePage: PropTypes.string.isRequired,
  item: PropTypes.shape({
    enabled: PropTypes.bool,
    title: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    url: PropTypes.string,
    withInfo: PropTypes.bool.isRequired,
  }).isRequired,
  openNc: PropTypes.bool.isRequired,
  setOpenNc: PropTypes.func.isRequired,
  testid: PropTypes.string.isRequired,
};
function Overlay({ isOpen }) {
  return (
    <AnimatePresence>
      {isOpen ? (
        <StyledOverlay
          initial={{
            opacity: 0,
            transition: {
              delay: '0',
            },
          }}
          animate={{
            opacity: 0,
            transition: {
              delay: '0.5',
            },
          }}
          exit={{
            opacity: 0,
            transition: {
              delay: '0',
            },
          }}
        />
      ) : null}
    </AnimatePresence>
  );
}

Overlay.propTypes = {
  isOpen: PropTypes.bool.isRequired,
};

function NavLink({ onHover, activePage, isOpen, item }) {
  const checkCurrentPage = activePage === '/mein-bereich' && item.url === '/dashboard' ? true : activePage === item.url;
  return (
    <Box
      key={item.title}
      minWidth="auto"
      sx={{
        cursor: 'default',
        whiteSpace: 'nowrap',
        position: 'relative',
        zIndex: '2001',
      }}
    >
      {item.withInfo && item.type !== 'myPlace' ? <Info text={item.infoText} /> : null}
      <Text
        variant="navItem"
        onMouseEnter={() => {
          onHover();
        }}
      >
        {item.title}
      </Text>
      <NavActiv show={checkCurrentPage || isOpen} isOpen={isOpen} activePage={checkCurrentPage} />
    </Box>
  );
}

NavLink.propTypes = {
  activePage: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  item: PropTypes.shape({
    infoText: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    withInfo: PropTypes.bool.isRequired,
  }).isRequired,
  onHover: PropTypes.func.isRequired,
};

function NavLinkNotification({ onClick, item }) {
  const { data: dataNotification } = useQueryNotificationRequests();
  return (
    <Flex
      alignItems="center"
      key={item.title}
      onClick={() => {
        onClick();
      }}
      sx={{
        cursor: 'pointer',
        whiteSpace: 'nowrap',
        position: 'relative',
        zIndex: '2001',
      }}
    >
      {dataNotification && dataNotification?.meta?.cnt_unread !== 0 ? (
        <BellBox alignItems="center" justifyContent="center" sx={{ borderRadius: '50%', position: 'relative' }}>
          <Flex
            height="16px"
            width="16px"
            sx={{ borderRadius: '50%', position: 'absolute', right: '0', top: '6px' }}
            backgroundColor="#e2001a"
            justifyContent="center"
            alignItems="center"
          >
            <Text variant="notificationCount">{dataNotification?.meta?.cnt_unread}</Text>
          </Flex>
          <Box>{item.image}</Box>
        </BellBox>
      ) : (
        <BellBox alignItems="center" justifyContent="center" sx={{ borderRadius: '50%', position: 'relative' }}>
          <Box>{item.image}</Box>
        </BellBox>
      )}
    </Flex>
  );
}

NavLinkNotification.propTypes = {
  item: PropTypes.shape({
    infoText: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    url: PropTypes.string,
    withInfo: PropTypes.bool.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    image: PropTypes.object,
  }).isRequired,
  onClick: PropTypes.func.isRequired,
};

const Nav = ({ activePage }) => {
  const [openNc, setOpenNc] = useState(false);

  return (
    <NavWrapper>
      {Mainmenu().nav.map((item) => {
        return (
          <NavItem
            key={item.title}
            item={item}
            activePage={activePage}
            openNc={openNc}
            setOpenNc={setOpenNc}
            testid={item.title}
          />
        );
      })}
    </NavWrapper>
  );
};

Nav.propTypes = {
  activePage: PropTypes.string.isRequired,
};

export default Nav;
