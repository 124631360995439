const cmsApi = require('./src/services/cmsApi');
const configApi = require('./src/services/configApi');

module.exports = {
  locales: ['de', 'en'],
  defaultLocale: 'de',
  localeDetection: false,
  pages: {
    '*': [
      'common-header',
      'common-footer',
      'common-cookie-consent',
      'common-dropzone',
      'common-toast',
      'common-notification',
      'navigation-footer-menu',
      'navigation-header-menu',
      'seo-meta-text',
      'page-baufinanzierung',
      'common-two-factor',
      'common-feedback',
      'common-form',
      'page-indice',
    ],
    '/404': ['common-header', 'common-footer', 'content-error', 'page-nicht-gefunden'],
    '/nicht-gefunden': ['common-header', 'common-footer', 'page-nicht-gefunden'],
    '/': ['page-indice', 'content-journey', 'content-page-immobiliensuche', 'common-estate-card', 'page-start'],
    // '/dashboard': ['content-home'],
    'rgx:^/more-examples': ['content-more-examples'],
    '/finanzierung/finanzierungszertifikat': ['content-page-finanzierung-finanzierungszertifikat'],
    '/finanzierung/finanzierungszertifikat/zertifikatsfreigabe': ['content-page-finanzierung-finanzierungszertifikat'],
    '/finanzierung/finanzierungszertifikat/erstellen': [
      'content-page-finanzierung-finanzierungszertifikat',
      'page-dashboard',
      'page-zertifikat-erstellen',
    ],
    '/finanzierung/finanzierungszertifikat/zertifikat-erfolg': ['content-page-finanzierung-finanzierungszertifikat'],
    '/finanzierung/finanzierungszertifikat/abgesendet': ['content-page-finanzierung-finanzierungszertifikat'],
    '/finanzierung/haushaltsrechner': ['content-page-finanzierung-haushaltsrechner'],
    '/temp-document': ['content-page-finanzierung-finanzierungszertifikat'],
    // '/tarifrechner/[type]': ['content-page-tarifcheck'],
    '/tarifrechner/gas': ['content-page-tarifcheck'],
    '/tarifrechner/dsl': ['content-page-tarifcheck'],
    '/tarifrechner/strom': ['content-page-tarifcheck'],
    '/agb': ['content-page-agb'],
    '/datenschutz': ['content-page-datenschutz'],
    '/impressum': ['content-page-impressum'],
    '/hilfe': ['page-hilfe'],
    '/hilfe/erfolg': ['page-hilfe'],
    '/immobilien/immobiliensuche': ['content-page-immobiliensuche', 'common-estate-card'],
    '/immobilien/immobiliensuche/ergebnisse': [
      'page-immobilienergebnisse',
      'content-page-immobiliensuche',
      'common-estate-card',
    ],
    '/immobilien/immobiliensuche/expose/[id]': ['page-expose'],
    '/immobilien/eigenheim-verwaltung': ['page-immo-verwaltung'],
    '/immobilien/eigenheim-verwaltung/[id]': ['page-meine-immo-edit'],
    '/start': ['page-reconciliation'],
    '/mein-bereich': ['page-dashboard'],
    '/mein-bereich/profil-einstellungen': ['page-profil'],
    '/mein-bereich/dokumenten-center': ['page-dokumentencenter', 'page-dashboard'],
    '/mein-bereich/anfragen': ['page-meine-anfragen', 'common-such-agent'],
    '/mein-bereich/anfragen/[id]': ['page-meine-anfragen', 'page-dashboard', 'common-such-agent', 'common-estate-card'],
    '/mein-bereich/merkzettel': ['page-merkzettel', 'common-estate-card'],
    '/mein-bereich/konto-loeschen': ['page-konto-loeschen'],
    '/finanzierung/baurechner': ['page-finanzierungsrechner'],
    '/finanzierung/baufinanzierung': [
      'page-baufinanzierung',
      'common-tooltip',
      'common-input-field',
      'common-error-message',
    ],
    '/finanzierung/baufinanzierung/[rechner]': ['page-baufinanzierung', 'common-tooltip'],
    '/finanzierung/baurechner/[rechner]': ['page-finanzierungsrechner', 'common-tooltip'],
    '/finanzierung/berater-kontaktieren': ['page-berater-kontakt'],
    '/finanzierung/berater-kontaktieren/erfolg': ['page-berater-kontakt'],
    '/projekte': ['page-projekte', 'projekt-kaufen', 'projekt-bauen', 'projekt-modernisieren', 'projekt-eigene'],
    '/projekte/[id]': ['page-projekte-erstellen', 'page-dashboard'],
    '/projekte/dienstleister-finden': ['page-dienstleistungen', 'data-dienstleistungen'],
    '/projekte/leistungsauswahl': ['page-dienstleistungen', 'page-leistungsauswahl', 'data-dienstleistungen'],
    '/projekte/service-kontakt': ['page-service-kontakt', 'data-dienstleistungen', 'page-berater-kontakt'],
    '/projekte/erfolg': ['page-service-kontakt-erfolg'],
    '/immobilien/eigenheim-verwaltung/detail/[id]': [
      'page-meine-immobilie-uebersicht',
      'page-expose',
      'page-meine-immo-edit',
    ],
    '/dashboard': [
      'page-dash-board',
      'page-dokumentencenter',
      'page-immo-verwaltung',
      'common-dokumente',
      'page-dashboard',
      'common-such-agent',
      'common-dashboard-emptie',
      'page-projekte',
      'page-dash-board-overview',
      'page-auth',
      'common-overview-mapping',
      'page-meine-anfragen',
      'common-estate-card',
    ],
    '/dashboard?area=dokumentenCenter': ['page-dashboard'],
    '/public/keycloak': ['content-key-cloak-content'],
    '/auth': ['content-key-cloak-content', 'page-auth', 'common-overview-mapping', 'page-reconciliation'],
    '/kampagne': ['page-kampagne'],
    '/versicherungen': ['page-versicherungen'],
    '/versicherungen/[phase]/[id]': ['page-versicherungen'],
    '/versicherungen/[phase]/[id]/kontakt': ['page-versicherungen'],
    '/versicherungen/kontakt': ['page-versicherungen'],
    '/versicherungen/success': ['page-versicherungen'],
    '/ueberleitung': [
      'page-baufinanzierung',
      'common-tooltip',
      'common-input-field',
      'common-error-message',
      'page-baufinanzierung-berater',
      'page-ueberleitung',
    ],
    '/mein-bereich/anfragen/[id]/selbstauskunft': ['page-selbstauskunft', 'common-tooltip'],
  },
  loader: false,
  loadLocaleFrom: async (locale, namespace) => {
    const parsedLocale = locale.split(',');
    if (parsedLocale.length !== 2) return {};

    try {
      const portal = (await configApi('/settings', parsedLocale[1]))?.portal;
      const { data } = await cmsApi.get(`/${namespace}s`, {
        params: {
          _locale: parsedLocale[0],
          PORTAL: portal,
        },
      });

      const hasNullOrUndefined = data.every((d) => d === null);

      if (hasNullOrUndefined) {
        throw new Error('no-content');
      } else {
        return data[0];
      }
    } catch (error) {
      const portal = (await configApi('/settings', parsedLocale[1]))?.portal;
      const { data } = await cmsApi.get(`/${namespace}s`, {
        params: {
          _locale: 'de',
          PORTAL: portal,
        },
      });

      return data[0];
    }
  },
};
