import { useQuery } from 'react-query';

import { userApi } from '@/services';

import useAuth from '../useAuth';

const useQueryNotificationRequests = () => {
  const { authenticated } = useAuth();
  // const queryClient = useQueryClient();

  return useQuery(
    ['notifications'],
    async () => {
      const { data } = await userApi('/notification');
      return data.data;
    },
    {
      enabled: authenticated,
    }
  );
};
export default useQueryNotificationRequests;
