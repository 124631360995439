import { ToggleButton } from '@radix-ui/react-toggle-button';
import { useField } from 'formik';
import PropTypes from 'prop-types';
import { Box } from 'rebass/styled-components';
import styled from 'styled-components';

import { CookieCheckIcon } from '../Icon';

const MyToggle = styled(ToggleButton)`
  appearance: none;
  background-color: #d1d1d1;
  border: none;
  height: 26px;
  width: 40px;
  padding: 3px;
  overflow: hidden;
  text-align: left;
  border-radius: 13px;
  cursor: pointer;
  &:disabled[data-state='on'] {
    cursor: not-allowed;
    background-color: #d1d1d1;
    > div > div {
      animation: none;
    }
    > div svg {
      opacity: 0.4;
    }
  }
  &:focus {
    outline: none;
  }
  > div {
    padding-left: 4px;
    padding-top: 1px;
    > div {
      width: 11px;
      height: 11px;
      opacity: 0;
      > svg {
        width: 100%;
        height: 100%;
      }
    }
  }
  &[data-state='on'] {
    background-color: #5b5858;
    > div {
      transition: left 200ms;
      transition-timing-function: ease-in-out;
      left: 40%;
      > div {
        animation: checkmark 200ms linear normal;
        opacity: 1;
      }
    }
  }
  &[data-state='off'] {
    > div {
      transition: left 200ms;
      transition-timing-function: ease-in-out;
      left: 0%;
    }
  }

  @keyframes checkmark {
    0% {
      height: 12px;
      width: 0;
    }
    100% {
      height: 12px;
      width: 11px;
    }
  }
`;
const ToggleCircle = styled(Box)`
  position: relative;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  background: #fff;
`;

const Toggle = ({ isToggled, isDisabled, name }) => {
  const [field, meta, helpers] = useField(name);
  const { value } = meta;
  const { setValue } = helpers;

  return (
    <MyToggle
      {...field}
      disabled={isDisabled}
      onToggledChange={setValue}
      defaultToggled={isToggled}
      name={name}
      value={value}
    >
      <ToggleCircle>
        <Box style={{ height: '100%', marginLeft: '1px' }}>
          <CookieCheckIcon />
        </Box>
      </ToggleCircle>
    </MyToggle>
  );
};

Toggle.propTypes = {
  isDisabled: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/require-default-props
  isToggled: PropTypes.bool,
  name: PropTypes.string.isRequired,
};

export default Toggle;
