const formatValue = (number, style, doRemoveFractionDigits = false) => {
  const { format } = new Intl.NumberFormat('de-DE', {
    style,
    minimumFractionDigits: doRemoveFractionDigits ? 0 : 2,
    maximumFractionDigits: doRemoveFractionDigits ? 0 : 2,
    ...(style === 'currency' ? { currency: 'EUR' } : {}),
  });

  return format(Number.isNaN(number) || !number ? 0 : number).replace('-', '- ');
};

export default formatValue;
export const formatDecimal = (number) => formatValue(number, 'decimal');
export const formatDecimalwithoutComma = (number) => formatValue(number, 'decimal', true);
export const formatPercent = (number) => formatValue(number, 'percent');
export const formatEuro = (number) => formatValue(number, 'currency');
export const formatEuroAsInteger = (number) => formatValue(number, 'currency', true);
export const formatText = (number = 0) => number.toFixed(2).replace('.', ',');
