import P from 'prop-types';

const field = P.shape({
  label: P.string.isRequired,
  default: P.oneOfType([P.string.isRequired, P.number.isRequired]).isRequired,
}).isRequired;
const fieldReq = field.isRequired;

const result = P.shape({
  label: P.string.isRequired,
  unit: P.string.isRequired,
}).isRequired;

export default P.shape({
  title: P.string.isRequired,
  subtitle: P.string.isRequired,
  backgroundImage: P.string.isRequired,
  backButton: P.string.isRequired,
  sections: P.shape({
    calculation: P.shape({
      adults: fieldReq,
      children: fieldReq,
      netIncome: fieldReq,
      equity: fieldReq,
      cars: fieldReq,
      motorcycles: fieldReq,
      repayment: fieldReq,
      otherCapital: field,
      otherIncome: field,
      otherExpenses: field,
      buttonCalculate: P.string.isRequired,
      buttonSave: P.string.isRequired,
    }).isRequired,
    result: P.shape({
      title: P.string.isRequired,
      netHouseholdIncome: P.string.isRequired,
      rateAdults: P.string.isRequired,
      rateChildren: P.string.isRequired,
      rateCars: P.string.isRequired,
      rateMotorcycles: P.string.isRequired,
      rateRepayment: P.string.isRequired,
      rateAdditionalCosts: P.string.isRequired,
      otherExpenses: P.string.isRequired,
      monthlyAvailableAmount: P.string.isRequired,
      maxBuyingPrice: P.string.isRequired,
      disclaimerEmpty: P.string.isRequired,
      disclaimerCosts: P.string.isRequired,
      negativeIncome: P.string.isRequired,
      isCalculating: P.string.isRequired,
      details: P.shape({
        title: P.string.isRequired,
        maxLoanAmount: result,
        additionalCosts: result,
        fixedInterestRate: result,
        boundInterestRate: result,
        effectiveInterestRate: result,
        initialRepayment: result,
        disclaimers: P.string.isRequired,
      }).isRequired,
      buttonConsultation: P.string.isRequired,
      buttonFindImmo: P.string.isRequired,
    }).isRequired,
  }).isRequired,
  errorMessages: P.shape({
    calculation: P.string.isRequired,
    isInt: P.string.isRequired,
    max: P.string.isRequired,
    required: P.string.isRequired,
  }).isRequired,
}).isRequired;
