import React from 'react';

const LockIcon = (props) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg xmlns="http://www.w3.org/2000/svg" width="8.226" height="12.328" viewBox="0 0 8.226 12.328" {...props}>
    <g id="lock" transform="translate(-5.274 -2.174)">
      <rect
        id="Rechteck_1867"
        width="7.226"
        height="6.551"
        stroke="#333333"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        rx="2"
        transform="translate(5.774 7.451)"
      />

      <g id="Gruppe_4154">
        <circle
          id="Ellipse_627"
          cx=".263"
          cy=".263"
          r=".263"
          stroke="#333333"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          transform="translate(9.199 10.038)"
        />
        <path
          id="Pfad_2759"
          d="M9.469 11.522V10.3z"
          stroke="#333333"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <path
        id="Pfad_2760"
        stroke="#333333"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M6.952 7.457V5.065a2.391 2.391 0 0 1 2.391-2.391 2.391 2.391 0 0 1 2.392 2.391v2.392"
      />
    </g>
  </svg>
);

export default LockIcon;
