/* eslint-disable react/no-array-index-key */
import useTranslation from 'next-translate/useTranslation';
import PropTypes from 'prop-types';
import { Box, Flex } from 'rebass/styled-components';
import styled from 'styled-components';

import Button from '@/components/Button/Button';
import UnstyledButton from '@/components/Button/UnstyledButton';
import Divider from '@/components/Divider';
import ExtendedCard from '@/components/ExtendedCard';
import { ArrowIcon } from '@/components/Icon';
import Markdown from '@/components/Markdown/Markdown';
import Text from '@/components/Text';
import Toggle from '@/components/Toggle/Toggle';

const UnstyledButonLowerCase = styled(UnstyledButton)`
  text-transform: none;
`;
const MyArrow = styled(ArrowIcon)`
  transform: rotate(-90deg);
`;
const ListInfoText = styled.div`
  > div > p {
    margin-bottom: 12px;
  }
`;
const MarkdownHoverText = styled(Markdown)`
  margin-bottom: 20px;
  > p > a:hover {
    color: #a70000;
  }
`;

const ButtonWrapper = styled(Flex)`
  flex-direction: row;
  @media (max-width: 768px) {
    flex-direction: column-reverse;
  }
`;
const Settings = ({ setPage, AllowAllButton }) => {
  const { t } = useTranslation('common-cookie-consent');
  const handleClick = () => {
    setPage('overview');
  };
  return (
    <>
      <UnstyledButonLowerCase onClick={handleClick}>
        <MyArrow />
        <Text variant="extendCard" ml={['0.5rem', '0.5rem', '0.5rem']}>
          {t('settings.back')}
        </Text>
      </UnstyledButonLowerCase>
      <Text variant="cardHedline" my={['1rem', '1rem', '1rem']}>
        {t('settings.title')}
      </Text>
      <MarkdownHoverText variant="small">{t('settings.text')}</MarkdownHoverText>
      {t('toggle', {}, { returnObjects: true }).map?.((item) => (
        <Box key={item.title}>
          <Flex justifyContent="space-between" marginBottom={['9px', 0, 0]}>
            <Text variant="subheadCard" marginBottom={[0, 0, 0]}>
              {item.title}
            </Text>
            <Toggle isDisabled={item.disabled} isToggled={item.toggled} name={item.name} />
          </Flex>
          <ExtendedCard
            noArrow
            nopadding
            variant="smallPrimary"
            position="inner"
            textIsClosed={t('settings.detailsClose')}
            textIsOpen={t('settings.detailsOpen')}
          >
            <ListInfoText>
              {item.infoText.map((infoText) => (
                <>
                  {infoText.name ? (
                    <Text variant="small" class="infoText">
                      <b>{infoText.name}</b>
                    </Text>
                  ) : null}
                  <Markdown variant="small">{infoText.description}</Markdown>
                </>
              ))}
            </ListInfoText>
          </ExtendedCard>
          <Divider mb={['1.25rem', '1.25rem', '1.25rem']} mt={['0.5rem', '0.5rem', '0.5rem']} />
        </Box>
      ))}

      <ButtonWrapper justifyContent="space-between" flexWrap="wrap">
        <Button submit variant="secondary" mb={['1.25rem', '1.25rem', '1.25rem']} width={['100%', '100%', 'auto']}>
          {t('settings.buttonTextAllowed')}
        </Button>
        {AllowAllButton}
      </ButtonWrapper>
    </>
  );
};

Settings.propTypes = {
  setPage: PropTypes.func.isRequired,
  AllowAllButton: PropTypes.element.isRequired,
};

export default Settings;
