const BuildingIcon = ({ ...props }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg xmlns="http://www.w3.org/2000/svg" width="13.846" height="15" viewBox="0 0 13.846 15" {...props}>
    <path
      fill="#e2001a"
      d="M4 3v15h6.346v-2.308H11.5V18h6.346V3zm1.154 1.154h11.538v12.692h-4.038v-2.308H9.192v2.308H5.154zm1.154 1.154v1.154h2.307V5.308zm3.462 0v1.154h2.308V5.308zm3.462 0v1.154h2.308V5.308zM6.308 7.615v1.154h2.307V7.615zm3.462 0v1.154h2.308V7.615zm3.462 0v1.154h2.308V7.615zM6.308 9.923v1.154h2.307V9.923zm3.462 0v1.154h2.308V9.923zm3.462 0v1.154h2.308V9.923zm-6.924 2.308v1.154h2.307v-1.154zm3.462 0v1.154h2.308v-1.154zm3.462 0v1.154h2.308v-1.154zm-6.924 2.307v1.154h2.307v-1.154zm6.923 0v1.154h2.308v-1.154z"
      transform="translate(-4 -3)"
    />
  </svg>
);

export default BuildingIcon;
