import Link from 'next/link';
import React, { Fragment, useLayoutEffect, useState } from 'react';
import { Flex } from 'rebass/styled-components';

import Section from '@/components/Section';
import Text from '@/components/Text';
import { useLoginDialogContext } from '@/context/LoginRegisterDialog';
import { useBreakpoints } from '@/hooks';
import useAuth from '@/hooks/useAuth';
import Footermenu from '@/widgets/Navigation/Footermenu';

const StandardFooter = () => {
  const [direction, setDirection] = useState('row');
  const [alignment, setAlignment] = useState('space-between');
  const [menuDirection, setMenuDirection] = useState('row');
  const [textMarginX, setTextMarginX] = useState('3rem');
  const [textMarginTop, setTextMarginTop] = useState('0');

  const breakpoint = useBreakpoints();
  const isMobile = ['small'].includes(breakpoint);
  const isTablet = ['medium'].includes(breakpoint);

  useLayoutEffect(() => {
    if (isMobile) {
      setDirection('column-reverse');
      setAlignment('center');
      setMenuDirection('column');
      setTextMarginX('0');
      setTextMarginTop('0.3rem');
    } else if (isTablet) {
      setDirection('row');
      setAlignment('center');
      setMenuDirection('row');
      setTextMarginX('1.5rem');
      setTextMarginTop('0');
    } else {
      setDirection('row');
      setAlignment('end');
      setMenuDirection('row');
      setTextMarginX('1.5rem');
      setTextMarginTop('0');
    }
  }, [isMobile, isTablet]);
  const { authenticated } = useAuth();
  const { setShowLoginDialog, setUrl } = useLoginDialogContext();

  const doLoginDialog = () => {
    setShowLoginDialog(true);
  };
  const handleLinkClick = (url) => {
    return () => {
      doLoginDialog();
      setUrl(url);
      // eslint-disable-next-line no-console
      console.log(url);
    };
  };
  return (
    <Section sx={{ zIndex: '2', position: 'relative' }} pb="2rem" id="FooterMenu">
      <Flex flexDirection={direction} justifyContent={alignment} alignItems="center">
        {Footermenu().isCopyright ? (
          <Flex justifyContent={alignment} mt={textMarginTop}>
            <Text variant="navFooterText"> {Footermenu().nav[0].title}</Text>
          </Flex>
        ) : null}
        <Flex flexDirection={menuDirection}>
          {Footermenu()
            .nav.slice(1)
            .map((item) => (
              <Fragment key={item.title}>
                {!item.portalLoginRequired || authenticated ? (
                  <Flex justifyContent={alignment}>
                    <Link href={item.url ?? '/'}>
                      <a data-testid={item.title} title={item.title}>
                        <Text
                          variant="navFooterLinkDefault"
                          style={{
                            whiteSpace: 'nowrap',
                            lineHeight: '2.5',
                            minWidth: 'auto',
                          }}
                          mx={textMarginX}
                          mt={textMarginTop}
                        >
                          {item.title}
                        </Text>
                      </a>
                    </Link>
                  </Flex>
                ) : (
                  <Flex justifyContent={alignment} onClick={handleLinkClick(item.url)}>
                    <Text
                      variant="navFooterLinkDefault"
                      style={{
                        whiteSpace: 'nowrap',
                        lineHeight: '2.5',
                        minWidth: 'auto',
                      }}
                      mx={textMarginX}
                      mt={textMarginTop}
                    >
                      {item.title}
                    </Text>
                  </Flex>
                )}
              </Fragment>
            ))}
        </Flex>
      </Flex>
    </Section>
  );
};

export default StandardFooter;
