import useTranslation from 'next-translate/useTranslation';
import { useRouter } from 'next/router';
import { useMutation } from 'react-query';

import { toast } from '@/components/Toaster/Toaster';
import { transferApi } from '@/services';

const useFastSignUp = () => {
  const { t } = useTranslation('page-reconciliation');

  const router = useRouter();

  return useMutation(
    'fastSignUp',
    async ({ birthDate, ...restValues }) => {
      const { data } = await transferApi.post('/customer', null, {
        params: {
          ...restValues,
          birthday: new Date(birthDate.split('.').reverse().join('-')).toISOString(),
        },
      });

      if (!data.success) {
        throw new Error('Error');
      }
      return data;
    },
    {
      onSuccess: (data) => {
        toast({
          type: 'success',
          message: t('alertMessage') ?? 'Die Bestätigungsmail wurde erfolgreich zugeschickt',
          dismissable: true,
          withIcon: true,
        });
        router.push({
          pathname: '/auth',
          query: {
            page: 'Confirmation',
            email: data.email,
          },
        });
      },
    }
  );
};

export default useFastSignUp;
