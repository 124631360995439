import PropTypes from 'prop-types';

const FeedbackSuccess = ({ color, ...rest }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" {...rest}>
    <g id="checkcircle" transform="translate(-947 -2041)">
      <path
        id="times-circle"
        d="M13 3a10 10 0 1 0 10 10A10.012 10.012 0 0 0 13 3zm0 1.538A8.462 8.462 0 1 1 4.538 13 8.451 8.451 0 0 1 13 4.538z"
        fill={color}
        transform="translate(944 2038)"
      />
      <path
        id="iconmonstr-check-mark-2"
        d="M3.75 10.494L0 6.9l1.163-1.2 2.565 2.447 5.087-5.182L10 4.139z"
        fill={color}
        transform="translate(952 2044.035)"
      />
    </g>
  </svg>
);

FeedbackSuccess.propTypes = {
  color: PropTypes.string,
};

FeedbackSuccess.defaultProps = {
  color: '#155724',
};

export default FeedbackSuccess;
