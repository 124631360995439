import { useRouter } from 'next/router';

const removeLang = (pathname) => {
  const removed = pathname ? pathname.replace(`/[lang]`, '') : '';
  return removed || '/';
};

const useCurrentNavPage = () => {
  const { pathname } = useRouter();
  return `/${removeLang(pathname).split('/')[1]}`;
};

export default useCurrentNavPage;
