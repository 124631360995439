const MapPinIcon = ({ ...props }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19.421" viewBox="0 0 19 19.421" {...props}>
    <path
      fill="#e2001a"
      d="M18.25 3a4.763 4.763 0 0 0-4.75 4.75 6.371 6.371 0 0 0 .693 2.474 27.806 27.806 0 0 0 1.385 2.7c1.008 1.738 2.029 3.191 2.029 3.191l.643.94.643-.94s1.021-1.453 2.029-3.191a27.806 27.806 0 0 0 1.385-2.7A6.371 6.371 0 0 0 23 7.75 4.763 4.763 0 0 0 18.25 3zm-7.941 1.534L4 7.23v15.19l6.358-2.746 6.333 2.375L23 19.353v-6.9c-.5.968-1.064 1.9-1.583 2.721v3.142L17.458 20v-1.29L16.3 17.027c-.124-.179-.269-.393-.421-.618v3.661l-4.75-1.781V6.513l.866.322a6.217 6.217 0 0 1 .416-1.535zm7.941.049a3.157 3.157 0 0 1 3.167 3.167 5.9 5.9 0 0 1-.544 1.831 27.447 27.447 0 0 1-1.336 2.548c-.646 1.113-.925 1.509-1.286 2.053-.362-.544-.64-.94-1.286-2.053a27.447 27.447 0 0 1-1.336-2.548 5.9 5.9 0 0 1-.544-1.831 3.157 3.157 0 0 1 3.165-3.167zm-8.708 1.98v11.751L5.583 20V8.27zm8.708 0a1.188 1.188 0 1 0 1.188 1.187 1.188 1.188 0 0 0-1.188-1.187z"
      transform="translate(-4 -3)"
    />
  </svg>
);

export default MapPinIcon;
