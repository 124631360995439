import PropTypes from 'prop-types';

const FeedbackClose = ({ color, ...rest }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg xmlns="http://www.w3.org/2000/svg" width="11.414" height="11.414" viewBox="0 0 11.414 11.414" {...rest}>
    <path
      fill={color}
      stroke={color}
      d="M7.027-24.3l-.7.7 4.3 4.3-4.3 4.3.7.7 4.3-4.3 4.3 4.3.7-.7-4.3-4.3 4.3-4.3-.7-.7-4.3 4.3z"
      transform="translate(-5.616 25.009)"
    />
  </svg>
);

FeedbackClose.propTypes = {
  color: PropTypes.string,
};

FeedbackClose.defaultProps = {
  color: '155724',
};

export default FeedbackClose;
