const getItemStyle = (isDragging, draggableStyle) => {
  const { transform } = draggableStyle;
  let activeTransform = {};
  if (transform) {
    activeTransform = {
      transform: `translate(0, ${transform.substring(transform.indexOf(',') + 1, transform.indexOf(')'))})`,
    };
  }
  return {
    userSelect: 'none',
    outline: 'none',
    webkitAppearance: 'none',
    ...draggableStyle,
    ...activeTransform,
  };
};

export default getItemStyle;
