const PlusIconAddSearchAgent = ({ ...rest }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18.28"
    height="18.28"
    viewBox="0 0 18.28 18.28"
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...rest}
  >
    <g fill="#e2001a">
      <path
        d="m10.702 11.865-.177-.176-4.77-4.77-4.769 4.77-.176.176-.177-.176-.81-.81-.177-.177.177-.177 4.77-4.77L-.177.987-.354.81l.177-.177.81-.81.177-.177.176.177 4.77 4.77 4.77-4.77.176-.177.177.177.81.81.176.177-.176.176-4.77 4.77 4.77 4.77.176.176-.176.177-.81.81-.177.176z"
        stroke="none"
        transform="rotate(-45 11.533 3.363)"
      />
      <path
        d="M.81 0 0 .81l4.946 4.946L0 10.702l.81.81 4.946-4.946 4.946 4.946.81-.81-4.946-4.946L11.512.81l-.81-.81-4.946 4.946L.81 0m0-.707 4.946 4.946 4.946-4.946L12.22.81 7.273 5.756l4.946 4.946-1.517 1.517-4.946-4.946L.81 12.219l-1.517-1.517 4.946-4.946L-.707.81.81-.707z"
        fill="#e2001a"
        stroke="none"
        transform="rotate(-45 11.533 3.363)"
      />
    </g>
  </svg>
);

export default PlusIconAddSearchAgent;
