export default {
  breakpoints: ['468px', '768px', '1200px'],
  fontSizes: {
    // zeplin values in comments
    tiny: '0.75rem', // 15px
    small: '0.8rem', // 16px
    intermitten: '0.875rem', // 16px
    normal: '0.9rem', // 18px
    paragraph: '1rem', // 20px
    heading: '1.2rem', // 24px
    progressBar: '1.25rem', // 25px
    subtitle: '1.5rem', // 30px
    title: '1.75rem', // 35px
    logo: '2.1rem', // 42px
    hero: '2rem', // 50px
  },
  fontWeights: [500, 600, 700, 800, 900],
  lineHeights: {
    solid: 1,
    title: 1.23,
    termsText: 1.2,
    stackedText: '1.3rem', // 26px
    infoBox: '1.5rem',
    tall: 1.7,
    header: 2.5,
    select: 2.5,
  },
  letterSpacings: {
    normal: 'normal',
    tracked: '0.1em',
    tiny: '-0.15px',
    tight: '-0.26px',
    tighter: '-0.35px',
    mega: '0.25em',
  },
  fonts: {
    serif: 'Plus Jakarta Sans Medium',
    sansSerif: 'Plus Jakarta Sans Medium',
    helvetica: 'Plus Jakarta Sans Medium',
  },
  borders: [0, '1px solid', '2px solid', '4px solid', '8px solid', '16px solid', '32px solid'],
  radii: [0, 2, 4, 16, 9999, '100%'],
  width: [16, 32, 71, 128, 256],
  heights: [16, 32, 71, 128, 256],
  maxWidths: [16, 32, 64, 128, 256, 512, 768, 1200, 1536],
  outlines: [0],
  shadows: {
    default: `1px 1px 10px 0 rgba(0, 0, 0, 0.05)`,
    // default: `1px 1px 5px 0 rgba(0, 0, 0, 0.16)`,
    horizontal: `3px 0px 6px 0 rgba(0, 0, 0, 0.16)`,
    dropdown: `0 0 6px 0 rgba(0, 0, 0, 0.16)`,
  },
};
