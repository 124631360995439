const CloseIconSecondary = ({ ...rest }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12.551"
    height="12.551"
    viewBox="0 0 12.551 12.551" // eslint-disable-next-line react/jsx-props-no-spreading
    {...rest}
  >
    <path
      fill="#a09e9e"
      d="M7.107-24.3l-.783.783 4.785 4.785-4.786 4.784.783.783 4.785-4.785 4.785 4.785.783-.783-4.785-4.785 4.785-4.785-.783-.783-4.785 4.785z"
      transform="translate(-5.616 25.009)"
    />
  </svg>
);

export default CloseIconSecondary;
