const MowerIcon = ({ ...props }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg xmlns="http://www.w3.org/2000/svg" width="19.037" height="14.685" viewBox="0 0 19.037 14.685" {...props}>
    <g id="mower" transform="translate(-1780.322 -3870.712)">
      <g fill="#e2001a" stroke="#e2001a">
        <path
          id="Differenzmenge_12"
          d="M7.737 7.991L1.991 6.665a3.177 3.177 0 0 0 .3-1.35A3.231 3.231 0 0 0 0 2.238L.347.722a1 1 0 0 1 .973-.776 1.027 1.027 0 0 1 .226.026l8.062 1.861a1 1 0 0 1 .749 1.2l-.087.375a3.179 3.179 0 0 0-1.155.362H9.1a3.211 3.211 0 0 0-.843.662l-.007.007-.006.006-.008.009A3.208 3.208 0 0 0 7.42 6.6a3.166 3.166 0 0 0 .316 1.387z"
          transform="translate(1786.469 3876.541)"
        />
      </g>
      <g fill="#e2001a" stroke="#e2001a">
        <path
          id="Pfad_2076"
          d="M23191.8 3195l-3.33-5.79h-2.535a1.792 1.792 0 0 1-.107-.535 1.177 1.177 0 0 1 .107-.467h3.23l3.609 6.216z"
          transform="translate(-21405.002 683)"
        />
      </g>
      <path
        id="Differenzmenge_9"
        fill="#e2001a"
        d="M2.256 4.513a2.256 2.256 0 1 1 2.256-2.256 2.259 2.259 0 0 1-2.256 2.256zm0-3.008a.752.752 0 1 0 .752.752.753.753 0 0 0-.753-.752z"
        transform="translate(1794.848 3880.884)"
      />
      <g id="Differenzmenge_8" fill="#e2001a" stroke="none">
        <path
          d="M2.247 3.996C1.284 3.996.5 3.212.5 2.248c0-.963.784-1.747 1.747-1.747.964 0 1.749.784 1.749 1.747 0 .964-.785 1.748-1.749 1.748zM2.248 1C1.56 1 1 1.56 1 2.25c0 .688.56 1.249 1.25 1.249.688 0 1.249-.56 1.249-1.25C3.498 1.56 2.938 1 2.248 1z"
          transform="translate(1783.301 3879.609)"
        />
        <path
          d="M2.247 4.496C1.008 4.496 0 3.488 0 2.248 0 1.01 1.008.001 2.247.001c1.24 0 2.249 1.008 2.249 2.247 0 1.24-1.009 2.248-2.249 2.248zM2.248 1.5c-.413 0-.749.336-.749.75 0 .413.336.749.75.749.413 0 .749-.336.749-.75 0-.413-.336-.749-.75-.749z"
          transform="translate(1783.301 3879.609)"
        />
      </g>
    </g>
  </svg>
);
export default MowerIcon;
