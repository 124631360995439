const KindergardenIcon = ({ ...props }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg xmlns="http://www.w3.org/2000/svg" width="15.863" height="15" viewBox="0 0 15.863 15" {...props}>
    <path
      fill="#4692c9"
      d="M15.03 3.863l-3.83 6.921h7.666zm0 2.565l1.724 3.113h-3.448zm-8.3 4.978a3.729 3.729 0 1 0 3.729 3.729 3.733 3.733 0 0 0-3.73-3.729zm4.972.621v6.836h6.836v-6.836zm-4.972.621a2.486 2.486 0 1 1-2.486 2.486 2.488 2.488 0 0 1 2.485-2.485zm6.215.621h4.35v4.35h-4.35z"
      transform="translate(-3 -3.863)"
    />
  </svg>
);

export default KindergardenIcon;
