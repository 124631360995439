import axios from 'axios';
import https from 'https';

import config from '../config';
import { isSSR } from '../utils';

const apiClient = axios.create({
  baseURL: `${config.backend}/content`,
  headers: {
    'Content-Type': 'application/json',
  },
  httpsAgent: new https.Agent({
    rejectUnauthorized: false,
  }),
});

const apiReq = ({ url, lang, portal, ...args }) =>
  apiClient({
    ...args,
    url,
    params: {
      lang: lang ?? config.defaultLang,
    },
    headers: {
      domain: portal,
    },
  });

const clientOrigin = (host) => host.split(':')[0];

const api = async (url, lang, portal) => {
  try {
    const { data } = await apiReq({
      url,
      lang,
      portal: isSSR ? clientOrigin(portal) : window.location.hostname,
    });

    return data;
  } catch (error) {
    const { data } = await apiReq({ url });

    return data;
  }
};

export default api;
