const PlusIconTask = ({ ...rest }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15"
    height="15"
    viewBox="0 0 15 15"
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...rest}
  >
    <path
      fill="#a09e9e"
      d="M11.818 5v6.818H5v1.364h6.818V20h1.364v-6.818H20v-1.364h-6.818V5z"
      transform="translate(-5 -5)"
    />
  </svg>
);

export default PlusIconTask;
