import theme from './basicTheme/index';

const DEFAULT_THEME = theme;

const {
  buttons,
  colors,
  mediaQueries,
  sizes,
  space,
  textStyles,
  transitions,
  borders,
  letterSpacings,
  lineHeights,
  radii,
  shadows,
  zIndexes,
  fonts,
  fontSizes,
  fontWeights,
} = DEFAULT_THEME;

const TYPOGRAPHY = {
  logo: 'logo',
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  h4: 'h4',
  progress: 'progress',
  paragraph: 'paragraph',
  normal: 'normal',
  small: 'small',
  header: 'header',
  input: 'input',
  label: 'label',
  tiny: 'tiny',
  tabs: 'tabs',
};

export {
  colors as COLORS,
  buttons as BUTTONS,
  sizes as SIZES,
  space as SPACE,
  textStyles as TEXT_STYLES,
  transitions as TRANSITIONS,
  mediaQueries as MEDIA_QUERIES,
  borders as BORDERS,
  letterSpacings as LETTER_SPACINGS,
  lineHeights as LINE_HEIGHTS,
  TYPOGRAPHY,
  radii as RADII,
  shadows as SHADOWS,
  zIndexes as Z_INDEXES,
  fonts as FONTS,
  fontSizes as FONT_SIZES,
  fontWeights as FONT_WEIGHTS,
  DEFAULT_THEME,
};
