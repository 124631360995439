const Trash = ({ ...props }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg xmlns="http://www.w3.org/2000/svg" width="12.5" height="15" viewBox="0 0 12.5 15" {...props}>
    <path
      fill="#e2001a"
      d="M12 4a1.2 1.2 0 0 0-1.25 1.25v.625H7v1.25h.625v10A1.885 1.885 0 0 0 9.5 19H17a1.885 1.885 0 0 0 1.875-1.875v-10h.625v-1.25h-3.75V5.25A1.2 1.2 0 0 0 14.5 4zm0 1.25h2.5v.625H12zM8.875 7.125h8.75v10a.623.623 0 0 1-.625.625H9.5a.623.623 0 0 1-.625-.625zM10.125 9v6.875h1.25V9zm2.5 0v6.875h1.25V9zm2.5 0v6.875h1.25V9z"
      transform="translate(-7 -4)"
    />
  </svg>
);

export default Trash;
