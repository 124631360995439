import { useWindowWidth } from '@react-hook/window-size';
import { useEffect, useState } from 'react';

import { DEFAULT_THEME } from '../themes';

const breakpoints = DEFAULT_THEME.breakpoints.map((item) => item.split('p')[0]);

const useBreakpoints = () => {
  const width = useWindowWidth();
  const [breakpoint, setBreakpoint] = useState(undefined);

  useEffect(() => {
    if (width <= breakpoints[0]) {
      setBreakpoint('small');
    } else if (width <= breakpoints[1]) {
      setBreakpoint('medium');
    } else if (width <= breakpoints[2]) {
      setBreakpoint('large');
    } else {
      setBreakpoint('xl');
    }
  }, [width]);

  return breakpoint;
};

export default useBreakpoints;
