import { useQuery } from 'react-query';

import { userApi } from '@/services';

import useAuth from './useAuth';

const useNotification = () => {
  const { authenticated } = useAuth();

  return useQuery(
    'notificationSettings',
    async () => {
      const { data } = await userApi('/notification/setting');
      return data.data;
    },
    {
      enabled: authenticated,
      cacheTime: 1 * 60 * 1000,
    }
  );
};

export default useNotification;
