import base from './base';
import colors from './colors';

const button = {
  outline: 'none',
  borderRadius: '4px',
  borderWidth: '1px',
  borderStyle: 'solid',
  fontSize: base.fontSizes.normal,
  padding: '12px 20px',

  '&:not(:disabled)': {
    cursor: 'pointer',
  },
  '&:focus': {
    boxShadow: `0 0 2px 1px ${colors.gray};`,
  },
};

export default {
  primary: {
    ...button,
    color: colors.white,
    backgroundColor: colors.primary,
    borderColor: colors.primary,

    '&:not(:disabled):hover': {
      backgroundColor: colors.primaryHover,
    },

    '&:disabled': {
      backgroundColor: colors.primaryDisabled,
      borderColor: colors.primaryDisabled,
    },
  },

  secondary: {
    ...button,
    color: colors.darkGrey,
    backgroundColor: colors.white,
    borderColor: colors.border,

    '&:not(:disabled):hover': {
      backgroundColor: colors.buttonSecondaryHover,
    },

    '&:disabled': {
      color: colors.disabled,
      backgroundColor: colors.white,
      borderColor: colors.lightBorder,
    },
  },

  green: {
    ...button,
    color: colors.white,
    backgroundColor: colors.buttongreen,
    borderColor: colors.buttongreen,

    // '&:not(:disabled):hover': {
    //   backgroundColor: colors.buttongreen,
    // },

    '&:disabled': {
      color: colors.disabled,
      backgroundColor: colors.white,
      borderColor: colors.lightBorder,
    },
  },

  // TODO: this should be just a link
  minimal: {
    ...button,
    padding: 0,
    textTransform: 'none',
    border: 'none',
    color: colors.primary,
    backgroundColor: colors.transparent,
    fontWeight: base.fontWeights[1],

    '&:not(:disabled):hover': {
      color: colors.primaryHover,
    },

    '&:disabled': {
      color: colors.disabled,
    },
  },
};
