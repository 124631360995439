const parseTemplate = (text, map) => {
  let result = text;
  Object.keys(map).forEach((currentTag) => {
    // eslint-disable-next-line prefer-template
    result = result.replace('{' + currentTag + '}', map[currentTag]).replace('$', '');
  });
  return result;
};

export default parseTemplate;
