import * as cookie from 'cookie';

export { default as isSSR } from './isSSR';
export {
  formatEuro,
  formatPercent,
  formatDecimal,
  formatDecimalwithoutComma,
  default as formatValue,
} from './formatValues';
export { default as parseTemplate } from './parseTemplate';
export { default as titleCase } from './titleCase';
export { default as reorder } from './reorderLists';
export { default as getItemStyle } from './getItemStyle';
export { default as truncateStr } from './truncateStr';
export { default as formatLocaleDay } from './localeDEDay';
export { default as formatTime } from './formatTime';
export { default as mergeArrays } from './mergeArrays';

export function parseCookies(req) {
  if (!req || !req.headers) {
    return {};
  }
  return cookie.parse(req.headers.cookie || '');
}
