const PaperClip = ({ ...props }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg xmlns="http://www.w3.org/2000/svg" width="13.038" height="15" viewBox="0 0 13.038 15" {...props}>
    <path
      fill="#e2001a"
      d="M15.227 4a3.357 3.357 0 0 0-2.385 1l-5.864 5.861a4.769 4.769 0 0 0 6.744 6.744l3.91-3.91-.88-.88-3.91 3.91a3.525 3.525 0 0 1-4.985-4.985l5.864-5.864a2.142 2.142 0 0 1 3.03 3.03l-5.864 5.864a.76.76 0 0 1-1.075-1.07l5.376-5.38-.88-.88-5.376 5.376a2 2 0 1 0 2.834 2.834l5.864-5.864A3.392 3.392 0 0 0 15.227 4z"
      transform="translate(-5.583 -4)"
    />
  </svg>
);

export default PaperClip;
