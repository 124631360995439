const BellGreenIcon = ({ ...props }) => (
  <svg
    width="59"
    height="61"
    viewBox="0 0 59 61"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
  >
    <path
      opacity="0.327"
      d="M39.0954 9.06291C41.6469 10.6573 43.8594 12.7386 45.6066 15.1881C47.3538 17.6375 48.6014 20.4071 49.2782 23.3387C49.955 26.2703 50.0478 29.3065 49.5512 32.274C49.0546 35.2415 47.9784 38.082 46.384 40.6336C42.495 46.8574 37.2755 48.4681 30.5056 49.1793C25.5963 49.695 21.3033 52.6668 16.7964 49.8506C13.9098 48.0468 8.35639 43.0825 6.58908 40.399C1.82448 33.1643 6.42815 27.817 13.5699 21.1017C18.7841 15.6949 28.3646 2.35761 39.0954 9.06291Z"
      fill="#8FD3BC"
    />
    <path
      d="M29.3868 18.7816C28.4044 18.7816 27.6094 19.5766 27.6094 20.559C27.6094 20.6354 27.6267 20.7083 27.6372 20.7812C24.5648 21.5658 22.2771 24.3569 22.2771 27.6688V35.6673C22.2771 36.1706 21.8917 36.556 21.3884 36.556H20.4996V38.3334H26.8873C26.7866 38.6146 26.7207 38.9097 26.7207 39.2221C26.7207 40.6837 27.9253 41.8883 29.3868 41.8883C30.8484 41.8883 32.053 40.6837 32.053 39.2221C32.053 38.9097 31.987 38.6146 31.8864 38.3334H38.274V36.556H37.3853C36.8819 36.556 36.4966 36.1706 36.4966 35.6673V27.9187C36.4966 24.5791 34.2644 21.6005 31.1365 20.7812C31.1469 20.7083 31.1643 20.6354 31.1643 20.559C31.1643 19.5766 30.3693 18.7816 29.3868 18.7816ZM28.998 22.3365C29.1265 22.3261 29.2549 22.3365 29.3868 22.3365C29.4424 22.3365 29.4979 22.3365 29.5535 22.3365C32.4557 22.4233 34.7191 24.9679 34.7191 27.9187V35.6673C34.7191 35.9797 34.7851 36.2748 34.8858 36.556H23.8879C23.9886 36.2748 24.0545 35.9797 24.0545 35.6673V27.6688C24.0545 24.8464 26.2277 22.5378 28.998 22.3365ZM29.3868 38.3334C29.8867 38.3334 30.2755 38.7222 30.2755 39.2221C30.2755 39.7221 29.8867 40.1109 29.3868 40.1109C28.8869 40.1109 28.4981 39.7221 28.4981 39.2221C28.4981 38.7222 28.8869 38.3334 29.3868 38.3334Z"
      fill="#43997B"
    />
  </svg>
);

export default BellGreenIcon;
