const Clock = ({ ...props }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg xmlns="http://www.w3.org/2000/svg" width="9.827" height="9.827" viewBox="0 0 9.827 9.827" {...props}>
    <path
      d="M8.714 4a4.714 4.714 0 1 0 4.714 4.714A4.719 4.719 0 0 0 8.714 4zm0 .786a3.928 3.928 0 1 1-3.928 3.928 3.922 3.922 0 0 1 3.928-3.928zm-.393.786v3.534h2.75v-.785H9.106v-2.75z"
      transform="translate(-3.8 -3.8)"
      fill="#fff"
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="0.4px"
    />
  </svg>
);

export default Clock;
