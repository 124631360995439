const ChecklistService = ({ ...props }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg xmlns="http://www.w3.org/2000/svg" width="13.686" height="10.4" viewBox="0 0 13.686 10.4" {...props}>
    <path
      d="M13.91 6.281 6.181 14.01l-3.257-3.256-.643.643 3.578 3.578.321.307.321-.307 8.05-8.05z"
      transform="translate(-1.574 -5.574)"
      fill="#e2001a"
      stroke="#e2001a"
    />
  </svg>
);

export default ChecklistService;
