const Close = ({ ...props }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg xmlns="http://www.w3.org/2000/svg" width="12.5" height="12.5" viewBox="0 0 12.5 12.5" {...props}>
    <path
      fill="#333333"
      stroke="#333333"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="0.5px"
      d="M7.167-24.3l-.844.844 5.156 5.156-5.156 5.154.844.844 5.156-5.156 5.156 5.158.844-.844-5.156-5.156 5.156-5.156-.844-.844-5.156 5.156z"
      transform="translate(-6.073 24.552)"
    />
  </svg>
);

export default Close;
