const MinusIcon = ({ ...rest }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg xmlns="http://www.w3.org/2000/svg" width="11.414" height="11.414" viewBox="0 0 11.414 11.414" {...rest}>
    <g fill="#333333">
      <path
        d="M15.62-13.948l-.177-.177-4.12-4.12-4.12 4.12-.176.177-.177-.177-.704-.703-.176-.177.176-.177 4.12-4.12-4.12-4.12-.176-.176.176-.177.704-.704.177-.176.176.176 4.12 4.12 4.12-4.12.177-.176.177.176.703.704.177.177-.177.176-4.12 4.12 4.12 4.12.177.177-.177.177-.703.703-.177.177z"
        transform="translate(-5.616 25.009)"
      />
      <path
        d="M7.027-24.302l-.704.704 4.297 4.296-4.297 4.297.704.703 4.296-4.296 4.297 4.296.703-.703-4.296-4.297 4.296-4.296-.703-.704-4.297 4.297-4.296-4.297m0-.707l.353.354 3.943 3.943 3.943-3.943.354-.354.353.354.704.703.353.354-.353.353-3.943 3.943 3.943 3.943.353.354-.353.353-.704.704-.353.353-.354-.353-3.943-3.943-3.943 3.943-.353.353-.354-.353-.703-.704-.354-.353.354-.354 3.943-3.943-3.943-3.943-.354-.353.354-.354.703-.703.354-.354z"
        transform="translate(-5.616 25.009)"
      />
    </g>
  </svg>
);

export default MinusIcon;
