const ImageIcon = ({ ...props }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg xmlns="http://www.w3.org/2000/svg" width="21.292" height="17.366" viewBox="0 0 21.292 17.366" {...props}>
    <g id="Gruppe_3011" transform="translate(-189 -13)">
      <g id="image" fill="none">
        <path d="M2 5v13.98h17.792V5z" stroke="none" transform="translate(190.5 11.386)" />
        <path
          d="M3.5 6.5v10.98h14.792V6.5H3.5M2 5h17.792v13.98H2V5z"
          fill="#333333"
          stroke="none"
          transform="translate(190.5 11.386)"
        />
      </g>
      <g id="image-2" fill="#fff">
        <path d="M19.043 18.23H2.75V5.75h16.293v12.48z" stroke="none" transform="translate(187 8)" />
        <path
          d="M3.5 6.5v10.98h14.793V6.5H3.5M2 5h17.793v13.98H2V5z"
          fill="#333333"
          stroke="none"
          transform="translate(187 8)"
        />
      </g>
      <path
        id="image-3"
        fill="#333333"
        d="M2 5v13.98h17.793V5zm1.271 1.271h15.251v8.837l-3.356-3.376-.457-.457-2.879 2.88-3.654-3.694L7.719 10l-4.448 4.452zM15.98 7.542a1.271 1.271 0 1 0 1.271 1.271 1.27 1.27 0 0 0-1.271-1.271zm-8.261 4.269l5.838 5.9H3.271v-1.45zm6.99 1.271l3.813 3.813v.814h-3.158l-2.641-2.661z"
        transform="translate(187 8)"
      />
    </g>
  </svg>
);

export default ImageIcon;
