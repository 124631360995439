const TargetIcon = ({ ...props }) => (
  <svg
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
  >
    <rect
      x="36"
      y="36"
      width="36"
      height="36"
      rx="18"
      transform="rotate(180 36 36)"
      fill="#52B292"
      fillOpacity="0.101961"
    />
    <path
      d="M16.2262 9V10.6452H8V18.0488H24.838L25.0694 17.7661L28 14.347L25.0694 10.928L24.838 10.6452H17.8715V9H16.2262ZM9.64524 12.2905H24.0668L25.8406 14.347L24.0668 16.4036H9.64524V12.2905ZM16.2262 18.8715V27.0977H17.8715V18.8715H16.2262Z"
      fill="#52B292"
    />
  </svg>
);

export default TargetIcon;
