import PropTypes from 'prop-types';
import { createContext, useContext, useState } from 'react';

import NotificationCard from '@/widgets/Notification/NotificationCard';

const NotificationCardContext = createContext(undefined);
export const useNotificationCardContext = () => useContext(NotificationCardContext);

export function NotificationCardProvider({ children }) {
  const [showDialog, setShowDialog] = useState(false);

  const closeLoginDialog = () => {
    setShowDialog(false);
  };

  return (
    <NotificationCardContext.Provider value={{ setShowDialog }}>
      <NotificationCard showDialog={showDialog} onCloseClick={closeLoginDialog} />
      {children}
    </NotificationCardContext.Provider>
  );
}

NotificationCardProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default NotificationCardContext;
