const CloseIconModal = ({ ...rest }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 18 18"
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...rest}
  >
    <path
      fill="#A09E9E"
      d="M7.589-24.3l-1.266 1.264 7.734 7.736-7.734 7.732L7.589-6.3l7.734-7.734L23.057-6.3l1.266-1.266-7.734-7.734 7.734-7.734-1.266-1.266-7.734 7.734z"
      transform="translate(-6.323 24.302)"
    />
  </svg>
);

export default CloseIconModal;
