const SearchIcon = ({ ...props }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" {...props}>
    <path
      fill="#e2001a"
      d="M14.282 3a7 7 0 0 0-5.424 11.417l-5.577 5.577L4.287 21l5.577-5.577A7 7 0 1 0 14.282 3zm0 1.4a5.6 5.6 0 1 1-5.6 5.6 5.589 5.589 0 0 1 5.6-5.6z"
      transform="translate(-3.281 -3)"
    />
  </svg>
);

export default SearchIcon;
