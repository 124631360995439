const ListIcon = ({ ...props }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg xmlns="http://www.w3.org/2000/svg" width="4.25" height="4.25" viewBox="0 0 4.25 4.25" {...props}>
    <path
      fill="#e2001a"
      d="M2.125 0A2.125 2.125 0 1 0 4.25 2.125 2.124 2.124 0 0 0 2.125 0zM8.5 0a2.125 2.125 0 1 0 2.125 2.125A2.124 2.124 0 0 0 8.5 0zm6.375 0A2.125 2.125 0 1 0 17 2.125 2.124 2.124 0 0 0 14.875 0z"
      transform="rotate(-90 8.5 8.5)"
    />
  </svg>
);

export default ListIcon;
