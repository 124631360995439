const axios = require('axios');
const https = require('https');

const apiClient = axios.create({
  baseURL: `${process.env.NEXT_PUBLIC_BACKEND}/config`,
  headers: {
    'Content-Type': 'application/json',
  },
  httpsAgent: new https.Agent({
    rejectUnauthorized: false,
  }),
});

const apiReq = ({ url, portal, ...args }) =>
  apiClient({
    ...args,
    url,
    headers: {
      domain: portal,
    },
  });

const api = async (url, portal) => {
  try {
    const { data } = await apiReq({
      url,
      portal,
    });

    return data;
  } catch (error) {
    const { data } = await apiReq({ url });

    return data;
  }
};

module.exports = api;
