import { useQuery } from 'react-query';

import { userApi } from '@/services';

import useAuth from './useAuth';

const useDocumentMeta = (documentId) => {
  const { initialized, authenticated, login } = useAuth();

  if (initialized && !authenticated) login();

  const query = useQuery(
    ['documentMetaData', documentId],
    async () => {
      return (await userApi({ url: `/documents/${documentId}/meta` })).data?.[0];
    },
    {
      enabled: authenticated,
    }
  );

  return query;
};

export default useDocumentMeta;
