import axios from 'axios';
import https from 'https';

import config from '../config';

const api = axios.create({
  baseURL: `${config.backend}/transfer`,
  headers: {
    'Content-Type': 'application/json',
    domain: config.portal,
  },
  httpsAgent: new https.Agent({
    rejectUnauthorized: false,
  }),
});

export default api;
