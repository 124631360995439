import PropTypes from 'prop-types';

const FeedbackSuccess = ({ color, ...rest }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" {...rest}>
    <path
      fill={color}
      d="M13 3a10 10 0 1 0 10 10A10.012 10.012 0 0 0 13 3zm0 1.538A8.462 8.462 0 1 1 4.538 13 8.451 8.451 0 0 1 13 4.538zm-2.909 4.448l-1.105 1.105L11.894 13l-2.908 2.909 1.106 1.106L13 14.106l2.909 2.909 1.106-1.106L14.106 13l2.909-2.909-1.106-1.105L13 11.894z"
      transform="translate(-3 -3)"
    />
  </svg>
);

FeedbackSuccess.propTypes = {
  color: PropTypes.string,
};

FeedbackSuccess.defaultProps = {
  color: '155724',
};

export default FeedbackSuccess;
