//             0  1  2  3   4   5   6    7    8
const sizes = [0, 4, 8, 16, 32, 64, 128, 256, 512];

sizes.rootFontSize = '16px'; // equals 1rem

sizes.bodyPaddingBottom = 80;
sizes.bodyPaddingTop = 60;
sizes.cardPadding = 60;
sizes.footerPadding = 20;
sizes.homePageHeaderHeight = 550;
sizes.iconCircleSizes = [60, 88, 105, 130];

sizes.layoutBodyPaddingBottom = 64;
sizes.layoutBodyWidth = 1000;
sizes.fullLayoutWidth = 1200;
sizes.halfLayoutWidth = sizes.fullLayoutWidth / 2;
sizes.layoutHeaderHeight = '5.3rem';
sizes.layoutHeaderHeightCollapsed = 85;
sizes.layoutHeaderWithTabsTop = -55;
sizes.layoutAuxHeaderHeigh = 40;

sizes.modalBorderRadius = 6;
sizes.modalMaxWidth = 540;
sizes.modalPadding = 48;
sizes.modalTopMargin = 30;

sizes.productCardWidth = 200;
sizes.productCardHeight = 340;
sizes.tabMinWidth = 170;
sizes.singleColumnPageMaxWidth = 620;

export default sizes;
