import PropTypes from 'prop-types';
import React from 'react';
import { Flex } from 'rebass/styled-components';
import styled from 'styled-components';
import { layout, space } from 'styled-system';

export const LayoutWrapper = styled(Flex)`
  ${space}
  ${layout}
  flex-direction: column;
  min-height: 100vh;
  flex: 1;
`;

export const LayoutBody = styled(Flex)`
  flex: 1;
  justify-content: center;
  background-color: #f7f7f7;
`;

export const LayoutInnerBody = ({ children, ...props }) => (
  <Flex flexDirection="column" flex={1} {...props} mt={['68px', '68px', '68px']}>
    {children}
  </Flex>
);

LayoutInnerBody.propTypes = {
  children: PropTypes.node.isRequired,
};
