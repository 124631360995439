const PersonIcon = ({ ...props }) => (
  <svg
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
  >
    <circle cx="18" cy="18" r="18" fill="white" />
    <rect x="36" y="36" width="36" height="36" rx="18" transform="rotate(180 36 36)" fill="#125A41" fillOpacity="0.1" />
    <path
      d="M18 10C15.196 10 12.9091 12.2869 12.9091 15.0909C12.9091 16.821 13.7898 18.3523 15.1136 19.2727C12.1278 20.4375 10 23.3466 10 26.7273H11.4545C11.4545 23.5284 13.7756 20.8551 16.8182 20.2955L17.2727 21.6364H18.7273L19.1818 20.2955C22.2244 20.8551 24.5455 23.5284 24.5455 26.7273H26C26 23.3466 23.8722 20.4375 20.8864 19.2727C22.2102 18.3523 23.0909 16.821 23.0909 15.0909C23.0909 12.2869 20.804 10 18 10ZM18 11.4545C20.017 11.4545 21.6364 13.0739 21.6364 15.0909C21.6364 17.108 20.017 18.7273 18 18.7273C15.983 18.7273 14.3636 17.108 14.3636 15.0909C14.3636 13.0739 15.983 11.4545 18 11.4545ZM17.2727 22.3636L16.5455 26.7273H19.4545L18.7273 22.3636H17.2727Z"
      fill="#125A41"
    />
  </svg>
);

export default PersonIcon;
