import base from './base';
import colors from './colors';
import spaces from './spaces';

const textStyles = {
  logo: {
    fontSize: base.fontSizes.logo,
    fontWeight: base.fontWeights[3],
    color: colors.primary,
  },

  h1: {
    fontSize: base.fontSizes.hero,
    fontWeight: base.fontWeights[3],
    color: colors.primary,
    marginBottom: spaces[7],
    marginTop: spaces[8],
  },

  h2: {
    fontSize: base.fontSizes.title,
    fontWeight: base.fontWeights[2],
    color: colors.primary,
    marginBottom: spaces[6],
    marginTop: spaces[7],
  },

  h3: {
    fontSize: base.fontSizes.subtitle,
    fontWeight: base.fontWeights[2],
    color: colors.darkGrey,
    marginBottom: spaces[5],
    marginTop: spaces[6],
  },

  h4: {
    fontSize: base.fontSizes.heading,
    fontWeight: base.fontWeights[2],
    letterSpacing: base.letterSpacings.tight,
    color: colors.darkGrey,
    marginBottom: spaces[4],
    marginTop: spaces[5],
  },

  h5: {
    fontSize: base.fontSizes.heading,
    fontWeight: base.fontWeights[1],
    letterSpacing: base.letterSpacings.normal,
    color: colors.darkGrey,
    marginBottom: spaces[4],
    marginTop: spaces[5],
  },

  paragraph: {
    fontSize: base.fontSizes.paragraph,
    fontWeight: base.fontWeights[0],
    color: colors.darkGrey,
    marginBottom: spaces[3],
    marginTop: spaces[4],
  },

  header: {
    fontSize: base.fontSizes.title,
    fontWeight: base.fontWeights[2],
    lineHeight: base.lineHeights.title,
    letterSpacing: base.letterSpacings.tight,
    color: colors.primary,
  },

  progress: {
    fontSize: base.fontSizes.progressBar,
    fontWeight: base.fontWeights[2],
    color: colors.primary,
    marginBottom: spaces[4],
    marginTop: spaces[5],
  },

  normal: {
    fontSize: base.fontSizes.normal,
    fontWeight: base.fontWeights[0],
    marginBottom: spaces[3],
    marginTop: spaces[3],
    color: colors.darkGrey,
  },

  tabs: {
    fontSize: base.fontSizes.normal,
    fontWeight: base.fontWeights[0],
    color: colors.darkGrey,
  },

  small: {
    fontSize: base.fontSizes.small,
    fontWeight: base.fontWeights[0],
    marginBottom: spaces[1],
    marginTop: spaces[1],
    color: colors.darkGrey,
  },

  input: {
    fontSize: base.fontSizes.normal,
    fontWeight: base.fontWeights[0],
    letterSpacing: base.letterSpacings.tight,
    lineHeight: base.lineHeights.title,
    color: colors.darkGrey,
  },

  label: {
    fontSize: base.fontSizes.small,
    fontWeight: base.fontWeights[0],
    lineHeight: 2,
    letterSpacing: base.letterSpacings.tight,
    color: colors.darkGrey,
  },

  tiny: {
    fontSize: base.fontSizes.tiny,
    letterSpacing: base.letterSpacings.tiny,
    color: colors.darkGrey,
  },
  accordionHeading: {
    fontSize: base.fontSizes.paragraph,
    fontWeight: base.fontWeights[2],
    color: colors.darkGrey,
  },
};

export default textStyles;
