const JpgSmall = ({ ...props }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg xmlns="http://www.w3.org/2000/svg" id="JPG-small" width="30" height="34.583" viewBox="0 0 30 34.583" {...props}>
    <g id="file">
      <g id="Pfad_648" fill="#f8f8f8">
        <path
          d="M24.359 34.083H1.804c-.719 0-1.304-.578-1.304-1.288V1.789C.5 1.079 1.085.5 1.804.5h15.402c2.752 2.764 7.575 7.608 8.457 8.497v23.798c0 .71-.585 1.288-1.304 1.288z"
          className="cls-5"
        />
        <path
          d="M1.804 1C1.361 1 1 1.354 1 1.789v31.006c0 .435.36.788.804.788H24.36c.443 0 .804-.353.804-.788V9.203C24.014 8.047 19.64 3.653 16.998 1H1.804m0-1h15.61s8.718 8.755 8.75 8.791v24.004c0 .988-.809 1.788-1.805 1.788H1.804c-.996 0-1.804-.8-1.804-1.788V1.789C0 .8.808 0 1.804 0z"
          className="cls-6"
        />
      </g>
      <g id="Differenzmenge_5" fill="#d5d5d5">
        <path
          d="M7.878 8.483H2c-.827 0-1.5-.673-1.5-1.5V1.104l7.378 7.379z"
          className="cls-5"
          transform="translate(17.367 .102)"
        />
        <path
          d="M6.671 7.983L1 2.312v4.671c0 .551.449 1 1 1H6.67m2.013 1H2c-1.103 0-2-.897-2-2V0h.103l8.581 8.582v.4z"
          className="cls-6"
          transform="translate(17.367 .102)"
        />
      </g>
    </g>
    <g id="type" transform="translate(6.08 17.136)">
      <rect id="Rechteck_481" width="24" height="11" fill="#eaa243" rx="2" transform="translate(-.08 -.136)" />
      <path
        id="Pfad_644"
        fill="#fff"
        d="M1.317-4.365a1.913 1.913 0 0 1-.882-.205 1.739 1.739 0 0 1-.659-.584l.608-.836a1.075 1.075 0 0 0 .879.6q.669 0 .669-.9v-2.579H.269v-1.006H3.02v3.513a2.234 2.234 0 0 1-.433 1.5 1.59 1.59 0 0 1-1.27.497zm4.833-5.51a2.343 2.343 0 0 1 1.092.24 1.7 1.7 0 0 1 .713.681A2.084 2.084 0 0 1 8.2-7.91a2.089 2.089 0 0 1-.25 1.041 1.668 1.668 0 0 1-.713.681 2.375 2.375 0 0 1-1.092.236H5.2v1.494H4.1v-5.417zm-.061 2.9a1.05 1.05 0 0 0 .75-.244.909.909 0 0 0 .261-.691.92.92 0 0 0-.257-.7 1.05 1.05 0 0 0-.75-.244H5.2v1.88zm6.157-.279h1v2.2a2.737 2.737 0 0 1-.892.511 3.062 3.062 0 0 1-1.021.178 2.487 2.487 0 0 1-1.325-.36 2.509 2.509 0 0 1-.923-1 3.062 3.062 0 0 1-.335-1.443 3.062 3.062 0 0 1 .336-1.442 2.493 2.493 0 0 1 .929-1 2.534 2.534 0 0 1 1.338-.36 2.606 2.606 0 0 1 1.129.24 2.272 2.272 0 0 1 .852.7l-.7.743a1.539 1.539 0 0 0-1.226-.613 1.5 1.5 0 0 0-.8.217 1.465 1.465 0 0 0-.547.611 2.009 2.009 0 0 0-.2.905 1.991 1.991 0 0 0 .2.9 1.5 1.5 0 0 0 .544.615 1.452 1.452 0 0 0 .794.221 1.6 1.6 0 0 0 .852-.232z"
        transform="translate(5.751 12.732)"
      />
    </g>
  </svg>
);

export default JpgSmall;
