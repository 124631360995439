export default {
  nav: [
    {
      title: 'Immobiliensuche',
      url: '/immobiliensuche',
      type: 'nav',
      image: '/assets/menu/immobiliensuche.svg',
      subnav: [
        {
          title: 'Immobiliensuche',
          url: '/immobiliensuche',
          text: 'Deutschlandweit Immobilien suchen und finden.',
          loginRequired: false,
        },
        {
          title: 'Gespeicherte Suchen',
          url: '/mein-bereich',
          text: 'Per E-Mail bequem über neue Angebote informieren lassen.',
          loginRequired: true,
        },
        {
          title: 'Merkzettel',
          url: '/mein-bereich/merkzettel',
          text: 'Interessante Objekte merken und jederzeit wieder einsehen.',
          loginRequired: true,
        },
      ],
    },
    {
      title: 'Finanzierung',
      url: '/finanzierung',
      type: 'nav',
      image: '/assets/menu/finanzierung.svg',
      subnav: [
        {
          title: 'Baufinanzierungsrechner',
          url: '/finanzierung/baurechner',
          text: 'In wenigen Klicks die passende Baufinanzierung berechnen und anfragen.',
          loginRequired: false,
        },
        {
          title: 'Haushaltsrechner',
          url: '/finanzierung/haushaltsrechner?abschnitt=formular',
          text: 'Einfach ermitteln, welche Finanzierungssumme zu Ihrer Situation passt. ',
          loginRequired: false,
        },
        {
          title: 'Käufer-Zertifikat',
          url: '/finanzierung/finanzierungszertifikat?abschnitt=formular',
          text: 'Mit einer Finanzierungszusage schon heute beim Makler punkten.',
          loginRequired: false,
        },
      ],
    },
    {
      title: 'Tarifcheck',
      url: '/keinLink',
      type: 'nav',
      image: '/assets/menu/tarifcheck.svg',
      subnav: [
        {
          title: 'Strompreisvergleich',
          url: '/mein-bereich/tarifrechner/strom',
          text: 'Bis zu 12.000 Stromtarife vergleichen und kostenlos online wechseln und sparen.',
          loginRequired: true,
        },
        {
          title: 'Gaspreisvergleich',
          url: '/mein-bereich/tarifrechner/gas',
          text: 'Bis zu 7.000 Gastarife vergleichen und kostenlos online wechseln und sparen.',
          loginRequired: true,
        },
        {
          title: 'DSL-Preisvergleich',
          url: '/mein-bereich/tarifrechner/dsl',
          text: 'Einfach & kostenlos Tarife vergleichen – mit Best-Preis-Garantie.',
          loginRequired: true,
        },
      ],
    },
    {
      title: 'Mein Bereich',
      url: '/mein-bereich',
      loginRequired: true,
      type: 'myPlace',
      subnav: [
        {
          title: 'Meine Anfragen',
          url: '/mein-bereich/anfragen',
          loginRequired: true,
        },
        {
          title: 'Merkzettel',
          url: '/mein-bereich/merkzettel',
          loginRequired: true,
        },
        {
          title: 'Gespeicherte Suchen',
          loginRequired: true,
          url: '/mein-bereich',
        },
        {
          title: 'Dokumentencenter',
          url: '/mein-bereich/dokumenten-center',
          loginRequired: true,
        },
        {
          title: 'Profil-Einstellungen',
          url: '/mein-bereich/profil-einstellungen',
          loginRequired: true,
        },
      ],
    },
  ],
  additionLinkList: [
    {
      title: 'Hilfe und Kontakt',
      url: '/hilfe',
    },
  ],
  loginLogout: {
    logout: 'Abmelden',
    registered: {
      title: 'Willkommen zurück!',
      icon: '',
      url: '/mein-bereich/profil-einstellungen',
    },
    notRegistered: {
      title: 'Willkommen!',
      button: 'Anmelden / Registrieren',
    },
  },
  loginRegisterDialog: {
    title: 'Willkommen',
    subtitle: 'Anmelden und immer den Überblick über wichtige Ereignisse rund um Ihr Eigenheim behalten.',
    list: [
      'Anfragen einfach online stellen',
      'Ganz bequem Ihre Traumimmobilie finden',
      'Schnell und direkt Kontakt zu Experten aufnehmen',
    ],
    registerButton: 'Kostenlos registrieren',
    loginButton: 'Anmelden',
  },
};
