import { isSSR } from '@/utils';

const validate = (label, path, fallback) => {
  return path ?? fallback;
};

export default {
  public: process.env.PUBLIC_URL,
  backend: validate('backend', process.env.NEXT_PUBLIC_BACKEND),
  cms: validate('backend', process.env.NEXT_PUBLIC_CMS),
  defaultPortal: validate('Default Portal', process.env.NEXT_PUBLIC_DEFAULT_PORTAL, 'default'),
  defaultLang: validate('Default Language', process.env.NEXT_PUBLIC_DEFAULT_LANG, 'de'),
  // eslint-disable-next-line no-nested-ternary
  portal: isSSR ? undefined : window?.location?.hostname,
};
