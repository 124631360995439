const Frown = ({ ...props }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg xmlns="http://www.w3.org/2000/svg" width="20.342" height="20.342" viewBox="0 0 20.342 20.342" {...props}>
    <path
      d="M14.171 4a10.171 10.171 0 1 0 10.171 10.171A10.182 10.182 0 0 0 14.171 4zm0 1.7A8.476 8.476 0 1 1 5.7 14.171 8.462 8.462 0 0 1 14.171 5.7zm-3.814 5.085a1.271 1.271 0 1 0 1.271 1.271 1.272 1.272 0 0 0-1.271-1.275zm7.628 0a1.271 1.271 0 1 0 1.271 1.271 1.272 1.272 0 0 0-1.271-1.275zm-3.814 5.085a6.764 6.764 0 0 0-5.483 2.83l1.377.98a5.037 5.037 0 0 1 8.211 0l1.377-.98a6.764 6.764 0 0 0-5.482-2.834z"
      transform="translate(-4 -4)"
      fill="#e2001a"
    />
  </svg>
);

export default Frown;
