import P from 'prop-types';

const radioGroup = P.arrayOf(
  P.shape({
    label: P.string.isRequired,
    value: P.string.isRequired,
  }).isRequired
).isRequired;

export default P.shape({
  title: P.string.isRequired,
  subtitle: P.string.isRequired,
  backgroundImage: P.string.isRequired,
  actionTitle: P.objectOf(P.string).isRequired,
  backButton: P.string.isRequired,
  sections: P.shape({
    property: P.shape({
      title: P.string.isRequired,
      kind: P.shape({
        label: P.string.isRequired,
        values: radioGroup,
      }),
      postcode: P.shape({
        label: P.string.isRequired,
        value: P.string.isRequired,
      }).isRequired,
      propertyUse: P.shape({
        label: P.string.isRequired,
        values: radioGroup,
      }).isRequired,
      endDateInterestRate: P.shape({
        label: P.string.isRequired,
        value: P.string.isRequired,
      }),
    }).isRequired,
    financingNeeds: P.shape({
      title: P.string.isRequired,
      buyingPrice: P.string.isRequired,
      equity: P.string.isRequired,
      employment: P.shape({
        label: P.string.isRequired,
        values: radioGroup,
      }).isRequired,
      nominalInterestRateFixationInYears: P.string.isRequired,
      repayment: P.shape({
        label: P.string.isRequired,
        values: radioGroup,
      }).isRequired,
      loanAmount: P.string.isRequired,
      monthlyRate: P.string.isRequired,
      boundInterestRate: P.string.isRequired,
      effectiveInterestRate: P.string.isRequired,
    }).isRequired,
  }).isRequired,
  acceptPrivacy: P.shape({
    preText: P.string.isRequired,
    linkText: P.string.isRequired,
    postText: P.string.isRequired,
    url: P.string.isRequired,
  }).isRequired,
  saveCondition: P.string.isRequired,
  submit: P.string.isRequired,
}).isRequired;
