import { AnimatePresence, motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import { Box, Flex } from 'rebass/styled-components';

import Feedback from '@/components/Feedback/Feedback';
import uuid from '@/utils/uuid';

const activeToasts = [];
const listeners = [];

const addListener = (fn) => {
  listeners.push(fn);
};

const removeListener = (fn) => {
  const index = listeners.indexOf(fn);
  if (index > -1) {
    listeners.splice(index, 1);
  }
};

const notify = () => {
  listeners.forEach((fn) => {
    fn([...activeToasts]);
  });
};

const removeToast = (id) => {
  const index = activeToasts.findIndex((item) => item.id === id);
  if (index > -1) {
    activeToasts.splice(index, 1);
  }

  notify();
};

// example call
// toast({
// 	type: 'info',
// 	message: 'Test',
// 	dismissable: true,
// 	withIcon: true,
// 	action: { title: 'REMmve', callback: () => alert('ACTION') },
// 	description:
// 		'Description',
// })

const toast = (
  { type, message, description, dismissable, withIcon, action } = {
    type: 'success',
    message: 'Not set',
    dismissable: false,
    withIcon: false,
  }
) => {
  const id = uuid();
  activeToasts.push({
    type,
    message,
    description,
    withIcon,
    id,
    action,
    onDismiss: dismissable ? removeToast : undefined,
  });

  setTimeout(() => {
    removeToast(id);
  }, Math.min(Math.max((message?.length + (description?.length ?? 0)) * 50, 2000), 7000));

  notify();
};

const Toaster = () => {
  const [toasts, setToasts] = useState(activeToasts);

  useEffect(() => {
    addListener(setToasts);

    return () => {
      removeListener(setToasts);
    };
  }, []);

  return (
    <Box
      sx={{
        position: 'fixed',
        width: '100vw',
        height: '100vh',
        top: '0px',
        left: '0px',
        zIndex: 999899999, // 5000,
        pointerEvents: 'none',
      }}
    >
      <Flex
        sx={{
          position: 'fixed',
          bottom: 0,
          right: 0,
          top: 0,
          m: '0px',
          width: '500px',
          maxWidth: '100vw',
          flexDirection: 'column',
          listStyle: 'none',
          justifyContent: 'flex-end',
          pointerEvents: 'none',
        }}
      >
        <AnimatePresence initial={false}>
          {toasts.map((currentToast) => (
            <motion.div
              key={currentToast.id}
              layout
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, transition: { duration: 0.7 } }}
            >
              <Box
                sx={{
                  m: '10px',
                  flex: '0 0 auto',
                  position: 'relative',
                  pointerEvents: 'auto',
                }}
              >
                <Feedback {...currentToast} />
              </Box>
            </motion.div>
          ))}
        </AnimatePresence>
      </Flex>
    </Box>
  );
};

export default Toaster;

export { toast, removeToast };
