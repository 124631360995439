import { saveAs } from 'file-saver';
import { useRouter } from 'next/router';
import { useMutation } from 'react-query';

import { financingApi } from '@/services';

const useDownloadFinancingOffer = () => {
  const router = useRouter();
  return useMutation(
    'downloadFinancingOffer',
    async (values = {}) => {
      const response = await financingApi.get('/generateFinancingOfferPdf', {
        params: {
          ...values,
          proceedingId: router.query.id,
        },
        responseType: 'arraybuffer',
      });

      return response;
    },
    {
      onSuccess: ({ data }) => {
        const blob = new Blob([data], { type: 'application/pdf;charset=utf-8' });
        saveAs(blob, `Finanzierungsvorschlag${router.query.id ? `-${router.query.id}` : ''}.pdf`);
      },
    }
  );
};

export default useDownloadFinancingOffer;
