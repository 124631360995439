import PropTypes from 'prop-types';

export default PropTypes.shape({
  logo: PropTypes.shape({
    altText: PropTypes.string.isRequired,
    src: PropTypes.string.isRequired,
  }).isRequired,
  nav: PropTypes.arrayOf(
    // no dropdown
    PropTypes.oneOfType([
      PropTypes.shape({
        title: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
      }),
      // with dropdown
      PropTypes.shape({
        title: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
        sub: PropTypes.arrayOf(
          PropTypes.shape({
            title: PropTypes.string.isRequired,
            url: PropTypes.string.isRequired,
          })
        ),
      }),
      // with icon and action
      PropTypes.shape({
        title: PropTypes.string.isRequired,
        icon: PropTypes.string.isRequired,
        iconPosition: PropTypes.oneOf(['left', 'right']).isRequired,
        action: PropTypes.oneOf(['login']),
      }),
    ])
  ).isRequired,
}).isRequired;
