import { Disclosure, DisclosureButton, DisclosurePanel } from '@reach/disclosure';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Box } from 'rebass';
import styled from 'styled-components';

import { ArrowIcon } from '../Icon';
import Text from '../Text';

const MyBox = styled(Box)``;
MyBox.defaultProps = {
  paddingTop: ['30px', '16px', '16px'],
  paddingBottom: ['14px', '16px', '16px'],
};

const DefaultBox = styled(Box)``;
DefaultBox.defaultProps = {
  paddingTop: ['24px', '16px', '16px'],
  paddingBottom: ['14px', '16px', '16px'],
};

const ExtendButton = styled(DisclosureButton)`
  background: none;
  border: none;
  outline: none;
  padding-top: 0;
  padding-left: 0;
  align-self: flex-start;
  align-items: center;
`;

ExtendButton.defaultProps = {
  display: ['block', 'flex', 'flex'],
};

const StyledDisclosurePanel = styled(DisclosurePanel)`
  outline: none;
`;

const isOpen = {
  marginLeft: '8px',
  marginBottom: '2px',
};

const isClosed = {
  transform: 'rotate(180deg)',
  marginLeft: '8px',
  marginBottom: '2px',
};

const ExtendedCard = ({
  isMobile,
  position,
  noArrow,
  variant,
  nopadding,
  textIsClosed,
  textIsOpen,
  children,
  ...props
}) => {
  const [open, setOpen] = useState(false);
  useEffect(() => {
    if ((position === 'outer' && !isMobile) || (position === 'inner' && isMobile === true)) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [isMobile, position]);

  return (
    <Disclosure {...props} open={open}>
      {!open ? (
        <MyBox style={isMobile || nopadding ? { paddingTop: 0, paddingBottom: 0 } : {}}>
          <ExtendButton>
            <Text variant={variant} onClick={() => setOpen(!open)} style={{ cursor: 'pointer' }}>
              {textIsClosed}
              {!noArrow ? <ArrowIcon style={isClosed} /> : null}
            </Text>
          </ExtendButton>
        </MyBox>
      ) : null}

      <StyledDisclosurePanel>{children}</StyledDisclosurePanel>
      {(open && isMobile && position === 'outer') ||
      (open && !isMobile && position === 'inner') ||
      (open && !isMobile && !position) ? (
        <DefaultBox style={nopadding ? { paddingTop: 0, paddingBottom: 0 } : {}}>
          <ExtendButton>
            <Text variant={variant} onClick={() => setOpen(!open)} style={{ cursor: 'pointer' }}>
              {textIsOpen}
              {!noArrow ? <ArrowIcon style={isOpen} /> : null}
            </Text>
          </ExtendButton>
        </DefaultBox>
      ) : null}
    </Disclosure>
  );
};

ExtendedCard.propTypes = {
  children: PropTypes.node.isRequired,
  isMobile: PropTypes.bool,
  noArrow: PropTypes.string,
  nopadding: PropTypes.bool,
  textIsClosed: PropTypes.string.isRequired,
  textIsOpen: PropTypes.string.isRequired,
  variant: PropTypes.string.isRequired,
  position: PropTypes.string.isRequired,
};

ExtendedCard.defaultProps = {
  isMobile: false,
  noArrow: undefined,
  nopadding: undefined,
};

/** @component */
export default ExtendedCard;
