import { format } from 'date-fns';
import Link from 'next/link';
import PropTypes from 'prop-types';
import { useMutation, useQueryClient } from 'react-query';
import { Box, Flex } from 'rebass/styled-components';
import styled from 'styled-components';

import { HeartIcon, RegCheckIcon, TargetIcon } from '@/components/Icon';
import HomeActiveIcon from '@/components/Icon/HomeActiveIcon';
import PersonIcon from '@/components/Icon/PersonIcon';
import Markdown from '@/components/Markdown/Markdown';
import Text from '@/components/Text';
import { toast } from '@/components/Toaster/Toaster';
import { useQueryNotificationRequests } from '@/hooks';
import { userApi } from '@/services';

const MyFlex = styled(Flex)`
  cursor: pointer;
`;

const MyInnerFlex = styled(Flex)`
  &:hover {
    background: #fafafa;
    border-radius: 0.625rem;
  }
`;

const NotificationItem = ({ item, setOpenNc }) => {
  const queryClient = useQueryClient();
  const { data } = useQueryNotificationRequests();

  const NotificationIcon = () => {
    if (item?.icon === 'heart') return <HeartIcon />;
    if (item?.icon === 'target') return <TargetIcon />;
    if (item?.icon === 'success') return <RegCheckIcon />;
    if (item?.icon === 'person') return <PersonIcon />;
    if (item?.icon === 'home') return <HomeActiveIcon />;
    return <RegCheckIcon />;
  };

  const fetchNotificationRead = async (id) => {
    await userApi.put(`/notification/${id}/read/true`, {});
    return id;
  };

  const { mutateAsync: readNotification } = useMutation(fetchNotificationRead, {
    onMutate: async () => {
      queryClient.setQueryData(['notification', { id: item.id }], data);
    },
    onSuccess: () => {
      queryClient.invalidateQueries('notifications');
      toast({
        type: 'success',
        message: 'Mitteilung gelesen',
        dismissable: true,
        withIcon: true,
      });
    },
  });

  const handleReadClick = async () => {
    setOpenNc(false);
    if (item?.read === false) await readNotification(item.id);
  };

  return (
    <MyFlex
      alignItems="flex-start"
      backgroundColor={item?.read === true ? '#fafafa' : '#ffffff'}
      flexDirection="row"
      onClick={handleReadClick}
      px={['0.625rem', '0.625rem', '0.625rem']}
      py={['0.625rem', '0.625rem', '0.625rem']}
    >
      <MyInnerFlex
        px={['0.625rem', '0.625rem', '0.625rem']}
        py={['0.625rem', '0.625rem', '0.625rem']}
        flexDirection="row"
        width={['100%', '100%', '100%']}
      >
        {item?.link ? (
          <Link href={item?.link}>
            <a title={item?.title} style={{ display: 'flex' }}>
              <Box minWidth="2rem" mr={['1rem', '1rem', '1rem']} sx={{ position: 'relative' }}>
                {item?.read === false ? (
                  <Box
                    height="8px"
                    width="8px"
                    sx={{ borderRadius: '50%', position: 'absolute', right: '-1px', top: '0' }}
                    backgroundColor="red"
                  />
                ) : null}
                <NotificationIcon />
              </Box>
              <Flex flexDirection="column">
                <Text variant="notificationTitle">{item?.title}</Text>
                <Markdown mb={['0.125rem', '0.125rem', '0.125rem']} variant="smallEstate">
                  {item?.message}
                </Markdown>
                <Text variant="smallLight"> {format(new Date(item?.create_at), 'dd.MM.yyyy HH:mm')} Uhr</Text>
              </Flex>
            </a>
          </Link>
        ) : (
          <>
            <Box minWidth="2rem" mr={['1rem', '1rem', '1rem']} sx={{ position: 'relative' }}>
              {item?.read === false ? (
                <Box
                  height="8px"
                  width="8px"
                  sx={{ borderRadius: '50%', position: 'absolute', right: '-1px', top: '0' }}
                  backgroundColor="red"
                />
              ) : null}
              <NotificationIcon />
            </Box>
            <Flex flexDirection="column">
              <Text variant="notificationTitle">{item?.title}</Text>
              <Markdown mb={['0.125rem', '0.125rem', '0.125rem']} variant="smallEstate">
                {item?.message}
              </Markdown>
              <Text variant="smallLight"> {format(new Date(item?.create_at), 'dd.MM.yyyy HH:mm')} Uhr</Text>
            </Flex>
          </>
        )}
      </MyInnerFlex>
    </MyFlex>
  );
};

NotificationItem.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    read: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    create_at: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
  }).isRequired,
  setOpenNc: PropTypes.func.isRequired,
};

export default NotificationItem;
