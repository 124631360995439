const Three = ({ ...props }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg xmlns="http://www.w3.org/2000/svg" width="6.06" height="8.172" viewBox="0 0 6.06 8.172" {...props}>
    <path
      data-name="3"
      d="M3.564.12a3.876 3.876 0 0 1-2.022-.5A2.558 2.558 0 0 1 .4-1.812L1.956-2.5a1.9 1.9 0 0 0 .276.714 1.193 1.193 0 0 0 .522.426 1.956 1.956 0 0 0 .786.144 1.454 1.454 0 0 0 .69-.15 1.107 1.107 0 0 0 .438-.408 1.148 1.148 0 0 0 .156-.606 1.061 1.061 0 0 0-.144-.576.813.813 0 0 0-.414-.33 1.978 1.978 0 0 0-.69-.1q-.156 0-.294.006t-.306.03v-1.21q.1.012.2.018t.21.006a1.408 1.408 0 0 0 .966-.27 1.131 1.131 0 0 0 .294-.87.983.983 0 0 0-.294-.762 1.189 1.189 0 0 0-.834-.27 1.374 1.374 0 0 0-.912.282 1.524 1.524 0 0 0-.468.858L.66-6.12a2.208 2.208 0 0 1 .558-1.032 2.764 2.764 0 0 1 1.026-.666A3.769 3.769 0 0 1 3.6-8.052a3.514 3.514 0 0 1 1.482.276 2.063 2.063 0 0 1 .9.756 2.009 2.009 0 0 1 .306 1.1 2.073 2.073 0 0 1-.15.834 1.86 1.86 0 0 1-.384.576 1.769 1.769 0 0 1-.486.354 1.481 1.481 0 0 1-.468.144v.036a1.938 1.938 0 0 1 .564.114 1.745 1.745 0 0 1 .534.306 1.557 1.557 0 0 1 .4.522 1.685 1.685 0 0 1 .156.75 2.169 2.169 0 0 1-.378 1.266 2.447 2.447 0 0 1-1.026.84A3.567 3.567 0 0 1 3.564.12z"
      transform="translate(-.396 8.052)"
      fill="#ffffff"
    />
  </svg>
);

export default Three;
