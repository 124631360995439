import { themeGet } from '@styled-system/theme-get';
import React from 'react';
import { Box, Flex } from 'rebass/styled-components';
import styled from 'styled-components';

const colorLoader = (props) => themeGet(`colors.${props.color}`, props.color ?? 'white')(props);

const StyledLoader = styled(Box)`
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: ${colorLoader};
  color: ${colorLoader};
  animation: dot-flashing 0.6s infinite linear alternate;
  animation-delay: 0.3s;
  /* transform: translateX(15px); */

  &::before,
  &::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
  }

  &::before {
    left: -15px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: ${colorLoader};
    color: ${colorLoader};
    animation: dot-flashing 0.6s infinite alternate;
    animation-delay: 0s;
  }

  &::after {
    left: 15px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: ${colorLoader};
    color: ${colorLoader};
    animation: dot-flashing 0.6s infinite alternate;
    animation-delay: 0.6s;
  }

  @keyframes dot-flashing {
    0% {
      opacity: 1;
    }
    50%,
    100% {
      opacity: 0.1;
    }
  }
`;

const Loader = (props) => (
  <Flex width="90px" height="10px" justifyContent="center">
    <StyledLoader {...props} />
  </Flex>
);

Loader.defaultProps = {
  color: 'primary',
};

export default Loader;
