import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

import { LayoutBody, LayoutInnerBody, LayoutWrapper } from './components/LayoutComponents';
import LayoutFooter from './components/LayoutFooter';
import LayoutHeader from './components/LayoutHeader';

const Layout = ({
  children,
  withFooter,
  withHeader,
  // backgroundColor,
  headerContent,
  footerContent,
  activePage,
}) => {
  const [isMobileMenu, setIsMobileMenu] = useState(false);
  const handleResize = () => {
    if (window.innerWidth < 1024) {
      setIsMobileMenu(true);
    } else {
      setIsMobileMenu(false);
    }
  };
  useEffect(() => {
    window.addEventListener('resize', handleResize);
  });

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const isMobileMenuOpen = (isOpen) => {
    setMobileMenuOpen(isOpen);
    if (window.innerWidth < 1024) {
      setIsMobileMenu(true);
    } else {
      setIsMobileMenu(false);
    }
  };
  return (
    <LayoutWrapper
      withHeader
      style={
        mobileMenuOpen && isMobileMenu
          ? { width: '100%', maxHeight: '100vh', overflowY: 'hidden', position: 'fixed' }
          : { width: '100%', maxHeight: 'auto', overflowY: 'visible', position: 'initial' }
      }
    >
      {withHeader && (
        <LayoutHeader
          isMobileMenuOpen={isMobileMenuOpen}
          setMobileMenuOpen={setMobileMenuOpen}
          content={headerContent}
          activePage={activePage}
        />
      )}
      <LayoutBody {...{}}>
        <LayoutInnerBody bg="#F7F7F7">{children}</LayoutInnerBody>
      </LayoutBody>
      {withFooter && <LayoutFooter content={footerContent} />}
    </LayoutWrapper>
  );
};

Layout.propTypes = {
  subHeader: PropTypes.element,
  withHeader: PropTypes.bool,
  withFooter: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.func, PropTypes.arrayOf(PropTypes.element)]),
  // backgroundColor: PropTypes.string,
  hasMainNavigation: PropTypes.bool,
  currentPathname: PropTypes.string,

  // non styling props
  activePage: PropTypes.string.isRequired,
  headerContent: PropTypes.shape({
    logo: PropTypes.shape({
      altText: PropTypes.string.isRequired,
      src: PropTypes.string.isRequired,
    }).isRequired,
    nav: PropTypes.arrayOf(
      // no dropdown
      PropTypes.oneOfType([
        PropTypes.shape({
          title: PropTypes.string.isRequired,
          url: PropTypes.string.isRequired,
        }),
        // with dropdown
        PropTypes.shape({
          title: PropTypes.string.isRequired,
          url: PropTypes.string.isRequired,
          sub: PropTypes.arrayOf(
            PropTypes.shape({
              title: PropTypes.string.isRequired,
              url: PropTypes.string.isRequired,
            })
          ),
        }),
        // with icon and action
        PropTypes.shape({
          title: PropTypes.string.isRequired,
          icon: PropTypes.string.isRequired,
          iconPosition: PropTypes.oneOf(['left', 'right']).isRequired,
          action: PropTypes.oneOf(['login']),
        }),
      ])
    ).isRequired,
  }).isRequired,

  footerContent: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      url: PropTypes.string,
    }).isRequired
  ).isRequired,
};

Layout.defaultProps = {
  withFooter: true,
  withHeader: true,
  subHeader: null,
  children: null,
  // backgroundColor: COLORS.white,
  hasMainNavigation: true,
  currentPathname: '',
};
export default Layout;
