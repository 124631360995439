import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { Button } from 'rebass/styled-components';
import styled from 'styled-components';

const ResettedButton = styled(Button)`
  padding: 0;
  border: none;
  font: inherit;
  color: inherit;
  background-color: transparent;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: transparent !important;
  }

  &:focus {
    outline-style: solid;
    outline-color: transparent;
    box-shadow: 0 0 0 1px black;
  }
`;

const UnstyledButton = forwardRef(({ type, ...props }, ref) => {
  return <ResettedButton type="button" ref={ref} {...props} />;
});

UnstyledButton.propTypes = {
  type: PropTypes.string,
};

UnstyledButton.defaultProps = {
  type: 'button',
};
/** @component */
export default UnstyledButton;
