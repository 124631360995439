const DeletedObjectIcon = ({ ...rest }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="37.465"
    height="35.223"
    viewBox="0 0 37.465 35.223"
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...rest}
  >
    <g>
      <path
        fill="#d1d1d1"
        d="M19.667 2.594l-.911.871L2.281 19.94 4.1 21.762l1.624-1.624v14.653h11.408V22.118H22.2v12.673h11.407V20.138l1.624 1.624 1.822-1.822L20.578 3.465zm0 3.6L31.073 17.6v14.656h-6.337V19.583H14.6v12.673H8.261V17.6z"
        transform="translate(-945.806 -729.833) translate(946.219 727.239)"
      />
      <path
        fill="none"
        stroke="#d1d1d1"
        strokeWidth="2px"
        d="M0 32L33.228 0"
        transform="translate(-945.806 -729.833) translate(946.5 732.336)"
      />
    </g>
  </svg>
);

export default DeletedObjectIcon;
