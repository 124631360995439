// TODO: uuid() is only for generating new random id and then send it to backend.
//  DO NOT USE THIS FOR MAPPING KEY PROPS! Unless you know exactly what are you doing
//  Because the ID will be changed again and it will display a wrong data

/*
 * Dangerous
 * @example: {data.map(v => <Component key={uuid()} data={v} />)}
 * @example: {data.map((v, i) => <Component key={i} data={v} />)}
 *
 * Better
 * @example: {data.map((v) => <Component key={v.id} data={v} />)}
 */

export default function uuid() {
  let dt = new Date().getTime();
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    // eslint-disable-next-line no-bitwise
    const r = (dt + Math.random() * 16) % 16 | 0;
    dt = Math.floor(dt / 16);
    // eslint-disable-next-line no-bitwise
    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
  });
}
