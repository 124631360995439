const HomeActiveIcon = ({ ...props }) => (
  <svg
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
  >
    <circle cx="18" cy="18" r="18" fill="white" />
    <g clipPath="url(#clip0_2_17469)">
      <g clipPath="url(#clip1_2_17469)">
        <g clipPath="url(#clip2_2_17469)">
          <g clipPath="url(#clip3_2_17469)">
            <g clipPath="url(#clip4_2_17469)">
              <g clipPath="url(#clip5_2_17469)">
                <g clipPath="url(#clip6_2_17469)">
                  <rect
                    x="36"
                    y="36"
                    width="36"
                    height="36"
                    rx="18"
                    transform="rotate(180 36 36)"
                    fill="#125A41"
                    fillOpacity="0.101961"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M17.9989 9.99231L17.571 10.4016L9.83093 18.1417L10.6868 18.9975L11.4496 18.2347L11.4496 25.1189H16.8081V19.165H19.1897L19.1897 25.1189L24.5482 25.1189L24.5482 18.2347L25.311 18.9975L26.1669 18.1417L18.4268 10.4016L17.9989 9.99231ZM17.9989 11.6854L23.3574 17.0439L23.3574 23.9281L20.3804 23.9281L20.3804 17.9742L15.6173 17.9742L15.6173 23.9281H12.6404L12.6404 17.0439L17.9989 11.6854Z"
                    fill="#125A41"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
    <defs>
      <clipPath id="clip0_2_17469">
        <rect width="36" height="36" fill="white" />
      </clipPath>
      <clipPath id="clip1_2_17469">
        <rect width="36" height="36" fill="white" transform="translate(36 36) rotate(180)" />
      </clipPath>
      <clipPath id="clip2_2_17469">
        <rect width="36" height="36" fill="white" />
      </clipPath>
      <clipPath id="clip3_2_17469">
        <rect width="36" height="36" fill="white" transform="translate(36 36) rotate(180)" />
      </clipPath>
      <clipPath id="clip4_2_17469">
        <rect width="36" height="36" fill="white" />
      </clipPath>
      <clipPath id="clip5_2_17469">
        <rect width="36" height="36" fill="white" transform="translate(36 36) rotate(180)" />
      </clipPath>
      <clipPath id="clip6_2_17469">
        <rect width="36" height="36" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default HomeActiveIcon;
