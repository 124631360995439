import React from 'react';

const HeartSolidFillIcon = ({ width = '16px', height = '14px', ...props }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg width={width} height={height} viewBox="0 0 16 14" {...props}>
    <g fill="#e2001a">
      <path
        d="M10 18.287L3.762 11.94C3.325 11.5 2.5 10.396 2.5 9.36c0-2.128 1.698-3.86 3.786-3.86 1.478 0 2.75.958 3.377 1.529l.337.306.337-.306c.627-.571 1.9-1.529 3.377-1.529 2.088 0 3.786 1.732 3.786 3.86 0 1.035-.827 2.142-1.264 2.582L10 18.287z"
        transform="translate(-2 -5)"
      />
      <path
        d="M6.286 6C4.474 6 3 7.507 3 9.36c0 .905.805 1.914 1.115 2.226l.002.002.002.002L10 17.573l5.881-5.983C16.192 11.276 17 10.265 17 9.36 17 7.507 15.526 6 13.714 6c-1.31 0-2.467.876-3.04 1.399L10 8.012l-.673-.613C8.753 6.876 7.595 6 6.286 6m0-1C7.938 5 9.33 6.049 10 6.66 10.67 6.048 12.062 5 13.714 5 16.078 5 18 6.956 18 9.36c0 1.506-1.351 2.876-1.41 2.934L10 19l-6.594-6.709C3.35 12.236 2 10.866 2 9.361 2 6.955 3.922 5 6.286 5z"
        transform="translate(-2 -5)"
      />
    </g>
  </svg>
);

export default HeartSolidFillIcon;
