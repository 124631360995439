const PngIcon = ({ ...props }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg xmlns="http://www.w3.org/2000/svg" id="JPEG-small" width="30" height="34.583" viewBox="0 0 30 34.583" {...props}>
    <defs>
      <style>{'.cls-5,.cls-6{stroke:none}.cls-6{fill:#707070}'}</style>
    </defs>
    <g id="file">
      <g id="Pfad_648" fill="#f8f8f8">
        <path
          d="M24.359 34.083H1.804c-.719 0-1.304-.578-1.304-1.288V1.789C.5 1.079 1.085.5 1.804.5h15.402c2.752 2.764 7.575 7.608 8.457 8.497v23.798c0 .71-.585 1.288-1.304 1.288z"
          className="cls-5"
        />
        <path
          d="M1.804 1C1.361 1 1 1.354 1 1.789v31.006c0 .435.36.788.804.788H24.36c.443 0 .804-.353.804-.788V9.203C24.014 8.047 19.64 3.653 16.998 1H1.804m0-1h15.61s8.718 8.755 8.75 8.791v24.004c0 .988-.809 1.788-1.805 1.788H1.804c-.996 0-1.804-.8-1.804-1.788V1.789C0 .8.808 0 1.804 0z"
          className="cls-6"
        />
      </g>
      <g id="Differenzmenge_5" fill="#d5d5d5">
        <path
          d="M7.878 8.483H2c-.827 0-1.5-.673-1.5-1.5V1.104l7.378 7.379z"
          className="cls-5"
          transform="translate(17.367 .102)"
        />
        <path
          d="M6.671 7.983L1 2.312v4.671c0 .551.449 1 1 1H6.67m2.013 1H2c-1.103 0-2-.897-2-2V0h.103l8.581 8.582v.4z"
          className="cls-6"
          transform="translate(17.367 .102)"
        />
      </g>
    </g>
    <g id="type" transform="translate(6.08 17.136)">
      <rect id="Rechteck_481" width="24" height="11" fill="#689dc9" rx="2" transform="translate(-.08 -.136)" />
      <path
        id="Pfad_649"
        fill="#fff"
        d="M3.232-9.875a2.388 2.388 0 0 1 1.1.24 1.708 1.708 0 0 1 .721.681 2.066 2.066 0 0 1 .255 1.044 2.072 2.072 0 0 1-.253 1.041 1.678 1.678 0 0 1-.721.681 2.421 2.421 0 0 1-1.1.236h-.965v1.494H1.162v-5.417zm-.061 2.9a1.068 1.068 0 0 0 .758-.244.9.9 0 0 0 .26-.693.914.914 0 0 0-.26-.7 1.068 1.068 0 0 0-.758-.244h-.9v1.88zm7.309-2.9v5.417h-.91L7.186-7.747v3.289H6.094v-5.417h.915l2.377 3.289v-3.289zm4.365 2.623h1.011v2.2a2.773 2.773 0 0 1-.9.511 3.124 3.124 0 0 1-1.031.178 2.534 2.534 0 0 1-1.339-.36 2.519 2.519 0 0 1-.932-1 3.036 3.036 0 0 1-.338-1.443 3.036 3.036 0 0 1 .334-1.444 2.5 2.5 0 0 1 .939-1 2.582 2.582 0 0 1 1.352-.36 2.657 2.657 0 0 1 1.141.24 2.291 2.291 0 0 1 .861.7l-.71.743A1.561 1.561 0 0 0 14-8.9a1.529 1.529 0 0 0-.813.217 1.47 1.47 0 0 0-.553.611 1.992 1.992 0 0 0-.2.905 1.974 1.974 0 0 0 .2.9 1.5 1.5 0 0 0 .55.615 1.479 1.479 0 0 0 .8.221 1.626 1.626 0 0 0 .861-.232z"
        transform="translate(3.758 12.832)"
      />
    </g>
  </svg>
);

export default PngIcon;
