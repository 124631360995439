import P from 'prop-types';

const FileDownloadIcon = ({ fill, ...props }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg xmlns="http://www.w3.org/2000/svg" width="9" height="12" viewBox="0 0 9 12" {...props}>
    <path
      d="M11 4v8.281l-2.641-2.64-.719.719 3.5 3.5.359.344.359-.344 3.5-3.5-.719-.719L12 12.281V4zM7 15v1h9v-1z"
      transform="translate(-7 -4)"
      fill={fill}
    />
  </svg>
);

FileDownloadIcon.propTypes = {
  fill: P.string.isRequired,
};

export default FileDownloadIcon;
