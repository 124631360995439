const PlaneDepartureIcon = ({ ...props }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg xmlns="http://www.w3.org/2000/svg" width="15" height="13.086" viewBox="0 0 15 13.086" {...props}>
    <path
      fill="#e2001a"
      d="M15.525 4.776a1.64 1.64 0 0 0-.634.194l-2.658 1.408L8.642 5.5l-.211-.053-.211.106L7 6.255l-.722.422.669.511L8.484 8.35 6.565 9.371l-2.06-.933-.246-.106-.246.123-.986.528-.651.352.493.528 3.046 3.274.282.334.387-.211 2.9-1.549-.581 3.2-.194 1.021.968-.4 1.444-.616.211-.088.088-.211 2.098-5.087 2.957-1.584a1.707 1.707 0 0 0 .7-2.289 1.683 1.683 0 0 0-1-.81 1.757 1.757 0 0 0-.65-.071zm.106 1.124a.621.621 0 0 1 .211.018.552.552 0 0 1 .106 1.021L12.8 8.649l-.176.088-.07.194-2.095 5.035-.246.106.6-3.327.206-1.145-1.019.546-3.558 1.919-2.236-2.43.088-.053 2.06.915.264.123.229-.141 8.573-4.523a.5.5 0 0 1 .211-.056zm-7.059.739l2.165.528-1.162.6L8.308 6.8zM2.727 16.73v1.127h14.647V16.73z"
      transform="translate(-2.375 -4.77)"
    />
  </svg>
);

export default PlaneDepartureIcon;
