const Two = ({ ...props }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg xmlns="http://www.w3.org/2000/svg" width="6.06" height="8.04" viewBox="0 0 5.964 8.04" {...props}>
    <path
      data-name="2"
      d="M.78 0v-1.128q1.08-.72 1.83-1.314a10.141 10.141 0 0 0 1.212-1.1 3.93 3.93 0 0 0 .672-.966 2.164 2.164 0 0 0 .21-.912 1.263 1.263 0 0 0-.336-.906 1.244 1.244 0 0 0-.948-.354 1.289 1.289 0 0 0-1.05.456 2.128 2.128 0 0 0-.414 1.3L.528-5.472a2.841 2.841 0 0 1 .438-1.386A2.459 2.459 0 0 1 2-7.734a3.546 3.546 0 0 1 1.516-.306 3.507 3.507 0 0 1 1.254.2 2.556 2.556 0 0 1 .894.558 2.252 2.252 0 0 1 .534.81 2.637 2.637 0 0 1 .174.948 2.957 2.957 0 0 1-.378 1.452 5.086 5.086 0 0 1-1.158 1.36A15.875 15.875 0 0 1 2.856-1.3v.012q.18-.012.438-.024t.534-.018q.276-.006.48-.006h2.184V0z"
      transform="translate(-.528 8.04)"
      fill="#ffffff"
    />
  </svg>
);

export default Two;
