const ChecklistSoft = ({ ...props }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg xmlns="http://www.w3.org/2000/svg" width="13.637" height="10.136" viewBox="0 0 13.637 10.136" {...props}>
    <path
      fill="#a09e9e"
      stroke="#a09e9e"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="0.5px"
      d="M14.73 6.281l-8.274 8.274-3.486-3.486-.688.688 3.83 3.83.344.329.344-.329 8.618-8.617z"
      transform="translate(-2.031 -6.031)"
    />
  </svg>
);

export default ChecklistSoft;
