import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';

const CheckMarkBoldIcon = ({ width = '14.137', height = '10.636', color, ...props }) => {
  const theme = useContext(ThemeContext);

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <svg width={width} height={height} viewBox="0 0 14.137 10.636" {...props}>
      <path
        d="M14.73 6.281l-8.274 8.274-3.486-3.486-.688.688 3.83 3.83.344.329.344-.329 8.618-8.617z"
        transform="translate(-1.781 -5.781)"
        stroke={color ?? '#333333'}
        strokeLinecap="round"
        strokeLinejoin="round"
        fill={color ?? theme.colors.primary}
      />
    </svg>
  );
};

CheckMarkBoldIcon.propTypes = {
  color: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
};

CheckMarkBoldIcon.defaultProps = {
  color: undefined,
  height: undefined,
  width: undefined,
};

export default CheckMarkBoldIcon;
