const ArrowIcon = ({ ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="10"
    height="5.6"
    viewBox="0 0 10 5.6"
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
  >
    <path
      fill="#e2001a"
      d="M4.987 10L.293 5.307 0 5l.293-.307L4.987 0 5.6.613 1.213 5 5.6 9.387z"
      transform="rotate(90 5 5)"
    />
  </svg>
);

export default ArrowIcon;
