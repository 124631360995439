import { useDebounce } from '@react-hook/debounce';
import * as React from 'react';
import { useQuery } from 'react-query';

import { realEstateApi } from '@/services';

const mapArrayObj = (array) => {
  return array?.map((d) => {
    return {
      label: d.displayCode,
      value: `${d.displayCode}#${d.latitude}#${d.longitude}`,
    };
  });
};

const mapEstateDataAutocomplete = (data, { city, district, street, zipcode }) => {
  const mappedLabel = { PLZ: zipcode, Straße: street, Stadt: city, Stadtteil: district };

  return Object.entries(data)
    .reduce((acc, [key, value]) => {
      const obj = {
        label: mappedLabel[key],
        options: mapArrayObj(value),
      };
      return [...acc, obj];
    }, [])
    .filter(Boolean);
};

const useEstateAutocomplete = (labels) => {
  const [search, setSearch] = useDebounce('', 500);

  const handleInputChange = React.useCallback(
    (city, { action }) => {
      if (action === 'input-change') {
        setSearch(city);
      }
    },
    [setSearch]
  );

  const query = useQuery(
    ['fetchOptions', search],
    async () => {
      const { data } = await realEstateApi({
        url: '/search/autocomplete',
        params: {
          pattern: search,
        },
      });

      return mapEstateDataAutocomplete(data, labels);
    },
    {
      enabled: search.length >= 3,
    }
  );

  return { handleInputChange, ...query };
};

export default useEstateAutocomplete;
