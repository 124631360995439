const MinusIcon = ({ ...rest }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="50.175"
    height="32.901"
    viewBox="0 0 50.175 32.901"
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...rest}
  >
    <defs>
      <style>
        {
          '.cls-1{fill:none;stroke:#e2001a;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-width:2px}'
        }
      </style>
    </defs>
    <g id="icon-2" transform="translate(-.5 -.5)">
      <g id="Gruppe_1486" transform="translate(1.5 1.5)">
        <path id="Linie_96" d="M0 0L48.175 0" className="cls-1" transform="translate(0 30.896)" />
        <g id="Gruppe_1485" transform="translate(2.227)">
          <path
            id="Pfad_518"
            d="M68.1 15.636V36.62H39.24V9.27"
            className="cls-1"
            transform="translate(-24.244 -5.724)"
          />
          <path
            id="Pfad_519"
            d="M6.38 42.693h15V20.44l-15 8.744z"
            className="cls-1"
            transform="translate(-6.38 -11.796)"
          />
          <g id="Gruppe_1483" transform="translate(3.35 19.77)">
            <path id="Rechteck_441" d="M0 0H7.918V11.126H0z" className="cls-1" />
            <path id="Rechteck_442" d="M0 0H7.918V3.523H0z" className="cls-1" transform="translate(0 3.802)" />
          </g>
          <path id="Linie_97" d="M0 27.35L0 0" className="cls-1" transform="translate(14.996 3.546)" />
          <path
            id="Pfad_520"
            d="M67.7 8.3L34.65 1.5v3.112l33.046 6.8z"
            className="cls-1"
            transform="translate(-21.748 -1.5)"
          />
          <path id="Rechteck_443" d="M0 0H5.709V8.311H0z" className="cls-1" transform="translate(26.342 22.59)" />
          <g id="Gruppe_1484" transform="translate(18.976 12.395)">
            <path id="Rechteck_444" d="M0 0H4.299V4.956H0z" className="cls-1" />
            <path id="Rechteck_445" d="M0 0H4.299V4.956H0z" className="cls-1" transform="translate(8.224)" />
            <path id="Rechteck_446" d="M0 0H4.299V4.956H0z" className="cls-1" transform="translate(16.443)" />
          </g>
          <path id="Rechteck_447" d="M0 0H4.299V4.956H0z" className="cls-1" transform="translate(18.976 22.59)" />
          <path id="Rechteck_448" d="M0 0H4.299V4.956H0z" className="cls-1" transform="translate(35.191 22.59)" />
        </g>
      </g>
    </g>
  </svg>
);

export default MinusIcon;
