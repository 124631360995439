import Cookie from 'js-cookie';
import { useCallback, useState } from 'react';

const useCookieConsent = (cookieName) => {
  const [hasCookies, setHasCookies] = useState(null);

  const updateCookie = useCallback(
    (list, options = {}) => {
      Cookie?.set?.(cookieName, list, {
        ...options,
        'max-age': '2147483647',
      });

      setHasCookies(list);
    },
    [cookieName]
  );

  const deleteCookie = useCallback(() => {
    Cookie?.remove(cookieName);
    setHasCookies(null);
  }, [cookieName]);

  return [hasCookies, updateCookie, deleteCookie];
};

export default useCookieConsent;
