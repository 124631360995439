import * as Accordion from '@radix-ui/react-accordion';
import Link from 'next/link';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import React, { Fragment, useEffect, useState } from 'react';
import { Box, Flex } from 'rebass/styled-components';
import styled from 'styled-components';

import Button from '@/components/Button/Button';
import { ArrowIcon, BellIcon } from '@/components/Icon';
import Loader from '@/components/Loader';
import Text from '@/components/Text';
import { useLoginDialogContext } from '@/context/LoginRegisterDialog';
import { useQueryNotificationRequests } from '@/hooks';
import useAuth from '@/hooks/useAuth';
import { isSSR } from '@/utils';
import Mainmenu from '@/widgets/Navigation/Mainmenu';

import NotificationItems from './NotificationItems';

const InfoBox = styled(Box)`
  position: absolute;
  right: 3.5rem;
  background: #8fd3bc;
  border-radius: 6px;
  color: #fff;
`;

const InfoBoxRight = styled(Box)`
  position: absolute;
  right: 1.5rem;
  background: #8fd3bc;
  border-radius: 6px;
  color: #fff;
`;

const MobileNavWrapper = styled.div`
  display: none;
  position: absolute;
  height: calc(100vh - 119px);
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  z-index: 99;
  top: 64px;
  padding-bottom: 50px;

  @media (max-width: 1024px) {
    display: block;
    width: ${({ open }) => (open ? '100%' : '0')};
    opacity: ${({ open }) => (open ? '1' : '0')};
    transition: ${({ open }) =>
      open ? 'width 0.5s, opacity 0.5s 0.4s linear;' : 'width 0.5s 0.4s, opacity 0.4s linear;'};
  }
`;

const NavBackround = styled(Box)`
  display: none;
  flex-flow: column nowrap;
  height: 24px;
  justify-content: space-around;
  position: fixed;
  background: #fff;

  top: -40px;
  height: calc(100vh);
  z-index: 98;

  padding-bottom: 25px;
  border-top: solid 1px #ebebeb;
  clip-path: ${({ open }) => (open ? `circle(200% at right top)` : 'circle(30px at right top)')};

  transition: clip-path 0.6s linear;
  @media (max-width: 1024px) {
    display: block;
    width: 100%;
  }
`;

const MobileNotificationWrapper = styled(Box)`
  background: #fff;
  box-shadow: 1px 1px 30px 0px rgba(0, 0, 0, 0.1);
  border-radius: 0 0 10px 10px;
  display: none;
  position: fixed;
  height: fit-content;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  z-index: 98;
  top: 64px;
  border-top: solid 1px #ebebeb;
  @media (max-width: 1024px) {
    display: block;
    width: 100%;
  }
`;
const MyFlex = styled(Flex)`
  display: none;
  height: 32px;
  width: 32px;
  position: fixed;
  right: 75px;
  top: 16px;
  z-index: 200;
  @media (max-width: 1024px) {
    display: block;
  }
`;
const StyledHamburger = styled(Box)`
  cursor: pointer;
  display: none;
  flex-flow: column nowrap;
  height: 24px;
  justify-content: space-around;
  position: fixed;
  right: 20px;
  top: 23px;
  width: 24px;
  z-index: 200;
  @media (max-width: 1024px) {
    display: flex;
  }

  div {
    background-color: ${({ open }) => (open ? 'darkgrey' : 'darkgrey')};
    border-radius: 5px;
    height: 3px;
    transform-origin: 1px;
    transition: all 0.3s linear;
    &:nth-child(1) {
      transform: ${({ open }) => (open ? 'rotate(45deg)' : 'rotate(0)')};
    }
    &:nth-child(2) {
      opacity: ${({ open }) => (open ? 0 : 1)};
      transform: ${({ open }) => (open ? 'scaleX(0)' : 'scaleX(1)')};
    }
    &:nth-child(3) {
      transform: ${({ open }) => (open ? 'rotate(-45deg)' : 'rotate(0)')};
    }
  }
`;
StyledHamburger.defaultProps = {};

const Navcontainer = styled(Box)`
  border-bottom: solid 1px #ebebeb;
  padding: 1.5em 2em;
`;

const Register = styled(Box)`
  border-bottom: solid 1px #ebebeb;
  position: relative;
  z-index: 5;
  background: #fff;
`;
Register.defaultProps = {
  paddingRight: ['1.3em', '1.3em', '1.3em'],
  paddingLeft: ['2em', '2em', '2em'],
  paddingTop: ['0', '0', '0', '0'],
  paddingBottom: ['0', '0', '0'],
};

const StyledAccordion = styled(Accordion.Root)`
  bordertop: '1px solid gainsboro';
`;

const StyledItem = styled(Accordion.Item)`
  border-bottom: 1px solid #ebebeb;
  padding-left: 2em;
  padding-right: 2em;
  position: relative;
  z-index: 5;
  background: #fff;
`;

const StyledHeader = styled(Accordion.Header)`
  margin: 0;
  svg {
    transition: transform 500ms;
    transform: rotate(-180deg);
  }
  &[data-state='open'] {
    svg {
      transform: rotate(0deg);
    }
  }
`;

const StyledPanel = styled(Accordion.Panel)`
  &[data-state='open'] {
    animation: slideDown 150ms ease-out;
  }
  &[data-state='closed'] {
    animation: slideUp 150ms ease-out;
  }
  @keyframes slideDown {
    from {
      height: 0;
    }
    to {
      height: var(--radix-accordion-panel-height);
    }
  }
  @keyframes slideUp {
    from {
      height: var(--radix-accordion-panel-height);
    }
    to {
      height: 0;
    }
  }
`;
const StyledOverlay = styled(Box)`
  background-color: rgba(0, 0, 0, 0.35);
  position: fixed;
  top: 64px;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 97;
  @media (min-width: 1025px) {
    display: none;
  }
`;
const Arrow = styled(ArrowIcon)`
  width: 14px;
  height: 7px;
`;

const AdditionLink = styled(Box)`
  border-bottom: 1px solid #ebebeb;
  padding-left: 2em;
  padding-right: 2em;
  cursor: pointer;
  position: relative;
  z-index: 5;
  background: #fff;
`;

const BellBox = styled(Flex)`
  width: 40px;
  height: 40px;
  &:hover {
    background: #fafafa;
  }
`;

const Info = ({ text }) => {
  return (
    <InfoBox>
      <Text variant="navInfoText">{text}</Text>
    </InfoBox>
  );
};
const InfoRight = ({ text }) => {
  return (
    <InfoBoxRight>
      <Text variant="navInfoText">{text}</Text>
    </InfoBoxRight>
  );
};

InfoRight.propTypes = {
  text: PropTypes.string.isRequired,
};

Info.propTypes = {
  text: PropTypes.string.isRequired,
};

const NavItem = ({ doLoginDialog, item, setOpen }) => {
  const { authenticated } = useAuth();
  const router = useRouter();
  const handleLinkClick = (loginRequired, url) => {
    return () => {
      if (loginRequired && !authenticated) {
        doLoginDialog();
      } else {
        router.push({ pathname: url });
        setOpen(false);
      }
    };
  };
  return (
    <>
      {item?.type === 'tarifcheck' || (item?.type === 'notification' && !item?.enabled) ? null : (
        <StyledItem value={item?.title}>
          <StyledHeader>
            <Accordion.Button as="div">
              <Flex justifyContent="space-between" alignItems="center" sx={{ cursor: 'pointer' }}>
                <Text variant="navBoldMobil">{item?.title}</Text>
                {item?.withInfo ? <Info text={item?.infoText} /> : null}
                <Arrow aria-hidden />
              </Flex>
            </Accordion.Button>
          </StyledHeader>
          <StyledPanel>
            {item?.subnav?.map((subitem) => {
              return (
                <Fragment key={subitem.title}>
                  {item?.type === 'tarifcheck' && !subitem?.isEnabled ? null : (
                    <>
                      {!subitem.loginRequired || authenticated ? (
                        <Link href={subitem.url}>
                          <a title={subitem.title}>
                            <Box
                              onClick={() => {
                                setOpen(false);
                              }}
                            >
                              <Text variant="navSubMobil" sx={{ cursor: 'pointer' }}>
                                {subitem?.title}
                              </Text>
                            </Box>
                          </a>
                        </Link>
                      ) : (
                        <Box key={subitem?.title} onClick={handleLinkClick(subitem?.loginRequired, subitem?.url)}>
                          <Text variant="navSubMobil" sx={{ cursor: 'pointer' }}>
                            {subitem?.title}
                          </Text>
                        </Box>
                      )}
                    </>
                  )}
                </Fragment>
              );
            })}
          </StyledPanel>
        </StyledItem>
      )}
    </>
  );
};

NavItem.propTypes = {
  doLoginDialog: PropTypes.func.isRequired,
  item: PropTypes.shape({
    loginRequired: PropTypes.string.isRequired,
    subnav: PropTypes.shape({
      map: PropTypes.func.isRequired,
    }).isRequired,
    title: PropTypes.string.isRequired,
    type: PropTypes.string,
    url: PropTypes.string.isRequired,
  }).isRequired,
  setOpen: PropTypes.func.isRequired,
}.isRequired;

const NavitemRegister = () => {
  const { authenticated, login: klogin, initialized } = useAuth();
  const handleClick = () => {
    if (initialized) {
      if (!authenticated) {
        klogin?.();
      }
    }
  };

  return (
    <>
      {!isSSR && authenticated ? null : (
        <>
          {!isSSR && initialized ? (
            <Navcontainer>
              <Button width="100%" onClick={handleClick}>
                {Mainmenu().loginLogout.notRegistered.button}
              </Button>
            </Navcontainer>
          ) : (
            <Loader />
          )}
        </>
      )}
    </>
  );
};

const NavItemLogout = () => {
  const { authenticated, logout: klogout } = useAuth();

  const handleLogoutClick = () => {
    if (authenticated) {
      klogout?.();
    }
  };
  return (
    <>
      {authenticated ? (
        <Register onClick={handleLogoutClick} paddingRight="2em">
          <Text sx={{ cursor: 'pointer' }} variant="navBoldMobil">
            {Mainmenu().loginLogout.logout}
          </Text>
        </Register>
      ) : null}
    </>
  );
};

const MenuList = ({ doLoginDialog, setOpen }) => {
  return (
    <>
      {Mainmenu().nav.map((item) => {
        return (
          <Fragment key={item?.title}>
            {item?.type !== 'myPlace' && <NavItem item={item} doLoginDialog={doLoginDialog} setOpen={setOpen} />}
          </Fragment>
        );
      })}
    </>
  );
};

MenuList.propTypes = {
  doLoginDialog: PropTypes.func.isRequired,
  setOpen: PropTypes.func.isRequired,
};

const AdditionalMenuList = ({ setOpen }) => {
  return (
    <>
      {Mainmenu().additionLinkList.map((subitem) => {
        return (
          <AdditionLink key={subitem?.title} onClick={() => setOpen(false)}>
            <Link href={subitem?.url}>
              <a title={subitem?.title}>
                <Text variant="navBoldMobil">{subitem?.title}</Text>
                {subitem?.withInfo ? <Info text={subitem?.infoText} /> : null}
              </a>
            </Link>
          </AdditionLink>
        );
      })}
    </>
  );
};

AdditionalMenuList.propTypes = {
  setOpen: PropTypes.func.isRequired,
};

const DashBoardList = ({ doLoginDialog, setOpen }) => {
  const { authenticated } = useAuth();
  const router = useRouter();
  const handleLinkClick = (loginRequired, url) => {
    return () => {
      if (loginRequired && !authenticated) {
        doLoginDialog();
      } else {
        router.push({ pathname: url });
        setOpen(false);
      }
    };
  };
  return (
    <>
      {Mainmenu().nav.map((item) => {
        return (
          <Fragment key={item?.title}>
            {item?.type === 'myPlace' ? (
              <Box px={['2rem', '2rem', '2rem']} sx={{ borderBottom: 'solid 1px #ebebeb' }}>
                {authenticated ? (
                  <Link href={item?.url}>
                    <a title={item?.title}>
                      <Flex
                        justifyContent="space-between"
                        alignItems="center"
                        sx={{ cursor: 'pointer' }}
                        onClick={() => {
                          setOpen(false);
                        }}
                      >
                        <Text variant="navBoldMobil" sx={{ cursor: 'pointer' }}>
                          {item?.title}
                        </Text>
                        {item?.withInfo ? <InfoRight text={item?.infoText} /> : null}
                      </Flex>
                    </a>
                  </Link>
                ) : (
                  <Flex
                    key={item?.title}
                    onClick={handleLinkClick(item?.loginRequired, item?.url)}
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{ cursor: 'pointer' }}
                  >
                    <Text variant="navBoldMobil" sx={{ cursor: 'pointer' }}>
                      {item?.title}
                    </Text>
                    {item.withInfo ? <InfoRight text={item?.infoText} /> : null}
                  </Flex>
                )}
                {item?.subnav.map((subitem) => {
                  return (
                    <Fragment key={subitem.title}>
                      {item.type === 'tarifcheck' && !subitem?.isEnabled ? null : (
                        <>
                          {!subitem?.loginRequired || authenticated ? (
                            <Link href={subitem.url}>
                              <a title={subitem?.title}>
                                <Box
                                  onClick={() => {
                                    setOpen(false);
                                  }}
                                >
                                  <Text variant="navSubMobil" sx={{ cursor: 'pointer' }}>
                                    {subitem?.title}
                                  </Text>
                                </Box>
                              </a>
                            </Link>
                          ) : (
                            <Box key={subitem?.title} onClick={handleLinkClick(subitem?.loginRequired, subitem?.url)}>
                              <Text variant="navSubMobil" sx={{ cursor: 'pointer' }}>
                                {subitem?.title}
                              </Text>
                            </Box>
                          )}
                        </>
                      )}
                    </Fragment>
                  );
                })}
              </Box>
            ) : null}
          </Fragment>
        );
      })}
    </>
  );
};

DashBoardList.propTypes = {
  doLoginDialog: PropTypes.func.isRequired,
  setOpen: PropTypes.func.isRequired,
};

const NotificationMobil = ({ doLoginDialog, setOpen }) => {
  const { authenticated } = useAuth();
  const handleNotification = (loginRequired) => {
    return () => {
      if (loginRequired && !authenticated) {
        doLoginDialog();
      } else {
        setOpen(false);
      }
    };
  };
  return <NotificationItems handleNotification={handleNotification} />;
};

NotificationMobil.propTypes = {
  doLoginDialog: PropTypes.func.isRequired,
  setOpen: PropTypes.func.isRequired,
};

const MobileNavigation = ({ isMobileMenuOpen, setOpenStart, openStart }) => {
  const [showDrawArea, setShowDrawArea] = useState(false);
  const [open, setOpen] = useState(false);
  const [openNotification, setOpenNotification] = useState();
  const { setShowLoginDialog } = useLoginDialogContext();
  const { data } = useQueryNotificationRequests();
  useEffect(() => {
    if (openStart) {
      setOpen(false);
      setShowDrawArea(false);
      setOpenStart(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openStart]);

  const doLoginDialog = () => {
    setOpen(false);
    setShowDrawArea(false);
    isMobileMenuOpen(false);
    setShowLoginDialog(true);
  };

  const handleMenu = () => {
    if (open === false) {
      setShowDrawArea(!showDrawArea);
      setTimeout(() => {
        setOpen(!open);
        isMobileMenuOpen(!open);
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      }, 100);
    } else {
      setOpen(!open);
      isMobileMenuOpen(!open);
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
      setTimeout(() => {
        setShowDrawArea(!showDrawArea);
      }, 650);
    }
  };

  const handleNotification = () => {
    setOpenNotification(!openNotification);
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const setMobileOpen = () => {
    setOpen(!open);
    isMobileMenuOpen(!open);
    setTimeout(() => {
      setShowDrawArea(!showDrawArea);
    }, 750);
  };
  return (
    <>
      {open ? <StyledOverlay /> : null}
      <MyFlex justify="center" alignItems="center">
        {data && data?.meta?.cnt_unread !== 0 ? (
          <BellBox
            alignItems="center"
            justifyContent="center"
            onClick={() => handleNotification()}
            sx={{ borderRadius: '50%', position: 'relative' }}
          >
            <Flex
              height="16px"
              width="16px"
              sx={{ borderRadius: '50%', position: 'absolute', right: '0', top: '6px' }}
              backgroundColor="red"
              justifyContent="center"
              alignItems="center"
            >
              <Text variant="notificationCount">{data?.meta?.cnt_unread}</Text>
            </Flex>
            <Box>
              <BellIcon />
            </Box>
          </BellBox>
        ) : (
          <BellBox
            alignItems="center"
            justifyContent="center"
            onClick={() => handleNotification()}
            sx={{ borderRadius: '50%', position: 'relative' }}
          >
            <Box>
              <BellIcon />
            </Box>
          </BellBox>
        )}
      </MyFlex>
      {openNotification ? (
        <MobileNotificationWrapper>
          <NotificationMobil open={openNotification} setOpen={setMobileOpen} />
        </MobileNotificationWrapper>
      ) : null}
      <StyledHamburger open={open} onClick={() => handleMenu()}>
        <div />
        <div />
        <div />
      </StyledHamburger>
      {showDrawArea ? <NavBackround open={open} /> : null}
      <MobileNavWrapper open={open}>
        <NavitemRegister setOpen={setMobileOpen} />
        <DashBoardList doLoginDialog={doLoginDialog} setOpen={setMobileOpen} />
        <StyledAccordion type="multiple">
          <MenuList doLoginDialog={doLoginDialog} setOpen={setMobileOpen} />
        </StyledAccordion>
        <AdditionalMenuList setOpen={setMobileOpen} />
        <NavItemLogout />
      </MobileNavWrapper>
    </>
  );
};

MobileNavigation.propTypes = {
  isMobileMenuOpen: PropTypes.func.isRequired,
  openStart: PropTypes.bool,
  setOpenStart: PropTypes.func.isRequired,
};

MobileNavigation.defaultProps = {
  openStart: false,
};

export default MobileNavigation;
