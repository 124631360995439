import { useQuery } from 'react-query';

import { configApi } from '@/services';
import getHostname from '@/utils/getHostname';

const useAccessMenu = () =>
  useQuery('visibility', async () => {
    const settings = await configApi('/settings', getHostname());

    return settings?.visibility ?? { homemanagement: false, insurance: false, projects: false, services: false };
  });

export default useAccessMenu;
