const CultureIcon = ({ ...props }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg xmlns="http://www.w3.org/2000/svg" width="19.181" height="19" viewBox="0 0 19.181 19" {...props}>
    <path
      fill="#8277bc"
      d="M13.62 2.813l-.589.159a34.754 34.754 0 0 0-5.1 1.789h-.023a39.761 39.761 0 0 0-4.66 2.649l-.5.34.252.565s1.928 4.32 2.808 6.228c0 .008.02.014.023.023a2.576 2.576 0 0 0 1.313 1.223 4.227 4.227 0 0 0 .521 1.472A10.976 10.976 0 0 0 9.2 19.367a22.231 22.231 0 0 0 2.4 2.265l.226.181.294-.023a23.245 23.245 0 0 0 3.261-.544 11.038 11.038 0 0 0 2.491-.883 3.124 3.124 0 0 0 1.7-1.766c.648-2.163 2.174-7.02 2.174-7.02l.181-.589-.566-.272a48.877 48.877 0 0 0-5.141-2.2c-.948-2.109-2.355-5.163-2.355-5.163zm-.793 1.766C13.026 5 13.77 6.6 14.413 8c-2.069-.5-4.212-.77-4.212-.77l-.611-.068-.181.566-.294.928-2.243 1.516.815 1.2.77-.521c-.422 1.37-.668 2.183-1.042 3.42a.806.806 0 0 1-.272-.294v-.023c-.781-1.69-2.243-4.908-2.559-5.616a30.344 30.344 0 0 1 3.94-2.265 27.141 27.141 0 0 1 4.303-1.494zm-2.219 4.19a32.56 32.56 0 0 1 4.937 1.019 37.416 37.416 0 0 1 4.62 1.97c-.2.637-1.11 3.533-1.766 5.684l-.226.725a1.912 1.912 0 0 1-.974.906 9.856 9.856 0 0 1-2.151.77 17.951 17.951 0 0 1-2.763.453 18.433 18.433 0 0 1-1.993-1.9 10.089 10.089 0 0 1-1.359-1.834 1.927 1.927 0 0 1-.317-1.291c.594-1.982 1.769-5.781 1.992-6.503zm.589 3.487l-.43 1.381 2.74.883.43-1.4zm3.85 1.155l-.453 1.381 2.672.883.453-1.381zm-2.967 1.834l-1.359.5a3.731 3.731 0 0 0 2.333 2.151v.023c.014.006.031-.006.045 0a3.8 3.8 0 0 0 3.148-.544l-.838-1.175a2.49 2.49 0 0 1-1.88.34h-.023l-.023-.023a2.34 2.34 0 0 1-1.403-1.272z"
      transform="translate(-2.75 -2.813)"
    />
  </svg>
);

export default CultureIcon;
