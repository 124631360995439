const UploadIcon = ({ ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="47.552"
    height="33.966"
    viewBox="0 0 47.552 33.966"
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
  >
    <path
      fill="#ebebeb"
      d="M25.776 7a15.063 15.063 0 0 0-11.2 5.2 5.556 5.556 0 0 0-.69-.106 6.815 6.815 0 0 0-6.793 6.793A10.022 10.022 0 0 0 2 27.38a10.218 10.218 0 0 0 10.19 10.19h8.491v-3.4H12.19a6.8 6.8 0 0 1-2.654-13.053l1.168-.478-.159-1.274c-.033-.279-.053-.438-.053-.478a3.37 3.37 0 0 1 3.4-3.4 3.293 3.293 0 0 1 .8.106l1.061.265.637-.849a11.873 11.873 0 0 1 20.91 4.352l.318 1.327 1.433-.053c.358-.02.438-.053.318-.053a6.793 6.793 0 1 1 0 13.586h-8.498v3.4h8.491a10.12 10.12 0 0 0 .69-20.22A15.168 15.168 0 0 0 25.776 7zm0 13.586l-6.793 6.794h5.095v13.586h3.4V27.38h5.095z"
      transform="translate(-2 -7)"
    />
  </svg>
);

export default UploadIcon;
