import { SSRCookies, SSRKeycloakProvider, useKeycloak } from '@react-keycloak/ssr';
import Cookies from 'js-cookie';
import PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from 'react';
import { useQueryClient } from 'react-query';

import useAuth from '@/hooks/useAuth';
import { contactApi, realEstateApi, userApi } from '@/services';
import { isSSR } from '@/utils';

const authedApis = [userApi, realEstateApi, contactApi];

const KeycloakTokenUpdater = () => {
  const { keycloak, initialized } = useKeycloak();
  const queryClient = useQueryClient();
  const { login } = useAuth();

  const [refetchedAfterLogin, setRefetchedAfterLogin] = useState(false);

  useEffect(() => {
    if (initialized) {
      keycloak.onTokenExpired = () => {
        keycloak.updateToken(30).catch(() => {
          login?.();
        });
      };
    }
  }, [keycloak, initialized, login]);

  useEffect(() => {
    const authRequestInterceptors = authedApis.map((api) => {
      return api.interceptors.request.use(
        (config) => {
          if (keycloak?.token) {
            // eslint-disable-next-line no-param-reassign
            config.headers.Authorization = `Bearer ${keycloak?.token}`;
          }

          return config;
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    });

    const authResponseInterceptors = authedApis.map((api) => {
      return api.interceptors.response.use(null, async (error) => {
        if (error?.response?.status === 401) {
          keycloak?.updateToken?.(4000)?.catch(() => {
            login?.();
          });
        }

        return Promise.reject(error);
      });
    });

    return () => {
      authedApis.forEach((api, index) => {
        api.interceptors.request.eject(authRequestInterceptors[index]);
        api.interceptors.request.eject(authResponseInterceptors[index]);
      });
    };
  }, [keycloak, keycloak?.token, login]);

  useEffect(() => {
    if (initialized && keycloak?.token && !refetchedAfterLogin) {
      queryClient.invalidateQueries();
      setRefetchedAfterLogin(true);
    }
  }, [initialized, keycloak?.token, queryClient, refetchedAfterLogin]);

  return null;
};

const Keycloak = ({ children, cookies, config }) => {
  const origin = useMemo(() => (isSSR ? null : window?.location?.origin), []);

  const handleToken = ({ token }) => {
    if (token) {
      Cookies?.set('isAuthenticated', true);
    } else {
      Cookies?.remove('isAuthenticated');
    }
  };

  return (
    <SSRKeycloakProvider
      keycloakConfig={config}
      onTokens={handleToken}
      persistor={SSRCookies(cookies)}
      initOptions={{
        onLoad: 'check-sso',
        silentCheckSsoRedirectUri: origin ? `${origin}/silent-check-sso` : null,
      }}
    >
      <>
        <KeycloakTokenUpdater />
        {children}
      </>
    </SSRKeycloakProvider>
  );
};

Keycloak.propTypes = {
  children: PropTypes.node.isRequired,
  cookies: PropTypes.shape({}).isRequired,
  config: PropTypes.shape({
    realm: PropTypes.string,
    url: PropTypes.string,
    'ssl-required': PropTypes.string,
    resource: PropTypes.string,
    'public-client': PropTypes.bool,
    'verify-token-audience': PropTypes.bool,
    'use-resource-role-mappings': PropTypes.bool,
    'confidential-port': PropTypes.number,
    clientId: PropTypes.string,
  }).isRequired,
};

export default Keycloak;
