import stylePropTypes from '@styled-system/prop-types';
import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { Box } from 'rebass/styled-components';
import styled from 'styled-components';
import { boxShadow } from 'styled-system';

const SectionWrapper = styled(Box)`
  ${boxShadow}
`;
SectionWrapper.defaultProps = {
  width: '100%',
};

const SectionInner = styled(Box)``;
SectionInner.defaultProps = {
  width: '100%',
  maxWidth: 'fullLayoutWidth',
  mx: 'auto',
};

const Section = forwardRef(({ children, padded, fullWidth, ...props }, ref) => (
  <SectionWrapper {...props} ref={ref}>
    <SectionInner maxWidth={fullWidth ? 'unset' : 'fullLayoutWidth'} px={padded ? ['1rem', '1rem', '2rem'] : 0}>
      {children}
    </SectionInner>
  </SectionWrapper>
));

Section.propTypes = {
  ...stylePropTypes.color,
  ...stylePropTypes.space,
  ...stylePropTypes.shadow,
  children: PropTypes.node,
};

Section.defaultProps = {
  children: null,
};

export default Section;
